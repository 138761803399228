import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SidebarTest } from '../data';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

const Sidebar = ({ currentUser } : any) => {
  const location = useLocation();

  return (
    <nav>
      <div className='sidebar'>
        <ul className='sidebar-main navbar-nav'>
          {SidebarTest.map((sidebar, index) => {
            if(currentUser === null 
              || ( sidebar.role === "Admin" 
                && currentUser.role.name !== "Admin"
                ) 
              ) {
                return null;
            }

            return (
              <SidebarNavigationElement
                key={"sidebar_"+index}
                icon={sidebar.icon}
                title={sidebar.title}
                selected={sidebar.selected}
                link={sidebar.link}
                location={location}
                role={sidebar.role}
                dropdownelements={sidebar.dropdownelements}
              />
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export interface SidebarNavigationProps {
  icon: IconDefinition;
  title: string;
  selected: boolean;
  link: string;
  location?: Location;
  role: 'User' | 'Admin';
  dropdownelements?:{
    icon: IconDefinition;
    title: string;
    selected: boolean;
    link: string;
    role: 'User' | 'Admin';
  }[]
};

const SidebarNavigationElement = (props: SidebarNavigationProps) => {
  const { icon, title, link, location, dropdownelements, selected } = props;
  if(!location) return(<></>)
  const isActive =
    location.pathname === link || location.pathname.startsWith(`${link}/`) || (selected && location.pathname === '/');
  const className = `sidebar-list ${isActive ? 'active' : ''}`;

  return (
    <>
      {dropdownelements && dropdownelements.length > 0 ? 
        <li className={className +' pe-1'}>
            <div className="btn-group">
              <a className={`nav-link  ${selected ? 'active' :  ''}`}href={link ?? "#"} style={{width:'100%'}}>                  
                  <a role="button" className="dropdown-toggle dropdown-toggle-split ps-0" id="dropdownMenuButton" data-bs-toggle="dropdown"  aria-expanded="false" data-bs-auto-close="false">
                    <FontAwesomeIcon icon={icon} className='sidebar-icon me-2' />
                    <span className="sidebar-title me-3 ms-1">{title}</span>
                  </a>
                  <ul className="dropdown-menu bg-transparent border-0 sidebar-subsections mt-1" aria-labelledby="dropdownMenuButton">
                    {dropdownelements.map((element)=>(
                      <li className='sidebar-list ps-0 pe-1'>
                          <a className="dropdown-item nav-dropdown-item nav-link bg-transparent text-white" href={element.link} style={{width:'100%'}}>
                            <span className='sidebar-title pe-1'>
                              <FontAwesomeIcon icon={element.icon} className='sidebar-icon me-3' />
                                {element.title}
                              </span>
                          </a>
                      </li>
                    ))}
                  </ul>
              </a>
            </div>
        </li>
        : 
        <li className={className + ' pe-1'}>
            <a className="nav-link" href={link ?? "#"} style={{width:'100%'}}>
              <FontAwesomeIcon icon={icon} className='sidebar-icon' />
              <span className='sidebar-title'>{title}</span>
            </a>
        </li>
      }
    </>   

    // <NavLink to={link} className={className}>
    //   {dropdownelements && dropdownelements.length > 0 
    //     ? 
    //     <>
    //     <div className="btn-group">
    //         <a className="nav-link bg-transparent border-0 p-0" role="button" href={link ?? "#"}>{title} </a>
    //         <a role="button" className="nav-link bg-transparent border-0 p-0 ps-2 dropdown-toggle dropdown-toggle-split" id="dropdownMenuButton" data-bs-toggle="dropdown"  aria-expanded="false"  data-bs-auto-close="false" ><span className="visually-hidden"></span></a>
    //         <ul className="dropdown-menu bg-transparent border-0" aria-labelledby="dropdownMenuButton">
              
    //         </ul>
    //       </div>
    //     </>
    //     : 
        
    //   }
      
    // </NavLink>
  );
};
export default Sidebar;

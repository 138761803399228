import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { __PlannedStepsUnconverted, __WorkBlockResponse, __WorkBlockResponseList } from "../../backend/types/__GeneralStructures";
import { __WorkBlockEdit } from "../../backend/types/__RequestStructures";
import { ApiBackendData } from "../../backend/ApiBackendData";
import InputField from "../../UI/InputField";
import { ApiWorkBlock } from "../../backend/ApiWorkBlock";
import { useUserList } from "../../hooks/UserHooks";

interface WorkblockEditModalProps{
    modalID: string;
    workblockData: __WorkBlockResponse | __WorkBlockResponseList;
    changeHandler: () => void;
};

export  default function ArbeitsblockEditModal ({
    modalID,
    workblockData,
    changeHandler
}:WorkblockEditModalProps){
    const [inputs,setInputs] = useState(workblockData);
    const [selectedUser, setSelectedUser] = useState(inputs.responsibleUser[0].displayName);
    const [userListDict, userDataLoaded] = useUserList();
    const [initialized,setInitialized] = useState(false);

    let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
    let workblockStates = enums.WorkBlockState ?? [];
    
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };
    const handleChangeSelectedUser = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let selUser = event.target.value;
        setSelectedUser(selUser);
    };
    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setInputs((prevState) => ({ ...prevState, [name]: value }));
    };
    const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        if(name === 'stateID' && value === 'abgeschlossen'){
        let newInputs = {...inputs};
        newInputs.date = new Date()
        newInputs.stateID = value;
        setInputs(newInputs);
        }
        else{
        setInputs((prevState) => ({ ...prevState, [name]: value }));
        }
    };
    const handleSubmit = async (event: FormEvent) => {
        let user:string[] = [userListDict[userListDict.map(x=>x.displayName).indexOf(selectedUser)].loginName];
        let requestData: __WorkBlockEdit = {
            id: workblockData.id,
            plannedSteps: JSON.parse(workblockData.plannedSteps) as __PlannedStepsUnconverted,
            responsibleUser: user,
            name: inputs.name,
            date: new Date(inputs.date),
            description: inputs.description,
            comment: inputs.comment ?? '',
            locationOfSynthesis: inputs.locationOfSynthesisID,
            state: inputs.stateID
        };
        await ApiWorkBlock.Edit(requestData)
        changeHandler();
    };

    const onDismiss = () => {
        setInputs(workblockData);
    };
    //clear input on dismiss
    const modalElement = document.getElementById(modalID) as HTMLElement;
    useEffect(()=>{
        if(modalElement){
        modalElement.addEventListener('hidden.bs.modal', () => onDismiss() );
        }
    });
    useEffect(()=>{
        if(userDataLoaded){
            setInitialized(true);
        }
    },[userDataLoaded]);

    return (
        <div className='modal fade mt-4' id={modalID} tabIndex={-1} aria-hidden="true">
            <>
                { initialized 
                    ?   <div className="modal-dialog px-4 modal-xl">
                            <div className="modal-content px-3 modal-background-lightgrey arbeitspaket-detail-modal">
                                <div className="modal-header">
                                    <div className="ps-2 fs-3 fw-bold">Arbeitsblock bearbeiten</div>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className='modal-body py-0'>
                                    <div className='row margin-top-15'>
                                        <div className='col'>
                                            <label htmlFor='name'>Name</label>
                                            <InputField
                                                inputType='text'
                                                name='name'
                                                placeholder=''
                                                value={inputs.name}
                                                changeHandler={handleChange}
                                                disabled={true}
                                                inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='locationOfSynthesisID'>Syntheseort</label>
                                            <select
                                            name='locationOfSynthesisID'
                                            value={inputs.locationOfSynthesisID}
                                            onChange={handleOptionChange}
                                            className='input-lg text-center text-md border-bottom-primary  margin-top-5'
                                            >
                                            {enums.Location.map((option) => (
                                                <option key={option} value={option}>
                                                {option}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row margin-top-15'>
                                        <div className='col'>
                                            <label htmlFor='verantwortlicher'>Verantwortlicher</label>
                                            <select
                                                name='verantwortlicher'
                                                value={selectedUser}
                                                onChange={handleChangeSelectedUser}
                                                className='input-lg text-center text-md border-bottom-primary  margin-top-5'
                                            >
                                            {userListDict.map((option) => (
                                                <option key={option.displayName} value={option.displayName} hidden={!option.stateIsActve}>
                                                    {option.displayName}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='stateID'>Status</label>
                                            <select
                                            name='stateID'
                                            value={inputs.stateID}
                                            onChange={handleOptionChange}
                                            className='input-lg text-center text-md border-bottom-primary  margin-top-5'
                                            >
                                            {workblockStates.map((option) => (
                                                <option key={option} value={option}>
                                                {option}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row margin-top-15'>
                                        <div className='col-12 mb-3'>
                                            <label htmlFor='description'>Kurzbeschreibung</label>
                                            <textarea
                                            className='input-lg-textarea border-bottom-primary cut-text-overflow margin-top-5'
                                            name='description'
                                            value={inputs.description}
                                            onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='row margin-top-25'>
                                        <div className='col-12'>
                                            <label htmlFor='comment'> Kommentar</label>
                                            <textarea
                                            className='input-lg-textarea border-bottom-primary cut-text-overflow  margin-top-5'
                                            name='comment'
                                            value={inputs.comment}
                                            onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='modal-footer pt-0 mt-4'>
                                    <button 
                                        type="button" 
                                        className="btn-main margin-top-15" 
                                        data-bs-dismiss="modal"> Abbrechen
                                    </button>
                                    <button
                                        className='btn-main margin-top-15'
                                        type='button'
                                        onClick={handleSubmit} 
                                        data-bs-dismiss="modal"
                                    > Speichern
                                    </button>
                                </div>
                            </div>
                        </div>
                    : <></>
                }
            </>
    </div>
    );
};
import Config from "../util/Config";
import { __LayerR2RConverted, __LightningR2RConverted, CreateBasicResponse } from "./types/__GeneralStructures";
import { ApiClient } from "./ApiClient";
import BasicResponse from "./BasicResponse";
import * as requestTypes from "./types/__RequestStructures"
import * as responseTypes from "./types/__ResponseStructures"
import { ApiPrepareRequest } from './ApiPrepareRequest';



export class ApiAnodeConstructionR2R {

    static basePath: string = "/anoder2r";
    static baseUrl: string = Config.BackendUrl + ApiAnodeConstructionR2R.basePath;

    static Get(id: string = ''): Promise<responseTypes.IBackendAnodeConstructionR2RList> {
        let path = '/get';

        // prepare query
        let query = ApiPrepareRequest.PrepareGetQuery(id);
        let url = ApiAnodeConstructionR2R.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.get(url);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendAnodeConstructionR2RList>;

        resolve(responseObject.data);
        });
    };
    // TODO: add filter and search
    /**
     * /anode/list
     */
    static ListAll(): Promise<responseTypes.IBackendAnodeConstructionR2RList[]> {

        let path = "/list";
        let url = ApiAnodeConstructionR2R.baseUrl + path;

        return new Promise(async (resolve, reject) => {

            let response = await ApiClient.get(url);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendAnodeConstructionR2RList[]>;

            resolve(responseObject.data);

        });

    };
    static ListAnodes(filterObject?:requestTypes.AnodeR2RListFilter): Promise<responseTypes.IBackendAnodesR2R[]> {
        //datenstruktur muss angepasst werden
        let path = "/listanodes";
        // prepare query
        let query = ApiPrepareRequest.PrepareListQuery(filterObject??{},'');
        let url = ApiAnodeConstructionR2R.baseUrl + path +query;

        return new Promise(async (resolve, reject) => {

            let response = await ApiClient.get(url);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendAnodesR2R[]>;

            resolve(responseObject.data);

        });

    };
    static Create(requestData: requestTypes.__AnodeConstructionR2RCreateConverted): Promise<string> {
        let path = "/create";
        let url = ApiAnodeConstructionR2R.baseUrl + path;
        let layer:__LayerR2RConverted[] = [];
        for (let item of requestData.layer){
            let convertedLayer =JSON.parse(JSON.stringify(item)) as __LayerR2RConverted;
            layer.push(convertedLayer);
        }
        //convert lightnings
        let lightnings:__LightningR2RConverted[] = [];
        for (let item of requestData.lightnings){
            let convertedLightnings =JSON.parse(JSON.stringify(item)) as __LightningR2RConverted;
            lightnings.push(convertedLightnings);
        }
    
        return new Promise(async (resolve, reject) => {
            let response = await ApiClient.post(url, requestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }
    
            let responseObject = (await response.json()) as CreateBasicResponse<string>;
    
            resolve(responseObject.id);
        });
    };
    static Edit(requestData: requestTypes.__AnodeConstructionR2REditConverted):Promise<boolean>{
        let path = "/edit";
        let url = ApiAnodeConstructionR2R.baseUrl + path;
        return new Promise(async (resolve, reject) => {
            
          let response = await ApiClient.put(url, requestData);
          if (!response.ok) {
              if (response.status === 401) {
                  reject("Unauthorized!");
              }
              else {
                  reject(`Error! status: ${response.status}`);
              }
          }
    
          let responseObject = (await response.json()) as BasicResponse<string>;
    
          resolve(responseObject.success);
      });
    };
    static Delete(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/delete';
        let url = ApiAnodeConstructionR2R.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //lock
    static Lock(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/lock';
        let url = ApiAnodeConstructionR2R.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //activate
    static Activate(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/activate';
        let url = ApiAnodeConstructionR2R.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //close
    static Close(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/close';
        let url = ApiAnodeConstructionR2R.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //upload
    static Upload(requestData: requestTypes.__UploadFiles): Promise<boolean> {
        let path = '/upload';
        let url = ApiAnodeConstructionR2R.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //generatepdf
    static GeneratePdf(id: string): Promise<string> {
        let path = '/generatepdf';
        let query = '?id=' + id;
        let url = ApiAnodeConstructionR2R.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.get(url);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject = (await response.json()) as BasicResponse<string>;

        resolve(responseObject.data);
        });
    };
}

export class AnodeConstructionConverter {
    // Temporärer Hinweis: Gedacht für die Tabellen Planung und Arbeitspakete
    static ConvertFromApiToTableList(data:responseTypes.IBackendAnodeConstructionR2RList[]) {
        // [1, 'Anode 1', 'Tony', '15.03.2023', 'abgeschlossen', 'Halle'],
        let simpleList: string[][] = [];
        data.forEach(item => {
            simpleList.push([
                item.id,
                item.workblock.name ?? '',
                item.anode?.name ?? '',
                'R2R',
                item.responsibleUser[0].displayName ?? '',
                new Date(item.date).toLocaleDateString(),
                item.state.name ?? '',
                item.location.name ?? ''
            ]);
        });

        return simpleList;
    }
}

import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModularSearchHeader from '../../components/ModularSearchHeader/ModularSearchHeader';

import { Table } from '../../components/Table';
import {
  AnodeConstructionConverter as AnodeConstructionConverterSheet,
  ApiAnodeConstruction,
} from '../../backend/ApiAnodeConstruction';

import {
  ApiAnodeConstructionR2R,
  AnodeConstructionConverter as AnodeConstructionConverterR2R,
} from '../../backend/ApiAnodeConstructionR2R';
import { IBackendMaterialGet } from '../../backend/types/__ResponseStructures';
import { ApiMaterial } from '../../backend/ApiMaterial';
import Loading from '../../components/Ladebalken';
import { InfoModal } from '../../components/Modal';

// TODO: Daten anders verwalten
export default function Anodenbau() {
  const [initializedTable, setInitializedTable] = useState(false);
  const navigate = useNavigate();
  const tableHeaders = [
    'Arbeitsblock',
    'Name',
    'Typ',
    'Verantwortlicher',
    'Datum',
    'Status',
    'Ort',
    'Bearbeiten',
  ];
  const [displayedTable, setDisplayedTable] = useState<string[][]>([]);
  const [materials, setMaterials] = useState<IBackendMaterialGet[]>();
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  useEffect(() => {
    let temp: string[][];
    document.title = 'Anodenbau';
    if (!initializedTable) {
      setOpenLoadingModal(true);
      ApiMaterial.ListAll().then((data) => {
        setMaterials(data);
      });
      ApiAnodeConstruction.ListAll().then((data) => {
        temp = AnodeConstructionConverterSheet.ConvertFromApiToTableList(data);
        ApiAnodeConstructionR2R.ListAll().then((data) => {
          temp = temp.concat(
            AnodeConstructionConverterR2R.ConvertFromApiToTableList(data)
          );
          setDisplayedTable(temp);
          setInitializedTable(true);
        });
      });
      setOpenLoadingModal(false);
    }
  });
  const [inputs, setInputs] = useState({});
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getInitialTable = () => {
    return displayedTable;
  };
  const handleDetail = async (index: number) => {
    if(displayedTable[index][3] === 'S2S'){
      await ApiAnodeConstruction.Get(displayedTable[index][0])
      .then((data) => {
        navigate('/anodenbau/sheetToSheet', {
          state: {
            data: data,
            materials: materials,
            workblockID: data.workblock.id
          }
        });
      })
      .catch((err) => {
      });
    }
    else{
      await ApiAnodeConstructionR2R.Get(displayedTable[index][0])
      .then((data) => {
        navigate('/anodenbau/rollToRoll', {
          state: {
            data: data,
            workblockID: data.workblock.id
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };
  const handlePDF = async (index: number) => {
    setOpenLoadingModal(true);
    let id = displayedTable[index][0];
    // TODO: Unterscheidung R2R statt nur S2S
    ApiAnodeConstruction.GeneratePdf(id).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };

  const someFunctions = {
    func: [handleDetail, handlePDF],
    val: ['Details', 'PDF'],
    defaultState: ["aktiv", "aktiv"],
    modal:[false, false]
  };

  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}

      {initializedTable ? (
        <ModularSearchHeader
          title='Anodenbau'
          dropdownOptions={tableHeaders.slice(0, -1)}
          handleChange={handleChange}
          setDisplayedTable={setDisplayedTable}
          displayTable={displayedTable}
          getInitialTable={getInitialTable}
        />
      ) : (
        <></>
      )}

      <Table
        modalIDList={[]}
        headers={tableHeaders}
        valuesList={displayedTable}
        stateList={null}
        functionsList={someFunctions}
      />
      <InfoModal
        modalID='ErrorDownloadFailed'
        infoText='Download fehlgeschlagen'
        confirmationText='Ok'
      />
    </div>
  );
}

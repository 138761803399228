import { ChangeEvent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faGears, faSearch, faSort } from "@fortawesome/free-solid-svg-icons";
import Cookies from 'js-cookie';

export interface filterSelectProps {
    anodenbautyp: string[]; //'s2s'| 'r2r'
    batteriebautype: string[]; //'vz'|'hz'|'pz'
    batterietest: string[]; //'vz'|'hz'|'pz'
    characterisierung: string[]; //'hafttest'|'xrd' | 'rem' |'raman' | 'pm' | 'mikroskopie' 

    confirmationStateOfPackage: string[] //bestätigt | unbestätigt
    state: string[]; //'gelöscht' |'gesperrt' |'aktiv' |'abgeschlossen'
};

interface TableSettingProps {
    inputsFilter: filterSelectProps;
    searchFilter: string;
    sortByList: string[];
    sortBy: string;
    handleSearchFilterChange: (item: string) => void;
    handleFilterChange: (inputs:filterSelectProps) => void;
    handleChangeSort: (target:string) => void;
    handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRadioChangeSecond: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TableSettingsFullTable =({
    inputsFilter,
    searchFilter,
    sortBy,
    sortByList,
    handleSearchFilterChange,
    handleFilterChange,
    handleRadioChange,
    handleRadioChangeSecond,
    handleChangeSort
}: TableSettingProps) =>{
    const [inputsFilterData,setInputsFilterData] = useState<filterSelectProps>(inputsFilter);
    const [inputsSearchFilterData,setInputsSearchFilterData] = useState<string>(searchFilter);
    const [accordeonCollapsed, setAccordeonCollapsed] = useState(Cookies.get('tableFullSettings_accordeon') === 'true');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        Cookies.set('tableFullSettings_searchValue', value);
        setInputsSearchFilterData(value);
    };
    const handleCheckIfEnter = (event:React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter'){
            handleSearchFilterChange(inputsSearchFilterData);
        }
    };

    const changingSort = (event: ChangeEvent<HTMLSelectElement>) => {
        let value = event.target.value;
        if(value === 'Priorität'){
            value = 'prio';
        }
        Cookies.set('tableFullSettings_sort', value);
        handleChangeSort(value);
    };

    const changingFilter = (filter: filterSelectProps) => {
        Cookies.set('tableFullSettings_filter', JSON.stringify(filter));
        setInputsFilterData(filter);
        handleFilterChange(filter);
    };

    return(
        <div className="accordion mt-4  bg-transparent" id="accordionExample">
            <div className="accordion-item  border-0 ">
                <h2 className="accordion-header " id="headingOne">
                <button 
                    className={`accordion-button p-0 fs-4 bg-transparent ${accordeonCollapsed ? 'collapsed' : ''}`}
                    type="button" data-bs-toggle="collapse" 
                    data-bs-target="#collapseOne" 
                    aria-expanded="true" 
                    aria-controls="collapseOne"
                    onClick={() => {
                        let value = !accordeonCollapsed;
                        setAccordeonCollapsed(value);
                        Cookies.set('tableFullSettings_accordeon', String(value));
                    }}
                >
                    <FontAwesomeIcon
                        icon={faGears}
                        className="pe-2"
                        size="xs"
                        // style={{color:'#fff'}}
                    />
                    Tabelleneinstellungen
                </button>
                </h2>
                <div id="collapseOne" className={`accordion-collapse body-background ${accordeonCollapsed ? 'collapse' : 'collapse show'}`} >
                    <div className="accordion-body pt-1">
                        <div className="row">
                            <div className="col-4 mt-2">
                                <div className="form-check form-switch">
                                    <input 
                                        className="form-check-input radio-switch-dark" 
                                        type="checkbox" 
                                        name="showInvalidStates" 
                                        checked={Cookies.get('tablefull_settings_ ShowLockedSteps')==='true'}
                                        onChange={event => handleRadioChange(event)}
                                    />
                                    <label className="form-check-label">Gesperrte Arbeitsschritte anzeigen</label>
                                </div>
                                <div className="form-check form-switch">
                                    <input 
                                        className="form-check-input radio-switch-dark" 
                                        type="checkbox" 
                                        name="showInvalidStatesPackages" 
                                        checked={Cookies.get('tablefull_settings_ ShowLockedBlocksAndPackages')==='true'}
                                        onChange={event => handleRadioChangeSecond(event)}
                                    />
                                    <label className="form-check-label">Gesperrte AP/AB anzeigen</label>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className='input-wrapper'>
                                    <label>
                                    <   FontAwesomeIcon
                                            icon={faSort}
                                            className="pe-2"
                                        />
                                        Sortieren nach</label>
                                    <select
                                        name=''
                                        style={{height:'35px'}}
                                        value={sortBy}
                                        onChange={changingSort}
                                        className='input-lg text-start border-bottom-primary p-1'
                                    >
                                        {sortByList.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className='input-wrapper'>
                                    <label>
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            className="pe-2"
                                        />Suche
                                    </label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            name='searchFilter'
                                            style={{height:'35px'}}
                                            value={inputsSearchFilterData}
                                            onChange={handleChange}
                                            onKeyUp={e=> handleCheckIfEnter(e)}
                                            className='form-control input-lg text-start border-bottom-primary p-1'
                                            aria-describedby="buttonSearch"
                                        />
                                        <button
                                            onClick={ e=> handleSearchFilterChange(inputsSearchFilterData)}
                                            className="btn btn-light text-dark border-light pb-0"
                                            id="buttonSearch"
                                        >
                                            <FontAwesomeIcon
                                                className="bg-transparent"
                                                icon={faSearch}
                                            />
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className=" mt-2">
                            <FontAwesomeIcon
                                icon={faFilter}
                                className="pe-2"
                            />
                            Filtereinstellungen
                        </div>
                        <div className='row'>
                            
                            <div className='col-8 border-end border-dark'>
                                <div className="row row-cols-md-4 g-0">
                                    <div className="col-2">
                                        <p className="fw-bold">Anodenbau:</p>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="anodenbautypS2S"
                                                name="anodenbautyp"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.anodenbautyp.includes('s2s')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.anodenbautyp.includes('s2s')){
                                                        newinputsFilterData.anodenbautyp = newinputsFilterData.anodenbautyp.filter( x => x !== 's2s');
                                                    }
                                                    else{
                                                        newinputsFilterData.anodenbautyp.push('s2s');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                S2S
                                            </label>
                                        </div>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="anodenbautypS2S"
                                                name="anodenbautyp"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.anodenbautyp.includes('r2r')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.anodenbautyp.includes('r2r')){
                                                        newinputsFilterData.anodenbautyp = newinputsFilterData.anodenbautyp.filter( x => x !== 'r2r');
                                                    }
                                                    else{
                                                        newinputsFilterData.anodenbautyp.push('r2r');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                R2R
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-2">
                                        <p className="fw-bold">Batteriebau:</p>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="batteriebautypeVZ"
                                                name="batteriebautype"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.batteriebautype.includes('vz')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.batteriebautype.includes('vz')){
                                                        newinputsFilterData.batteriebautype = newinputsFilterData.batteriebautype.filter( x => x !== 'vz');
                                                    }
                                                    else{
                                                        newinputsFilterData.batteriebautype.push('vz');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                VZ
                                            </label>
                                        </div>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="batteriebautypeHZ"
                                                name="batteriebautype"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.batteriebautype.includes('hz')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.batteriebautype.includes('hz')){
                                                        newinputsFilterData.batteriebautype = newinputsFilterData.batteriebautype.filter( x => x !== 'hz');
                                                    }
                                                    else{
                                                        newinputsFilterData.batteriebautype.push('hz');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                HZ
                                            </label>
                                        </div>
                                        {/* <div className="form-check ms-2">
                                            <input 
                                                id="batteriebautypePZ"
                                                name="batteriebautype"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.batteriebautype.includes('pz')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.batteriebautype.includes('pz')){
                                                        newinputsFilterData.batteriebautype = newinputsFilterData.batteriebautype.filter( x => x !== 'pz');
                                                    }
                                                    else{
                                                        newinputsFilterData.batteriebautype.push('pz');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                PZ
                                            </label>
                                        </div> */}
                                    </div>

                                    <div className="col-2">
                                        <p className="fw-bold">Batterietest:</p>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="batterietestVZ"
                                                name="batterietest"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.batterietest.includes('vz')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.batterietest.includes('vz')){
                                                        newinputsFilterData.batterietest = newinputsFilterData.batterietest.filter( x => x !== 'vz');
                                                    }
                                                    else{
                                                        newinputsFilterData.batterietest.push('vz');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                VZ
                                            </label>
                                        </div>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="batterietestHZ"
                                                name="batterietest"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.batterietest.includes('hz')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.batterietest.includes('hz')){
                                                        newinputsFilterData.batterietest = newinputsFilterData.batterietest.filter( x => x !== 'hz');
                                                    }
                                                    else{
                                                        newinputsFilterData.batterietest.push('hz');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                HZ
                                            </label>
                                        </div>
                                        {/* <div className="form-check ms-2">
                                            <input 
                                                id="batterietestPZ"
                                                name="batterietest"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.batterietest.includes('pz')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.batterietest.includes('pz')){
                                                        newinputsFilterData.batterietest = newinputsFilterData.batterietest.filter( x => x !== 'pz');
                                                    }
                                                    else{
                                                        newinputsFilterData.batterietest.push('pz');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                PZ
                                            </label>
                                        </div> */}
                                    </div>

                                    <div className="col-3">
                                        <p className="fw-bold">Charakterisierung:</p>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="characterisierungLimi"
                                                name="characterisierung"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.characterisierung.includes('mikroskopie')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.characterisierung.includes('mikroskopie')){
                                                        newinputsFilterData.characterisierung = newinputsFilterData.characterisierung.filter( x => x !== 'mikroskopie');
                                                    }
                                                    else{
                                                        newinputsFilterData.characterisierung.push('mikroskopie');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                LiMi
                                            </label>
                                        </div>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="characterisierungXRD"
                                                name="characterisierung"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.characterisierung.includes('xrd')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.characterisierung.includes('xrd')){
                                                        newinputsFilterData.characterisierung = newinputsFilterData.characterisierung.filter( x => x !== 'xrd');
                                                    }
                                                    else{
                                                        newinputsFilterData.characterisierung.push('xrd');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                XRD
                                            </label>
                                        </div>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="characterisierungrem"
                                                name="characterisierung"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.characterisierung.includes('rem')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.characterisierung.includes('rem')){
                                                        newinputsFilterData.characterisierung = newinputsFilterData.characterisierung.filter( x => x !== 'rem');
                                                    }
                                                    else{
                                                        newinputsFilterData.characterisierung.push('rem');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                REM
                                            </label>
                                        </div>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="characterisierungraman"
                                                name="characterisierung"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.characterisierung.includes('raman')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.characterisierung.includes('raman')){
                                                        newinputsFilterData.characterisierung = newinputsFilterData.characterisierung.filter( x => x !== 'raman');
                                                    }
                                                    else{
                                                        newinputsFilterData.characterisierung.push('raman');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                PM REM
                                            </label>
                                        </div>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="characterisierungpm"
                                                name="characterisierung"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.characterisierung.includes('pm')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.characterisierung.includes('pm')){
                                                        newinputsFilterData.characterisierung = newinputsFilterData.characterisierung.filter( x => x !== 'pm');
                                                    }
                                                    else{
                                                        newinputsFilterData.characterisierung.push('pm');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                PM Foto
                                            </label>
                                        </div>
                                        <div className="form-check ms-2">
                                            <input 
                                                id="characterisierunghafttest"
                                                name="characterisierung"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.characterisierung.includes('hafttest')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.characterisierung.includes('hafttest')){
                                                        newinputsFilterData.characterisierung = newinputsFilterData.characterisierung.filter( x => x !== 'hafttest');
                                                    }
                                                    else{
                                                        newinputsFilterData.characterisierung.push('hafttest');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                Sonstiges
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className='col'>
                                <div className="row">
                                    {/* <div className="col">
                                        <p className="fw-bold">Paket:</p>
                                        <div className="form-check ms-4">
                                            <input 
                                                id="confirmationStateOfPackageTrue"
                                                name="confirmationStateOfPackage"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.confirmationStateOfPackage.includes('bestätigt')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.confirmationStateOfPackage.includes('bestätigt')){
                                                        newinputsFilterData.confirmationStateOfPackage = newinputsFilterData.confirmationStateOfPackage.filter( x => x !== 'bestätigt');
                                                    }
                                                    else{
                                                        newinputsFilterData.confirmationStateOfPackage.push('bestätigt');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                Bestätigt
                                            </label>
                                        </div>
                                        <div className="form-check ms-4">
                                            <input 
                                                id="confirmationStateOfPackageFalse"
                                                name="confirmationStateOfPackage"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.confirmationStateOfPackage.includes('unbestätigt')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.confirmationStateOfPackage.includes('unbestätigt')){
                                                        newinputsFilterData.confirmationStateOfPackage = newinputsFilterData.confirmationStateOfPackage.filter( x => x !== 'unbestätigt');
                                                    }
                                                    else{
                                                        newinputsFilterData.confirmationStateOfPackage.push('unbestätigt');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                Unbestätigt
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="col">
                                        <p className="fw-bold">Status:</p>
                                        <div className="form-check ms-4">
                                            <input 
                                                id="statePlanned"
                                                name="state"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.state.includes('inPlanung')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.state.includes('inPlanung')){
                                                        newinputsFilterData.state = newinputsFilterData.state.filter( x => x !== 'inPlanung');
                                                    }
                                                    else{
                                                        newinputsFilterData.state.push('inPlanung');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                In Planung
                                            </label>
                                        </div>
                                        <div className="form-check ms-4">
                                            <input 
                                                id="stateActive"
                                                name="state"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.state.includes('aktiv')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.state.includes('aktiv')){
                                                        newinputsFilterData.state = newinputsFilterData.state.filter( x => x !== 'aktiv');
                                                    }
                                                    else{
                                                        newinputsFilterData.state.push('aktiv');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                Aktiv
                                            </label>
                                        </div>
                                        <div className="form-check ms-4">
                                            <input 
                                                id="stateDone"
                                                name="state"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.state.includes('abgeschlossen')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.state.includes('abgeschlossen')){
                                                        newinputsFilterData.state = newinputsFilterData.state.filter( x => x !== 'abgeschlossen');
                                                    }
                                                    else{
                                                        newinputsFilterData.state.push('abgeschlossen');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                Abgeschlossen
                                            </label>
                                        </div>
                                        {/* <div className="form-check ms-4">
                                            <input 
                                                id="stateDeleted"
                                                name="state"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.state.includes('gelöscht')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.state.includes('gelöscht')){
                                                        newinputsFilterData.state = newinputsFilterData.state.filter( x => x !== 'gelöscht');
                                                    }
                                                    else{
                                                        newinputsFilterData.state.push('gelöscht');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                Gelöscht
                                            </label>
                                        </div> */}
                                        <div className="form-check ms-4">
                                            <input 
                                                id="stateLocked"
                                                name="state"
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={inputsFilter.state.includes('gesperrt')}
                                                onChange={event => {
                                                    let newinputsFilterData = {...inputsFilterData}
                                                    if(newinputsFilterData.state.includes('gesperrt')){
                                                        newinputsFilterData.state = newinputsFilterData.state.filter( x => x !== 'gesperrt');
                                                    }
                                                    else{
                                                        newinputsFilterData.state.push('gesperrt');
                                                    }
                                                    changingFilter(newinputsFilterData);
                                                }}    
                                            />

                                            <label className="form-check-label">
                                                Gesperrt
                                            </label>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { ApiBackendData } from '../backend/ApiBackendData';
import InputField from '../UI/InputField';
import Config from '../util/Config';
import { FileAddData, __FileResponse } from '../backend/types/__GeneralStructures';
import { Table } from './Table';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiFile } from '../backend/ApiFiles';
import { InfoModal } from './Modal';


interface FileUploadProps {
  modalId: string;
  handleConfirm: (data: FormData) => FormData;
  handleReloadPage: () => void;
}
interface FileUploadAccordeonProps {
  files: __FileResponse[];
  handleConfirm: (data: FormData) => FormData;
  handleReloadPage: () => void;
}

const FileUpload = ({
  modalId,
  handleConfirm,
  handleReloadPage,
}: FileUploadProps) => {

  const handlePromptConfirmation = async (event: FormEvent) => {
    event.preventDefault();
    let fileData: FileAddData = {
      name: inputsHeader.title,
      platform: Config.Platform,
      filetype: inputsHeader.filetype
    };
    let newFormData = formDataUpload;
    newFormData.append("fileData",JSON.stringify(fileData));
    await handleConfirm(newFormData);
    handleReloadPage();
  };
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const fileTypes: string[] = enums.FileType;
  const [formDataUpload, setFormData] = useState<FormData>(new FormData());
  const [inputsHeader, setInputsHeader] = useState({
    title: "",
    filetype: "",
    platform: Config.Platform
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputsHeader((values) => ({ ...values, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setInputsHeader({ ...inputsHeader, [name]: value });
  };
  const [typeError, setTypeError] = useState('');
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const formData = new FormData();
    let file: File |null = event.target.files ? event.target.files[0]  : null;
    if(file){
        if(fileTypes.includes(file.type.split('/')[1].toLowerCase())){
          const newHeader = {...inputsHeader};
          newHeader.title = file.name.split('.')[0];
          newHeader.filetype = file.type.split('/')[1].toLowerCase() ?? inputsHeader.filetype;
          setInputsHeader(newHeader);
          setTypeError('');
          formData.append("file", file);
          await setFormData(formData);
        }
        else{
          setTypeError('Ungültiger Dateityp')
        }
    }
  };
  const onDismiss = () => {
    setInputsHeader({
      title: "",
      filetype: "",
      platform: Config.Platform
    });
  };
  const modalElement = document.getElementById(modalId) as HTMLElement;
  useEffect(()=>{
    if(modalElement){
      modalElement.addEventListener('hidden.bs.modal', () => onDismiss() );
    }
  });

  return (
    <>
      <div className='modal fade' id={modalId} tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-dialog-middle">
          <div className="modal-content modal-background-lightgrey small-modal">
            <div className="modal-header mb-0">
              <h2 className="modal-title">Datei hochladen</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body pt-0 my-0">
              <div className='row my-0'>
                <div className='col'>
                  <label htmlFor=''>Titel</label>
                  <InputField
                      inputType='text'
                      name='title'
                      placeholder={inputsHeader.title}
                      value={inputsHeader.title}
                      changeHandler={handleChange}
                      disabled= {false}
                      inputClass='text-md border-bottom-primary'
                  />
                </div>
                <div className='col'>
                  <label htmlFor=''>Dateityp </label>
                  <div className='m-0' style={{ visibility: 'hidden', height: 0 }}>
                      <InputField
                          inputType='text'
                          name='filetype'
                          placeholder={inputsHeader.filetype}
                          value={inputsHeader.filetype}
                          changeHandler={handleChange}
                          disabled={true}
                          inputClass='text-center text-md border-bottom-primary'
                      />
                  </div>
                  <select
                      name='filetype'
                      value={inputsHeader.filetype}
                      onChange={(event) => handleOptionChange(event)}
                      disabled = {true}
                      className='input-lg text-center text-md border-bottom-primary margin-bottom-10'
                      >
                      {fileTypes.map((option) => (
                          <option key={option} value={option}>
                          {option}
                          </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className='row mb-0'>
                <div className='col'>
                  <label htmlFor='fileUpload'> Datei Upload</label>
                  <input
                      id = 'filesUpload'
                      type= 'file'
                      name='file'
                      accept='image/png,application/pdf,image/jpg,image/jpeg,image/tif,image/tiff'
                      className='fileUploadfield'
                      onChange={handleFileChange}
                  />
                  {typeError !== '' ? 
                  <p className='text-err-color-font'>{typeError}</p>
                  :<></>
                  }
                </div>
              </div>
            </div>
            <div className='modal-footer py-0'>
              <button 
                type="button" 
                name='cancelButton'
                id='cancelButton'
                className='btn-main margin-left-15'
                data-bs-dismiss="modal"> Abbrechen
              </button>
              <button
                className='btn-main'
                type='button'
                name='confirmButton'
                id='confirm'
                onClick={handlePromptConfirmation}
                disabled = {inputsHeader.filetype === ''} 
                data-bs-dismiss="modal"
                > Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
    
  );
};

const FileUploadAccordeon =  ({
  handleConfirm,
  handleReloadPage,
  files,
}: FileUploadAccordeonProps) =>  {
  const tableHeaders = ['Titel', 'Typ', '']
  const [displayedTable, setDisplayedTable] = useState<string[][]>([]);
  const [initialized, setInitialisation] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    if(!initialized){
      const table: string[][] = [];
      for(let file of files){
        const row = [file.id, file.name, file.filetypeId];
        table.push(row);
      }
      setDisplayedTable(table);
      setInitialisation(true);
    }
  });
  const handleDelete = async (index: number) => {
    const id = displayedTable[index][0];
    await ApiFile.Delete(id).then(()=>{
      handleReloadPage();
    });
  };
  const handleDownload = async (index:number) =>{
    const id: string = displayedTable[index][0];
    await ApiFile.Download(id).then((url) => {
      window.open(url, '_blank');
    })
    .catch((e:unknown)=>{
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };

  const someFunctions = {
    func: [handleDownload, handleDelete],
    val: ['Download', 'Löschen'],
    defaultState: ["aktiv", "aktiv"],
    modal: [true, true]
  };

  const handleAccordeon = () =>{
    setOpenDropdown(!openDropdown);
  };
  
  return(<>
  <div className='input-wrapper'>
    <span className='input-icon-left mt-0 pt-0' onClick={handleAccordeon}>
      <FontAwesomeIcon icon={openDropdown ? faChevronUp : faChevronDown}/>
    </span>
    <h2 className='ms-4'> Anhängte Dateien </h2>
  </div>
  {openDropdown?
   <div className='row'>
      <div className='col-6'>
        <button
            type='button'
            className='btn-main ms-4'
            data-bs-toggle='modal'
            data-bs-target='#FileUploadModalArbeitspakete'
        > Neue Datei hinzufügen
        </button>
      </div>
      <FileUpload
          modalId='FileUploadModalArbeitspakete'
          handleConfirm={handleConfirm}
          handleReloadPage={handleReloadPage}
      />
      <div className='row ms-2'>
        {displayedTable.length > 0?
          <Table
            modalIDList={someFunctions.val.map(x => 'FileUpload_' + x)}
            modalTextList={['Soll die Datei gedownloaded werden?', 'Soll die Datei wirklich gelöscht werden?']}
            headers={tableHeaders}
            valuesList={displayedTable}
            stateList={null}
            functionsList={someFunctions}
          />
          : <></>
        }  
      </div>
      <InfoModal
        modalID='ErrorDownloadFailed'
        infoText='Download fehlgeschlagen'
        confirmationText='Ok'
      />
    </div>
    : <></>
  }
  </>
  )
};
export { FileUpload };
export default FileUploadAccordeon;

import { Chart as ChartJS, Tooltip, Legend, Color, LinearScale,  CategoryScale, BarElement, Title } from 'chart.js';
import { Bar} from 'react-chartjs-2';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export interface BarChartProps{
    labels: string[];
    datasets: BarCharDatasetProps[];   
    optionData?: BarChartOptionsProps;
};

export interface BarCharDatasetProps {
    label?: string;
    backgroundColor?: Color|Color[];
    base?: number; //Base value for the bar in data units along the value axis.
    barPercentage?: number;
    barThickness?: number | 'flex';
    borderColor?: Color|Color[];
    borderSkipped?: boolean | "top" | "left" | "right" | "bottom" | "start" | "end" | "middle"; //The edge to skip when drawing bar.
    borderWidth?: number;
    borderRadius?: number|object;
    categoryPercentage?: number; //Percent (0-1) of the available width each category should be within the sample width
    grouped?: boolean;
    hoverBackgroundColor?: Color;
    hoverBorderColor?: Color;
    hoverBorderRadius? : number;
    hoverBorderWidth?: number;
    data: number[];
    inflateAmount?: number|'auto';
    maxBarThickness? : number;	
    minBarLength?: number; //Set this to ensure that bars have a minimum length in pixels.
    order?: number; //The drawing order of dataset. Also affects order for stacking, tooltip and legend
    skipNull?: boolean;	//If true, null or undefined values will not be used for spacing calculations when determining bar size.
    stack?: string; //The ID of the group to which this dataset belongs to (when stacked, each group will be a separate stack).
    xAxisID?: string; //The ID of the x-axis to plot this dataset on.
    yAxisID? : string; //The ID of the y-axis to plot this dataset on.
    clip?: number|object|false // How to clip relative to chartArea. Positive value allows overflow, negative value clips that many pixels inside chartArea. 0 = clip at chartArea. Clipping can also be configured per side: clip: {left: 5, top: false, right: -2, bottom: 0}
};

export interface BarChartOptionsProps{
    title?:  string; 
    responsive?: boolean;
    stacked?: boolean;
    horizontal?: boolean;
    legendposotion?: 'top'|'bottom'|'right'|'left';
};

export const BarChart = ({
    labels,
    datasets,
    optionData
    }:BarChartProps) =>{
        let dataCollection = {
            labels: labels,
            datasets: datasets
        };
        const options = optionData ? {
            plugins : {
                title:{
                    display: optionData.title? true: false,
                    text: optionData.title?? ''
                },
                legend: {
                    display: false
                },
            },
            responsive: optionData.responsive ?? true,
            scales:{        
                x:{
                    stacked: optionData.stacked ?? false,
                    ticks:{
                        font:{
                            size: 0
                        }
                    }
                },
                y: {
                    stacked: optionData.stacked?? false,
                    ticks:{
                        font:{
                            size: 16
                        }
                    }
                    
                },
            },
            indexAxis: optionData.horizontal ? 'y' as const : 'x' as const
        } : {};

        return (
            <Bar
                data = {dataCollection}
                options={options}
            />
        );
};


import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = ({ image, currentUser, logoutHandler }: any) => {
  return (
    <header className='header-norc'>
      <img src={image} alt=''/>
      <p className='header-user-name pointer' >{currentUser ? currentUser.displayName : ""}</p>
      <p className='header-user-icon pointer' onClick={logoutHandler}>
        <FontAwesomeIcon className='vertical-middle' icon={faDoorOpen} title='Logout' />
      </p>
    </header>
  );
};

export default Header;

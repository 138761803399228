import React,{ ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiWorkPackage } from '../../backend/ApiWorkPackage';
import InputField from '../../UI/InputField';
import { __PlannedStepsConverted } from '../../backend/types/__GeneralStructures';
import { ApiSummary } from '../../backend/ApiSummary';
import Config from '../../util/Config';
import * as requestTypes from '../../backend/types/__RequestStructures';
import Loading from '../../components/Ladebalken';
import Cookies from 'js-cookie';

interface CreateTableEntryProps {
  inputElement: any;
  index: number;
  keyName: string;
  subIndex: number;
  handleChangeTable: (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
};
const createChangeHandler =
  <T extends { [key: string]: any }>(
    table: T[],
    setInputs: React.Dispatch<React.SetStateAction<T[]>>
  ) =>
  (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    const list = [...table];
    let val: string| number = value;
    if(['anodenbaupunkte','batteriebaupunkte','batterietestpunkte','analysepunkte'].includes(name)){
      val = Number(val);
    }
    list[index] = { ...list[index], [name]: val };
    setInputs(list);
};
export function CreateTableEntry({
  inputElement,
  index,
  keyName,
  handleChangeTable,
}: CreateTableEntryProps) {
  return (
    <InputField
      inputType='text'
      name={keyName}
      placeholder={''}
      value={inputElement}
      changeHandler={(event) => handleChangeTable(event, index)}
      disabled={false}
      inputClass='text-center text-md cut-text-overflow margin-top-5'
    />
  );
}

export default function ArbeitspaketZusammenfassung () {
  const navigate = useNavigate();
  const location = useLocation();
  const packageID = location.state.packageID ?? '';

  const [openLoadingModal, setOpenLoadingModal] = useState(true);
  //get textarea height 
  const [textAreaHeight, setTextAreaHeight] = useState(Cookies.get('textareaHeightZusammenfassung_'+packageID) ?? '200px');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [initialized, setInitialized] = useState(false);


  // const countPoints =()=> {
  //   let sum = 0;
  //   for(let item of inputstable){
  //     if(item.anodenbaucheck){
  //       sum += item.anodenbaupunkte
  //     }
  //     if(item.batteriebaucheck){
  //       sum += item.batteriebaupunkte
  //     }
  //     if(item.batterietestcheck){
  //       sum += item.batterietestpunkte
  //     }
  //     if(item.analysecheck){
  //       sum += item.analysepunkte
  //     }
  //   }
  //   return sum;
  // };
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) =>{
    const { name, value } = event.target;
    setInputsHeader((values) => ({ ...values, [name]: value }));
  };
  const [inputstable, setInputsTable] = useState<requestTypes.__EvaluationProps[]>([]);
  const [inputsHeader, setInputsHeader] = useState({
    id: '',
    verantwortlicher: '',
    date: '',
    currentFullDate:new Date(),
    tableLength: 0,
    zusammenfassung: '',
    fazit: '',
    schlagwort: '',
    gesamtpunkte: 0,
    ausblick: ''
  });


  //Handle Functions
  const handleChangeTable = createChangeHandler<requestTypes.__EvaluationProps>(
    inputstable,
    setInputsTable
  );
  const handleSubmit = () =>{
    setOpenLoadingModal(true);
    if(inputsHeader.id !== ''){
      ApiSummary.Edit({
          id:inputsHeader.id,
          view: inputsHeader.ausblick,
          catchphrase: '',
          points: 0,
          conclusion: inputsHeader.fazit,
          summaryText: inputsHeader.zusammenfassung,
          date: new Date()
      }).then(()=>{
        // ApiWorkPackage.Close({id: packageID}).then(()=>{
          ApiWorkPackage.Get(packageID)
            .then((data)=>{
              // navigate('/arbeitspakete/details', {
              //   state: {
              //     workpackage: [data.id, data.name, data.responsibleUser[0].displayName,data.name, data.state.name, data.locationOfSynthesis.name]
              //   },
              // });
              navigate(-1);
            });
        // });
      });
    }
    else{
      ApiSummary.Create({
        platform: Config.Platform,
        workpackageID: packageID,
        summaryText: inputsHeader.zusammenfassung,
        points: 0,
        conclusion: inputsHeader.fazit,
        catchphrase: '',
        view: inputsHeader.ausblick
      })
      .then(async()=>{
        // ApiWorkPackage.Close({id: packageID}).then(()=>{
          ApiWorkPackage.Get(packageID)
            .then((data)=>{
              navigate( -1
              //   '/arbeitspakete/details', {
              //   state: {
              //     workpackage: [data.id, data.name, data.responsibleUser[0].displayName,data.name, data.state.name, data.locationOfSynthesis.name]
              //   },
              // }
            );
            // });
        });
      });
    }
  };
  const handleChangeTextAreaHeight = async() => {
    //notify if height changed
    const textarea = textareaRef.current;
    if(textarea){
      let value = String(textarea.scrollHeight)+'px';
      if(value!==textAreaHeight){
        //set textarea height
        setInitialized(false);
        setTextAreaHeight(value);
        Cookies.set('textareaHeightZusammenfassung_'+packageID, value);
      }
    }
  };

  //Use Effects
  useEffect(() => {
    //check if textarea height changed with MutationObserver
    const textarea = textareaRef.current;
    if(!textarea) return;

    const observer = new MutationObserver(() => {
      handleChangeTextAreaHeight();
    });

    observer.observe(textarea, {
      attributes: true,
      attributeFilter: ['style']
    });

    return () => {
      observer.disconnect();
    };
  },);
  useEffect(() => {
    document.title = 'Zusammenfassung';
    if(!initialized){
      setOpenLoadingModal(true);
      ApiWorkPackage.Get(packageID).then((data)=>{
        let dictList: requestTypes.__EvaluationProps[] = [];
        let numberOfRows = 0;
        for(let block of data.workBlocks){
          let anode:boolean = false;
          let battery: boolean = false;
          let test: boolean = false;
          let analysis: boolean = false;
          let steps: __PlannedStepsConverted = JSON.parse(block.plannedSteps) as __PlannedStepsConverted;
          if(block.anodeConstruction && (steps.anodeConstructionR2R !== null || steps.anodeConstructionSheetToSheet !== null)){
            anode = true;
            numberOfRows += 1;
          }
          if(block.batteryConstruction &&block.batteryConstruction.length > 0 && steps.batteryConstruction !== null && (steps.batteryConstruction.HZ !== null || steps.batteryConstruction.VZ !== null)){
            battery = true;
            numberOfRows += 1;
          }
          if(block.batteryTest &&block.batteryTest.length > 0 &&steps.batteryTest !== null && (steps.batteryTest.HZ !== null || steps.batteryTest.VZ !== null)){
            test = true;
            numberOfRows += 1;
          }
          if(block.characteristics && steps.analysis !== null){
            analysis = true;
            numberOfRows += 1;
          }
          dictList.push({
            workblockid: block.id,
            workblockname: block.name,
            anodenbaucheck: anode,
            anodenbaupunkte:  anode 
              ? block.anodeConstruction 
                ? block.anodeConstruction.evaluation
                  ? block.anodeConstruction.evaluation.points
                  : 0
                : block.anodeConstructionR2R
                  ? block.anodeConstructionR2R.evaluation
                    ? block.anodeConstructionR2R.evaluation.points
                    : 0
                  : 0
              : 0,
            anodenbaukommentar: anode 
            ? block.anodeConstruction 
              ? block.anodeConstruction.evaluation
                ? block.anodeConstruction.evaluation.comment
                : ''
              : block.anodeConstructionR2R
                ? block.anodeConstructionR2R.evaluation
                  ? block.anodeConstructionR2R.evaluation.comment
                  : ''
                : ''
            : '',
            batteriebaucheck: battery,
            batteriebaupunkte: battery && block.batteryConstruction
              ? block.batteryConstruction.length > 0
                ? block.batteryConstruction[0].evaluation
                  ? block.batteryConstruction[0].evaluation.points
                  : 0
                : 0
              : 0 ,
            batteriebaukommentar: battery && block.batteryConstruction
            ? block.batteryConstruction.length > 0
              ? block.batteryConstruction[0].evaluation
                ? block.batteryConstruction[0].evaluation.comment
                : ''
              :''
            : '',
            batterietestcheck: test,
            batterietestpunkte: test && block.batteryTest
            ? block.batteryTest.length > 0
              ? block.batteryTest[0].evaluation
                ? block.batteryTest[0].evaluation.points
                : 0
              : 0
            : 0,
            batterietestkommentar: test && block.batteryTest
            ? block.batteryTest.length > 0
              ? block.batteryTest[0].evaluation
                ? block.batteryTest[0].evaluation.comment
                : ''
              : ''
            : '',
            analysecheck: analysis,
            analysepunkte: analysis && block.characteristics
            ? block.characteristics.evaluation
              ? block.characteristics.evaluation.points
              : 0
            : 0,
            analysekommentar: analysis && block.characteristics
            ? block.characteristics.evaluation
              ? block.characteristics.evaluation.comment
              : ''
            : ''
          });
        }
        setInputsTable(dictList);
        // date
        let currentFullDate = new Date();
        let month: number|string = currentFullDate.getMonth()+1;
        if (month < 10){
          month = '0' + month.toString();
        }
        else{
          month = month.toString();
        }
        let date =  [currentFullDate.getDate().toString(),month,currentFullDate.getFullYear().toString()].join('.');
        setInputsHeader({
          id: data.summary?.id ?? '',
          verantwortlicher: location.state.user.join(', ') ?? '',
          date:date,
          currentFullDate: currentFullDate,
          tableLength: numberOfRows ?? 1,
          zusammenfassung: data.summary ? data.summary.summaryText : '',
          fazit: data.summary ? data.summary.conclusion : '',
          schlagwort: '',
          gesamtpunkte: 0,
          ausblick: data.summary ? data.summary.view : ''
        });
      });
      setInitialized(true);
      setOpenLoadingModal(false);
    }
  },[]);
  useEffect(()=>{
    if(!openLoadingModal){
      setInitialized(true);
    };
  },[textAreaHeight]);
  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      <h1>Zusammenfassung</h1>
      <div className='margin-top-20'>
          <div className='row'>
            <div className='col-2'>
              <p className='fw-bold'>Verantwortlicher:</p>
            </div>
            <div className='col-2'>
              <p>{inputsHeader.verantwortlicher}</p>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-2'>
              <p className='fw-bold'>Datum:</p>
            </div>
            <div className='col-2'>
              <p>{inputsHeader.date}</p>
            </div>
          </div>
      </div>
      {/* <table className='main-table margin-top-15 hover-effect'>
        <thead>
          <tr>
            <th>Arbeitsblock</th>
            <th>Bereich</th>
            <th>Punkte</th>
            <th>Kommentar</th>
          </tr>
        </thead>
        <tbody>
        {inputstable.map((input,index)=>(
          <>
            {input.anodenbaucheck ?
              
            <tr key={index}>
              <td>{input.workblockname}</td>
              <td>Anodenbau</td>
              <td>
                <div className='input-wrapper'>
                  <input
                    type='number'
                    name='anodenbaupunkte'
                    value={input.anodenbaupunkte}
                    id=''
                    min='0'
                    max='10'
                    className='input-lg text-md border-bottom-primary '
                    onChange={(event) => {
                      handleChangeTable(event,index);
                    }}
                  />
                </div>
              </td>
              <td>
                <InputField
                  inputType='text'
                  name='anodenbaukommentar'
                  placeholder={''}
                  value={input.anodenbaukommentar}
                  changeHandler={(event) => handleChangeTable(event, index)}
                  disabled={false}
                  inputClass='text-center text-md cut-text-overflow margin-top-5'
                />
              </td>
            </tr>
            : <></> }

            { input.batteriebaucheck?
            <tr key={index}>
              <td>{input.workblockname}</td>
              <td>Batteriebau</td>
              <td>
                <div className='input-wrapper'>
                  <input
                    type='number'
                    name='batteriebaupunkte'
                    value={input.batteriebaupunkte}
                    id=''
                    min='0'
                    max='10'
                    className='input-lg text-md border-bottom-primary '
                    onChange={(event) => {
                      handleChangeTable(event, index);
                    }}
                  />
                </div>
              </td>
              <td>
                <InputField
                  inputType='text'
                  name='batteriebaukommentar'
                  placeholder={''}
                  value={input.batteriebaukommentar}
                  changeHandler={(event) => handleChangeTable(event,index)}
                  disabled={false}
                  inputClass='text-center text-md cut-text-overflow margin-top-5'
                />
              </td>
            </tr>
          : <></>
          }
          { input.batterietestcheck?
            <tr key={index}>
              <td>{input.workblockname}</td>
              <td>Batterietest</td>
              <td>
                <div className='input-wrapper'>
                  <input
                    type='number'
                    name='batterietestpunkte'
                    value={input.batterietestpunkte}
                    id=''
                    min='0'
                    max='10'
                    className='input-lg text-md border-bottom-primary '
                    onChange={(event) => {
                      handleChangeTable(event, index);
                    }}
                  />
                </div>
              </td>
              <td>
                <InputField
                  inputType='text'
                  name='batterietestkommentar'
                  placeholder={''}
                  value={input.batterietestkommentar}
                  changeHandler={(event) => handleChangeTable(event,index)}
                  disabled={false}
                  inputClass='text-center text-md cut-text-overflow margin-top-5'
                />
              </td>
            </tr>
          : <></>
          }


          { input.analysecheck?
            <tr key={index}>
              <td>{input.workblockname}</td>
              <td>Analyse</td>
              <td>
                <div className='input-wrapper'>
                  <input
                    type='number'
                    name='analysepunkte'
                    value={input.analysepunkte}
                    id=''
                    min='0'
                    max='10'
                    className='input-lg text-md border-bottom-primary '
                    onChange={(event) => {
                      handleChangeTable(event,index);
                    }}
                  />
                </div>
              </td>
              <td>
                <InputField
                  inputType='text'
                  name='analysekommentar'
                  placeholder={''}
                  value={input.analysekommentar}
                  changeHandler={(event) => handleChangeTable(event, index)}
                  disabled={false}
                  inputClass='text-center text-md cut-text-overflow margin-top-5'
                />
              </td>
            </tr>
          : <></>
          }



          </>
            ))}
          
        </tbody>
      </table> */}
      {/* <div className='margin-top-20'>
        <div className='row'>
          <div className='col-2'>
            <p className='fw-bold'>Gesamtpunktzahl:</p>
          </div>
          <div className='col-2'>
            <p>{countPoints()}</p>
          </div>
        </div>
        <div className='row mt-2'>
          <div className='col-2'>
            <p className='fw-bold'>Durchschnitt:</p>
          </div>
          <div className='col-2'>
            <p>
              {inputsHeader.tableLength >0 ? Math.round(countPoints()/inputsHeader.tableLength) : 0}
            </p>
          </div>
        </div>
      </div> */}
      <div className='margin-top-15 col-md-12'>
          <label htmlFor='zusammenfassung'>Zusammenfassung</label>
          <textarea
            className='input-lg-textarea-individual-box  border-bottom-primary cut-text-overflow  margin-top-5'
            name='zusammenfassung'
            ref={textareaRef}
            value={inputsHeader.zusammenfassung}
            onChange={event => handleInputChange(event)}
            style={{minHeight:textAreaHeight}}
          />
        </div>
        {/* <div className='margin-top-15 col-md-12'>
          <label htmlFor='fazit'>Fazit</label>
          <textarea
            className='input-lg-textarea-height-box text-md border-bottom-primary cut-text-overflow  margin-top-5'
            name='fazit'
            value={inputsHeader.fazit}
            onChange={event => handleInputChange(event)}
          />
        </div>
        <div className='margin-top-15 col-md-12'>
          <label htmlFor='ausblick'>Ausblick</label>
          <textarea
            className='input-lg-textarea-height-box text-md border-bottom-primary cut-text-overflow  margin-top-5'
            name='ausblick'
            value={inputsHeader.ausblick}
            onChange={event => handleInputChange(event)}
          />
        </div> */}
      <input
          className='btn-main margin-top-15 margin-right-15'
          type='button'
          value='Zurück'
          onClick={()=>navigate(-1)}
      />
      <input
        type='button'
        value='Speichern'
        className='btn-main margin-top-15 margin-right-10'
        onClick={() => handleSubmit()}
      />
    </div>
  )
}

import { useEffect, useState } from 'react';
import Ablaufdiagramm from '../../components/Ablaufdiagramm';
import { useNavigate, useLocation,NavigateOptions } from 'react-router-dom';
import { ApiCharacteristics } from '../../backend/ApiCharacteristics';
import { __FileResponse, __PlannedStepsConverted } from '../../backend/types/__GeneralStructures';
import FileUploadAccordeon from '../../components/FileUpload';
import { ApiFile } from '../../backend/ApiFiles';
import { IBackendCharacteristics } from '../../backend/types/__ResponseStructures';
import { ApiWorkBlock } from '../../backend/ApiWorkBlock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { ApiWorkPackage } from '../../backend/ApiWorkPackage';

export default function CharakterisierungAblauf() {
  const navigate = useNavigate();
  const location = useLocation();
  if(location.state?.characteristicID){
    Cookies.set('characteristicID',location.state.characteristicID);
  }
  const characteristicID = Cookies.get('characteristicID') ?? '';
  const [packageName, setPackageName] = useState('');
  const [packageID, setPackageID] = useState('');

  const [files, setFiles] = useState<__FileResponse[]>([]);
  const [characteristic, setCharacteristic] = useState<IBackendCharacteristics|null>(null)
  // const [openModalAccordion, setOpenModalAccordion] = useState(false);
  // let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  // const [newStateFailed, setNewStateFailed] = useState(false);
  // const [modalInfoText, setModalNavigateText] = useState('');
  const [initialize, setInitialize] = useState(false);
  const [characteristicArray, setCharacteristicArray] = useState<{
    title: string;
    notes: string[];
    status: string;
    lineState: string;
    link: string;
    linkProps:NavigateOptions;
  }[]>([])
  // const [inputs, setInputs] = useState(
  //   characteristic 
  //     ? {state: characteristic.state.name}
  //     : {state: 'aktiv'}
  // );
  const [notPlannedAnalysis, setNotPlannedAnalysis] = useState<string[]>([]);
  const [selectedAnalysis, setSelectedAnalysis] = useState('');

  useEffect(() => {
    document.title = 'Charakterisierung';
  },[]);
  useEffect(()=>{
    if(characteristic === null){
      loadData();
    }
  },[characteristic]);
  // useEffect(()=>{
  //   if(characteristic){
  //     loadData();
  //   }
  // },[inputs]);

  const loadData = () =>{
    if(characteristic === null && characteristicID !== '') {
      ApiCharacteristics.Get(characteristicID)
      .then(async (data) => {
        const myPlannedSteps = JSON.parse(data.workblock.plannedSteps) as __PlannedStepsConverted;
        await getAnalysisLists(myPlannedSteps);
        await setCharacteristic(data);
        const fileList = data.files?? []
        await setFiles(fileList);

        setPackageID(data.workblock.workpackageID);

        //in first call location.state is there -> so setPackageName if location.state is not null and name === ''
        if(location.state?.workpackageName && packageName === ''){
          setPackageName(location.state.workpackageName);
        }
        else if(packageName===''){
          setPackageName(data.workblock.name.split('-')[0]);
        }
        // await setInputs({state:data.state.name});

        let newCharacteristicArray : any[] = [];
        await getAnalysisLists(myPlannedSteps)
        .forEach((characteristicAnalysis: string) => {
          let link = '';
          let typeOfCharacteristicData: any = null;
          let state = 'upcoming';
          let addLink = true;
          /**
           * Charakterisierung
           * Umbenennungen:
              Hafttest -> Sonstiges
              Post Mortem -> PM Foto
              Raman -> PM REM
              Mikroskopie -> LiMi
          */
          switch (characteristicAnalysis) {
            case 'Sonstiges':
              link = 'hafttest';
              typeOfCharacteristicData = data.adhesionTest;
              if(data.adhesionTest || myPlannedSteps.analysis?.adhesionTest) {
                state = data.adhesionTest ? data.adhesionTest.stateID:  'inPlanung';
              }
              break;
            case 'LiMi':
              link = 'mikroskopie';
              typeOfCharacteristicData = data.microscopy;
              if(data.microscopy || myPlannedSteps.analysis?.microscopy) {
                state = data.microscopy ? data.microscopy.stateID:  'inPlanung';
              }
              break;
            case 'REM':
              link = 'rem';
              typeOfCharacteristicData = data.rem;
              if(data.rem || myPlannedSteps.analysis?.rem) {
                state = data.rem ? data.rem.stateID:  'inPlanung';
              }
              break;
            case 'XRD':
              link = 'xrd';
              typeOfCharacteristicData = data.xrd;
              if(data.xrd || myPlannedSteps.analysis?.xrd) {
                state = data.xrd ? data.xrd.stateID:  'inPlanung';
              }
              break;
            case 'PM REM':
              link = 'raman';
              typeOfCharacteristicData = data.raman;
              if(data.raman || myPlannedSteps.analysis?.raman) {
                state = data.raman ? data.raman.stateID:  'inPlanung';
              }
              break;
            case 'PM Foto':
              link = 'postMortemVisuell';
              typeOfCharacteristicData = data.postMortemAnalysis;
              if(data.postMortemAnalysis || myPlannedSteps.analysis?.postMortemAnalysis) {
                state = data.postMortemAnalysis ? data.postMortemAnalysis.stateID:  'inPlanung';
              }
              break;
            case ' ':
              addLink = false;
              break;
            case '':
              addLink = false;
              break;
          }
          /*
          
              status: blockSteps.analysis.state
                ? blockSteps.analysis.state
                : 'inPlanung',
              lineState:
                blockSteps.analysis.state === 'abgeschlossen'
                  ? 'completed'
                  : blockSteps.analysis.state === 'aktiv'
                  ? 'in-progress'
                  : blockSteps.analysis.state === 'gesperrt'
                  ? 'locked'
                  : 'upcoming',

                  
              status: 'upcoming',
              lineState: 'upcoming',
          */
          if(addLink){
            newCharacteristicArray.push({
              title: characteristicAnalysis,
              notes: [],
              status: state ? state : 'inPlanung',
              lineState: state === 'abgeschlossen'
                  ? 'completed'
                  : state === 'aktiv'
                  ? 'in-progress'
                  : state === 'gesperrt'
                  ? 'locked'
                  : 'upcoming',
              link: `/charakterisierung/${link}`,
              linkProps:{
                state:{
                  workblockID: data.workblock.id,
                  workblockName: data.workblock.name,
                  workpackageID: data.workblock.workpackageID,
                  workpackageName: packageName,
                  characteristicsID: characteristicID,
                  data : typeOfCharacteristicData
                }
                
              }
            });
          }
        });
        
        await setCharacteristicArray(newCharacteristicArray);
        await setInitialize(true);

      })
      .catch(e=>{
        console.log(e);
        setInitialize(true);
      });
    }
  };
  //set not plannedlist for dropdown and returns planned list for diagramm
  const getAnalysisLists = (plannedSteps: __PlannedStepsConverted) => {
    let notPlannedList: string[] = [];
    let plannedList: string[] = [];

    if(plannedSteps.analysis && !plannedSteps.analysis?.microscopy){
      notPlannedList.push('LiMi');
    }
    else{
      plannedList.push('LiMi');
    }
    if(plannedSteps.analysis && !plannedSteps.analysis?.xrd){
      notPlannedList.push('XRD');
    }
    else{
      plannedList.push('XRD');
    }
    if(plannedSteps.analysis && !plannedSteps.analysis?.rem){
      notPlannedList.push('REM');
    }
    else{
      plannedList.push('REM');
    }
    if(plannedSteps.analysis && !plannedSteps.analysis?.raman){
      notPlannedList.push('PM REM');
    }
    else{
      plannedList.push('PM REM');
    }
    if(plannedSteps.analysis && !plannedSteps.analysis?.postMortemAnalysis){
      notPlannedList.push('PM Foto');
    }
    else{
      plannedList.push('PM Foto');
    }
    if(plannedSteps.analysis && !plannedSteps.analysis.adhesionTest){
      notPlannedList.push('Sonstiges');
    }
    else{
      plannedList.push('Sonstiges');
    }
    if(notPlannedList.length > 0){
      setSelectedAnalysis(notPlannedList[0]);
    }
    setNotPlannedAnalysis(notPlannedList);
    return plannedList;
  };

  const handleReloadPage = async () => {
    console.log('reload')
  };
  const handleBack = async() => {
    if(packageID==='' || !characteristic) return;

    ApiWorkPackage.Get(packageID)
    .then(packageData=>{
      let data = [packageData.id, packageData.name, packageData.responsibleUser[0].displayName,packageData.name, packageData.state.name, packageData.locationOfSynthesis.name];
      navigate('/arbeitspakete/details/#'+characteristic.workblock.name, {
        state: {
          workpackage: data
        },
      })
    })
  };

  const handleSelectNotPlannedAnalysis = async(event: React.ChangeEvent<HTMLSelectElement>) => {
    let newSelectedAnalysis = event.target.value;
    setSelectedAnalysis(newSelectedAnalysis);
  };
  const handleSubmitSelectedAnalysis = () => {
    if(!characteristic) return;
    //adjust plannedSteps Object and send to api
    let characteristicsCopy = {...characteristic};
    let newPlannedSteps = JSON.parse(characteristicsCopy.workblock.plannedSteps) as __PlannedStepsConverted;

    newPlannedSteps.analysis = {
      adhesionTest: (newPlannedSteps.analysis?.adhesionTest !== undefined && newPlannedSteps.analysis.adhesionTest !== false) ? newPlannedSteps.analysis?.adhesionTest : selectedAnalysis === 'Sonstiges',
      microscopy: (newPlannedSteps.analysis?.microscopy !== undefined && newPlannedSteps.analysis.microscopy !== false) ? newPlannedSteps.analysis?.microscopy : selectedAnalysis === 'LiMi',
      rem: (newPlannedSteps.analysis?.rem !== undefined && newPlannedSteps.analysis.rem !== false) ? newPlannedSteps.analysis?.rem : selectedAnalysis === 'REM',
      xrd: (newPlannedSteps.analysis?.xrd !== undefined && newPlannedSteps.analysis.xrd !== false) ? newPlannedSteps.analysis?.xrd : selectedAnalysis === 'XRD',
      raman: (newPlannedSteps.analysis?.raman !== undefined && newPlannedSteps.analysis.raman !== false) ? newPlannedSteps.analysis?.raman : selectedAnalysis === 'PM REM',
      postMortemAnalysis: (newPlannedSteps.analysis?.postMortemAnalysis !== undefined && newPlannedSteps.analysis.postMortemAnalysis !== false) ? newPlannedSteps.analysis?.postMortemAnalysis : selectedAnalysis === 'PM Foto'
    };
    ApiWorkBlock.EditPlannedSteps(characteristicsCopy.workblock.id,newPlannedSteps)
    .then(()=>{
      //reload page
      setSelectedAnalysis('');
      setNotPlannedAnalysis([]);
      setCharacteristicArray([]);
      setCharacteristic(null);
    })
    .catch(e=>{
      console.log(e);
    });
  };
  //STATE CHANGE//
  // const handleInfoModaClose = () =>{
  //   setNewStateFailed(false);
  //   let overlay = document.getElementById('overlay-root');
  //   if(overlay){
  //     overlay.style.display = 'none';
  //   }
  // }
  // const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const { name, value } = event.target;
  //   if(characteristic){
  //     let requestObject = {id:characteristic.id}
  //   switch (value){
  //     case 'aktiv': 
  //       ApiCharacteristics.Activate(requestObject)
  //         .then((data)=>{
  //           setCharacteristic(null);
  //           setInputs({ ...inputs, [name]: value });
  //         })
  //         .catch(()=>{
  //           setModalNavigateText('Aktualisierung des Status ist fehlgeschlagen.');
  //           setNewStateFailed(true);
  //         });
  //       break;
  //     case 'abgeschlossen':
  //       ApiCharacteristics.Close(requestObject)
  //         .then((data)=>{
  //           setInputs({ ...inputs, [name]: value });
  //         })
  //         .catch(()=>{
  //           setModalNavigateText('Aktualisierung des Status ist fehlgeschlagen.');
  //           setNewStateFailed(true);
  //         });
  //       break;
  //     case 'gesperrt':
  //       ApiCharacteristics.Lock(requestObject)
  //       .then((data)=>{
  //         setInputs({ ...inputs, [name]: value });
  //       })
  //       .catch(()=>{
  //         setModalNavigateText('Aktualisierung des Status ist fehlgeschlagen.');
  //         setNewStateFailed(true);
  //       });
  //       break;
  //     case 'gelöscht':
  //       ApiCharacteristics.Delete(requestObject)
  //       .then((data)=>{
  //         setInputs({ ...inputs, [name]: value });
  //       })
  //       .catch(()=>{
  //         setModalNavigateText('Aktualisierung des Status ist fehlgeschlagen.');
  //         setNewStateFailed(true);
  //       });
  //       break;
  //     }
  //   }
  // };
  
  const submitModalCloseAccordion = (response:FormData)  =>{
    sendFileData(response);
    // send formdata to backend
    return response;
  };
  const sendFileData = async(data:FormData) =>{
    if(characteristic){
      await ApiFile.Upload(data,'/characteristics',characteristic.id);
    }
  };
  // TODO: Add button to go to Arbeitspakete
  return (
    <div className='content-main-container'>
    {/* {newStateFailed ? (
      <Modal
        handleModal={handleInfoModaClose}
        content = {
          <>
            <div className='confirmation-modal'>
              <p>{modalInfoText}</p>
                <div className='row'>
                <input
                  type='button'
                  name='ok'
                  id='ok'
                  value={'ok'}
                  className='btn-main margin-top-15 margin-left-15'
                  onClick={event => handleInfoModaClose()}
                />
              </div>
            </div>
          </>
        }
      />
      ) : (
      <></>
    )}  */}
    {initialize ?
      <>
        <h1 className='margin-bottom-25'>Charakterisierung</h1>
        <div className='row'>
          <form action='' className='margin-top-15'>
            <div className='col-6'>
              <div className='row'>
                  <p className='h5 fw-bold'>Arbeitspaket: {packageName ?? ''}</p>
              </div>
              <div className='row'>
                <p className='h5 fw-bold'>Arbeitsblock: {characteristic?.workblock.name ?? ''}</p>
              </div>
            </div>
            {/*<div className='row my-3'>
              <div className='col-3'>
                <label htmlFor='state'>Status:</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                  <InputField
                    value={inputs.state}
                    inputType='text'
                    name='state'
                    placeholder=''
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <select
                  name='state'
                  value={inputs.state}
                  onChange={(event) => handleOptionChange(event)}
                  className='input-lg text-md border-bottom-primary margin-top-5'
                >
                  {enums.WorkBlockState.map((option: string) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
                </div>*/}
            { notPlannedAnalysis.length > 0
              ? <div className='row my-3'>
                  <div className='col-3'>
                    <div style={{ visibility: 'hidden', height: 0 }}>
                      <input
                        value={selectedAnalysis}
                        type='text'
                        name='selectedAnalysis'
                        placeholder=''
                        className='text-md border-bottom-primary margin-bottom-10'
                      />
                    </div>
                    <select
                      name='notPlanned'
                      value={selectedAnalysis}
                      onChange={(event) => handleSelectNotPlannedAnalysis(event)}
                      className='input-lg text-md border-bottom-primary margin-top-5'
                    >
                      {notPlannedAnalysis.map((option: string) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <button
                      className='btn-main mt-2'
                      onClick={e => handleSubmitSelectedAnalysis()}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className='me-1'
                      />
                      Analyse Hinzufügen
                    </button>
                  </div>
                </div>
              : <></>
            }
            
          </form>
        </div>
        <div className='row'>
          {<Ablaufdiagramm ablauf={characteristicArray} disableLinks={false}/> }
        </div>
        <div className='row mt-2'>
          <div className='col'>
            <input
              type='button'
              value='Zurück'
              className='btn-main margin-top-25'
              onClick={() => handleBack()}
            />
          </div>
          <div className='col'>
            {false?
              <div className='margin-top-20 paket-information-container row'>
                <div className='column'>
                  <FileUploadAccordeon
                    handleConfirm={submitModalCloseAccordion}
                    handleReloadPage={handleReloadPage}
                    files={files}
                  />
                </div>
              </div>
              :<></>
            }
          </div>
        </div>
      </>
    :<></>
    }
    </div>
  );
}

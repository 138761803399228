import Config from "../util/Config";
import {  __PlannedStepsConverted, CreateBasicResponse } from "./types/__GeneralStructures";
import { ApiPrepareRequest } from "./ApiPrepareRequest";
import { ApiClient } from "./ApiClient";
import BasicResponse from "./BasicResponse";
import * as requestTypes from "./types/__RequestStructures"
import * as responseTypes from "./types/__ResponseStructures"


// Sample: /workblock/list ? filter = { "state": { "name": "inPlanung" } }
export interface IBackendWorkBlockFilter {
    state?: { name: string }
};

export class ApiWorkBlock {

    static basePath: string = "/workblock";
    static baseUrl: string = Config.BackendUrl + ApiWorkBlock.basePath;

    //Get
    static Get(id: string = ''): Promise<responseTypes.IBackendWorkBlock> {
        let path = '/get';
    
        // prepare query
        let query = ApiPrepareRequest.PrepareGetQuery(id);
        let url = ApiWorkBlock.baseUrl + path + query;
    
        return new Promise(async (resolve, reject) => {
          let response = await ApiClient.get(url);
          if (!response.ok) {
            if (response.status === 401) {
              reject('Unauthorized!');
            } else {
              reject(`Error! status: ${response.status}`);
            }
          }
    
          let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendWorkBlock>;
    
          resolve(responseObject.data);
        });
    };
    // Get planned steps
    static GetPlannedSteps(id: string = ''): Promise<responseTypes.IBackendPlannedSteps> {
        let path = '/steps';
    
        // prepare query
        let query = ApiPrepareRequest.PrepareGetQuery(id);
        let url = ApiWorkBlock.baseUrl + path + query;
    
        return new Promise(async (resolve, reject) => {
          let response = await ApiClient.get(url);
          if (!response.ok) {
            if (response.status === 401) {
              reject('Unauthorized!');
            } else {
              reject(`Error! status: ${response.status}`);
            }
          }
    
          let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendPlannedSteps>;
    
          resolve(responseObject.data);
        });
    };
    // TODO: add filter and search
    /**
     * /workblock/list
     */
    static ListAll(filter: IBackendWorkBlockFilter = {}, search: string = ""): Promise<responseTypes.IBackendWorkBlock[]> {

        let path = "/list";

        // prepare query
        let query = ApiPrepareRequest.PrepareListQuery(filter, search);
        let url = ApiWorkBlock.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {

            let response = await ApiClient.get(url);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendWorkBlock[]>;

            resolve(responseObject.data);

        });

    };
    /**
     * /workblock/create
     */
    static Create(requestData:requestTypes.__WorkBlockCreate): Promise<string> {

        let path = "/create";
        let url = ApiWorkBlock.baseUrl + path;

        return new Promise(async (resolve, reject) => {
            let response = await ApiClient.post(url, requestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as CreateBasicResponse<string>;

            resolve(responseObject.id);
        });
    };
    /**
     * /workblock/edit
     */
    static Edit(requestData:requestTypes.__WorkBlockEdit): Promise<boolean> {

        let path = "/edit";
        let url = ApiWorkBlock.baseUrl + path;

        return new Promise(async (resolve, reject) => {
            let convertedRequestData = {
                id: requestData.id,
                state: requestData.state,
                date: new Date (requestData.date),
                responsibleUser: requestData.responsibleUser,
                name: requestData.name,
                description:requestData.description,
                comment: requestData.comment,
                locationOfSynthesis: requestData.locationOfSynthesis,
                plannedSteps: JSON.parse(JSON.stringify(requestData.plannedSteps)) as __PlannedStepsConverted
            };
            let response = await ApiClient.put(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<string>;

            resolve(responseObject.success);
        });

    };
    static EditPlannedSteps(blockID:string, plannedSteps: __PlannedStepsConverted): Promise<boolean> {

        let path = "/editplannedsteps";
        let url = ApiWorkBlock.baseUrl + path;

        return new Promise(async (resolve, reject) => {
            let response = await ApiClient.put(url, {id: blockID, plannedSteps: plannedSteps});
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<string>;

            resolve(responseObject.success);
        });

    };
    static EditDescription(blockID:string,description:string): Promise<boolean> {

      let path = "/editdescription";
      let url = ApiWorkBlock.baseUrl + path;

      return new Promise(async (resolve, reject) => {
          let response = await ApiClient.put(url, {id:blockID,description:description});
          if (!response.ok) {
              if (response.status === 401) {
                  reject("Unauthorized!");
              }
              else {
                  reject(`Error! status: ${response.status}`);
              }
          }

          let responseObject = (await response.json()) as BasicResponse<string>;

          resolve(responseObject.success);
      });

    };
    static Delete(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/delete';
        let url = ApiWorkBlock.baseUrl + path;
    
        return new Promise(async (resolve, reject) => {
          let response = await ApiClient.post(url, requestData);
          if (!response.ok) {
            if (response.status === 401) {
              reject('Unauthorized!');
            } else {
              reject(`Error! status: ${response.status}`);
            }
          }
    
          let responseObject =
            (await response.json()) as BasicResponse<string>;
    
          resolve(responseObject.success);
        });
    };
    //lock
    static Lock(requestData: requestTypes.__IDRequest): Promise<boolean> {
      let path = '/lock';
      let url = ApiWorkBlock.baseUrl + path;
  
      return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
          if (response.status === 401) {
            reject('Unauthorized!');
          } else {
            reject(`Error! status: ${response.status}`);
          }
        }
  
        let responseObject =
          (await response.json()) as BasicResponse<string>;
  
        resolve(responseObject.success);
      });
    };
    //activate
    static Activate(requestData: requestTypes.__IDRequest): Promise<boolean> {
      let path = '/activate';
      let url = ApiWorkBlock.baseUrl + path;
  
      return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
          if (response.status === 401) {
            reject('Unauthorized!');
          } else {
            reject(`Error! status: ${response.status}`);
          }
        }
  
        let responseObject =
          (await response.json()) as BasicResponse<string>;
  
        resolve(responseObject.success);
      });
    };
    //close
    static Close(requestData: requestTypes.__IDRequest): Promise<boolean> {
      let path = '/close';
      let url = ApiWorkBlock.baseUrl + path;
  
      return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
          if (response.status === 401) {
            reject('Unauthorized!');
          } else {
            reject(`Error! status: ${response.status}`);
          }
        }
  
        let responseObject =
          (await response.json()) as BasicResponse<string>;
  
        resolve(responseObject.success);
      });
    };
    //upload
    static Upload(requestData: requestTypes.__UploadFiles): Promise<boolean> {
      let path = '/upload';
      let url = ApiWorkBlock.baseUrl + path;
  
      return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
          if (response.status === 401) {
            reject('Unauthorized!');
          } else {
            reject(`Error! status: ${response.status}`);
          }
        }
  
        let responseObject =
          (await response.json()) as BasicResponse<string>;
  
        resolve(responseObject.success);
      });
    };

    /**
     * /workblock/generatepdf
     */
    static GeneratePdf(id: string): Promise<string> {

        let path = "/generatepdf";
        let query = "?id=" + id;
        let url = ApiWorkBlock.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {

            let response = await ApiClient.get(url);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<string>;

            resolve(responseObject.data);
        });

    }

}

export class WorkblockConverter {
    // Temporärer Hinweis: Gedacht für die Tabellen Planung und Arbeitspakete
    static ConvertFromApiToTableList(data: responseTypes.IBackendWorkBlock[]) {
        // [1, 'Toller Versuch', 'Max', '15.03.2023', 'abgeschlossen', 'Halle'],
        let simpleList: string[][] = [];
        data.forEach(item => {
            simpleList.push([
                item.id,
                item.name,
                item.responsibleUser[0].displayName,

                new Date(item.date).toLocaleDateString() + "\n" + new Date(item.date).toLocaleTimeString(),

                item.state.name,
                item.locationOfSynthesis.name
            ]);
        });

        return simpleList;
    }
}
import Config from '../util/Config';
import BasicResponse from './BasicResponse';
import {
  CreateBasicResponse
} from './types/__GeneralStructures';
import * as requestTypes from './types/__RequestStructures';
import * as responseTypes from './types/__ResponseStructures';
import { ApiClient } from './ApiClient';
import { ApiPrepareRequest } from './ApiPrepareRequest';

export class ApiAuth {
  static basePath: string = '/auth';
  static baseUrl: string = Config.BackendUrl + ApiAuth.basePath;
  //get
  static Get(id: string = ''): Promise<responseTypes.IBackendUser> {
    let path = '/get';

    // prepare query
    let query = ApiPrepareRequest.PrepareGetQuery(id);
    let url = ApiAuth.baseUrl + path + query;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject =
        (await response.json()) as BasicResponse<responseTypes.IBackendUser>;

      resolve(responseObject.data);
    });
  }
  // TODO: add filter and search
  /**
   * /auth/list
   */
  static ListAll(): Promise<responseTypes.IBackendUser[]> {
    let path = '/list';
    let url = ApiAuth.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<
        responseTypes.IBackendUser[]
      >;

      resolve(responseObject.data);
    });
  }

  static Login(loginName: string, password: string): Promise<boolean> {
    let path = '/login';
    let url = ApiAuth.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let requestData = {
        loginName: loginName,
        password: password,
        platform: Config.Platform
      };

      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      /*
      let cookie = response.headers;
      let entries = cookie.values();
      setCookie('test', 'abc');
      */

      let responseObject = (await response.json()) as BasicResponse<any>;

      resolve(responseObject.success);
    });
  }
  static Logout(): Promise<boolean> {
    let path = '/logout';
    let url = ApiAuth.baseUrl + path;

    return new Promise(async (resolve, reject) => {

      let response = await ApiClient.post(url, {});
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      /*
      let cookie = response.headers;
      let entries = cookie.values();
      setCookie('test', 'abc');
      */

      let responseObject = (await response.json()) as BasicResponse<any>;

      resolve(responseObject.success);
    });
  }

  static Create(requestData: requestTypes.__UserCreate): Promise<string> {
    let path = '/create';
    let url = ApiAuth.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject =
        (await response.json()) as CreateBasicResponse<string>;

      resolve(responseObject.id);
    });
  }

  static Edit(requestData: requestTypes.__UserEdit): Promise<boolean> {
    let path = '/edit';
    let url = ApiAuth.baseUrl + path;
    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.put(url, requestData);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  static Delete(requestData: requestTypes.__IDRequest): Promise<boolean> {
    let path = '/delete';
    let url = ApiAuth.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  //lock
  static Lock(requestData: requestTypes.__IDRequest): Promise<boolean> {
    let path = '/lock';
    let url = ApiAuth.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  //isauthenticated
  static IsAuthenticated(): Promise<boolean> {
    let path = '/isauthenticated';
    let url = ApiAuth.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      //let response = await 
      ApiClient.get(url)
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 401) {
            reject('Unauthorized!');
          } else {
            reject(`Error! status: ${response.status}`);
          }
        }
  
        let responseObject = (await response.json()) as BasicResponse<boolean>;
  
        resolve(responseObject.data);
      })
      .catch(err => {
        reject(`Error! status: ${err}`);
      });
    });
  }

  static GetMyself(): Promise<responseTypes.IBackendUser> {
    let path = '/getmyself';

    let url = ApiAuth.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject =
        (await response.json()) as BasicResponse<responseTypes.IBackendUser>;

      resolve(responseObject.data);
    });
  }

  static userDisplayNamesList: string[] = [];
  static userLoginNamesList: string[] = [];
  static userIDList: string[] = [];

  static async GetUserNamesList(force:boolean =false){
    if (!force && ApiAuth.userDisplayNamesList.length > 0) {
      return {
        userDisplayNames: ApiAuth.userDisplayNamesList,
        userLoginNames: ApiAuth.userLoginNamesList,
        userIDList: ApiAuth.userIDList
      };
    }

    let users = (await ApiAuth.ListAll()) as responseTypes.IBackendUser[];

    ApiAuth.userDisplayNamesList = [];
    ApiAuth.userLoginNamesList = [];
    ApiAuth.userIDList = [];


    users.forEach((user) => {
      // if(!(user.deleted || user.state.name === 'gelöscht')){;
        ApiAuth.userDisplayNamesList.push(user.displayName);
        ApiAuth.userLoginNamesList.push(user.loginName);
        ApiAuth.userIDList.push(user.id);
      // }
    });

    return {
      userDisplayNames: ApiAuth.userDisplayNamesList,
      userLoginNames: ApiAuth.userLoginNamesList,
      userIDList: ApiAuth.userIDList,
    };
  }
}

export class UserConverter {
  // Temporärer Hinweis: Gedacht für die Tabellen Planung und Arbeitspakete
  static ConvertFromApiToTableList(data: responseTypes.IBackendUser[]) {
    let simpleList: string[][] = [];
    data.forEach((item) => {
      if(!item.deleted) {
        let lastLoginDate = new Date(item.lastLogin.toString())
        simpleList.push([
          item.id,
          item.displayName,
          lastLoginDate.toLocaleDateString(),
          item.state.name,
          item.role.name
        ]);
      }
    });

    return simpleList;
  }
}

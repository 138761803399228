import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import InputField from '../../UI/InputField';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { __BatteryConstructionEdit } from '../../backend/types/__RequestStructures';
import {
  __BatteryVariationConverted,
  __DataAnodeConverted,
  __DataAnodeUnconverted,
  __DataCaseConverted,
  __DataCaseUnconverted,
  __DataCathodeConverted,
  __DataCathodeUnconverted,
  __DataElectrolytConverted,
  __DataElectrolytUnconverted,
  __DataFeatherConverted,
  __DataFeatherUnconverted,
  __DataSeperatorConverted,
  __DataSeperatorUnconverted,
  __DataSpacerConverted,
  __DataSpacerUnconverted,
} from '../../backend/types/__GeneralStructures';
import {
  AnodeForBatteryData,
  BatteryDataForModal,
  CaseForBatteryData,
  CathodeForBatteryData,
  ElectrolytForBatteryData,
  FeatherForBatteryData,
  SeparatorForBatteryData,
  SpacerForBatteryData,
} from './BatteryConstructionData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useUserList } from '../../hooks/UserHooks';
let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
interface BatteriebauInputModalProps {
  modalID:string;
  changeHandler: (item:BatteryDataForModal) => BatteryDataForModal;
  materialNames: NamesAndIDOfMaterials;
  anode: { anode: string; anodengroesse: number,checked:boolean };
  kathode: { kathode: string; kathodengroesse: number,checked:boolean };
  elektrolyt: {
    elektrolyt: string;
    elektrolytvolumen: string;
    elektrolytgesamtvolumen: number;
    checked: boolean;
  };
  separator: {
    separator: string;
    separatorgroesse: number;
    separatoranzahl: number;
    checked: boolean;
  };
  feder: { feder: string; checked: boolean };
  spacer: {
    spacer: string;
    spacergroesse: number;
    spacerdicke: number;
    checked: boolean;
  };
  gehaeuse: { gehaeuse: string; checked: boolean };
};
//edit batterydata
interface BatteriebauInputModalPropsEdit {
  modalID:string;
  changeHandler: (item:BatteryDataForModal) => BatteryDataForModal;
  selectedCelltype: string,
  constructorName: string[],
  state: string,
  date: Date,
  materialNames: NamesAndIDOfMaterials;
  anode: { id:string; anodeName: string;anodeId: string; anodengroesse: number }|null;
  kathode: { id:string;
    kathodeName: string;
    kathodeId: string;
    kathodengroesse: number;
  } | null;
  elektrolyt: {
    id: string;
    elektrolytName: string;
    elektrolytId: string;
    elektrolytvolumen: string;
    elektrolytgesamtvolumen: number;
  } | null;
  separator: {
    id: string;
    separatorName: string;
    separatorId: string;
    separatorgroesse: number;
    separatoranzahl: number;
  } | null;
  feder: { id: string; federName: string; federId: string } | null;
  spacer: {
    id: string;
    spacerName: string;
    spacerId: string;
    spacergroesse: number;
    spacerdicke: number;
  } | null;
  gehaeuse: { id: string; gehaeuseName: string; gehaeuseId: string } | null;
  celltypeList: string[];
};
export interface NamesAndIDOfMaterials {
  anode: {
    name: string;
    state:boolean;
    id: string;
  }[];
  cathode: {
    name: string;
    state:boolean;
    id: string;
  }[];
  electrolyt: {
    name: string;
    state:boolean;
    id: string;
  }[];
  feather: {
    name: string;
    state:boolean;
    id: string;
  }[];
  separator: {
    name: string;
    state:boolean;
    id: string;
  }[];
  spacer: {
    name: string;
    state:boolean;
    id: string;
  }[];
  case: {
    name: string;
    state:boolean;
    id: string;
  }[];
};
//edit variation
interface BatteriebauInputModalPropsVariation {
  modalID:string;
  changeHandler: (item:__BatteryVariationConverted, idx:number) => __BatteryVariationConverted;
  materialNames: NamesAndIDOfMaterials;
  anode: { 
    id:string; 
    anodeName: string;
    anodeId: string; 
    anodengroesse: number;}
    |null;
  kathode: { id:string;
    kathodeName: string;
    kathodeId: string;
    kathodengroesse: number;
  } | null;
  elektrolyt: {
    id: string;
    elektrolytName: string;
    elektrolytId: string;
    elektrolytvolumen: string;
    elektrolytgesamtvolumen: number;
  } | null;
  separator: {
    id: string;
    separatorName: string;
    separatorId: string;
    separatorgroesse: number;
    separatoranzahl: number;
  } | null;
  feder: { id: string; federName: string; federId: string; } | null;
  spacer: {
    id: string;
    spacerName: string;
    spacerId: string;
    spacergroesse: number;
    spacerdicke: number;
  } | null;
  gehaeuse: { id: string; gehaeuseName: string; gehaeuseId: string } | null;
  variation: __BatteryVariationConverted;
  checkAnode: boolean;
  checkKathode: boolean;
  checkElektrolyt: boolean;
  checkFeder: boolean;
  checkGehaeuse: boolean;
  checkSeparator: boolean;
  checkSpacer: boolean;
  index: number;

};
//create variation
interface BatteriebauInputModalPropsVariationCreate {
  modalID:string;
  submitChangeHandler: (item:__BatteryVariationConverted, idx:number) => __BatteryVariationConverted;
  anode: { anode?: string; anodengroesse?: number,checked:boolean };
  kathode: { kathode?: string; kathodengroesse?: number,checked:boolean };
  elektrolyt: {
    elektrolyt?: string;
    elektrolytvolumen?: string;
    elektrolytgesamtvolumen?: number;
    checked:boolean;
  };
  separator: {
    separator?: string;
    separatorgroesse?: number;
    separatoranzahl?: number;
    checked:boolean;
  };
  feder: { feder?: string,checked?:boolean };
  spacer: { spacer?: string; spacergroesse?: number; spacerdicke?: number,checked:boolean };
  gehaeuse: { gehaeuse?: string,checked:boolean };
  materialNames: NamesAndIDOfMaterials;
  index: number;
};
interface BatteriebauModalInputProps{
  anodeCreate:string,
  anodengroesse: number,
  kathodeCreate: string,
  kathodengroesse: number,
  elektrolytCreate: string,
  elektrolytvolumen: string
  elektrolytgesamtvolumen: number,
  separatorCreate: string,
  separatorgroesse: number,
  separatoranzahl: number,
  federCreate: string,
  spacerCreate: string,
  spacergroesse: number,
  spacerdicke: number,
  gehaeuseCreate: string,
  checkAnode: boolean
  checkCathode: boolean,
  checkElectrolyt: boolean,
  checkSeparator: boolean,
  checkSpacer: boolean,
  checkFeather: boolean,
  checkCase: boolean,
};


export default function BatteriebauInputModalEdit({
  selectedCelltype,
  constructorName,
  state,
  date,
  materialNames,
  anode,
  kathode,
  elektrolyt,
  separator,
  feder,
  spacer,
  gehaeuse,
  celltypeList,
  modalID,
  changeHandler
}: BatteriebauInputModalPropsEdit) {
  const [inputs, setInputs] = useState({
    celltype: selectedCelltype,
    constructorName: constructorName,
    state: state,
    date: date,
    selectedConstructor: constructorName[0],
    anodeEdit: anode?.anodeName,
    anodengroesse: anode?.anodengroesse,
    kathode: kathode?.kathodeName,
    kathodengroesse: kathode?.kathodengroesse,
    elektrolyt: elektrolyt?.elektrolytName,
    elektrolytvolumen: elektrolyt?.elektrolytvolumen,
    elektrolytgesamtvolumen: elektrolyt?.elektrolytgesamtvolumen,
    separator: separator?.separatorName,
    separatorgroesse: separator?.separatorgroesse,
    separatoranzahl: separator?.separatoranzahl,
    feder: feder?.federName,
    spacerEdit: spacer?.spacerName,
    spacergroesse: spacer?.spacergroesse,
    spacerdicke: spacer?.spacerdicke,
    gehaeuse: gehaeuse?.gehaeuseName,
  });
  const [savingDisabled, setSavingDisabled] = useState(false);
  const [userListDict,dataLoaded] = useUserList();

  const handleSubmit = async (event: FormEvent) => {
    let anodeData: null | AnodeForBatteryData = null;
    if (inputs.anodeEdit && inputs.anodengroesse !== undefined) {
      let nameID = materialNames.anode.map(x => x.name).indexOf(inputs.anodeEdit);
      let materialID = materialNames.anode[nameID].id;
      anodeData = {
        id: '',
        anodeName: inputs.anodeEdit,
        anodeId: materialID,
        anodengroesse: Number(String(inputs.anodengroesse).replace(',','.')),
      };
    }
    let cathodedata: null | CathodeForBatteryData = null;
    if (inputs.kathode && inputs.kathodengroesse !== undefined) {
      let nameID = materialNames.cathode.map(x => x.name).indexOf(inputs.kathode);
      let materialID = materialNames.cathode[nameID].id;
      cathodedata = {
        id: '',
        kathodeName: inputs.kathode,
        kathodeId: materialID,
        kathodengroesse: Number(String(inputs.kathodengroesse).replace(',','.')),
      };
    }
    let electrolytData: null | ElectrolytForBatteryData = null;
    if (
      inputs.elektrolyt &&
      inputs.elektrolytvolumen !== undefined &&
      inputs.elektrolytgesamtvolumen !== undefined
    ) {
      let nameID = materialNames.electrolyt.map(x => x.name).indexOf(inputs.elektrolyt);
      let materialID = materialNames.electrolyt[nameID].id;
      electrolytData = {
        id: '',
        elektrolytName: inputs.elektrolyt,
        elektrolytId: materialID,
        elektrolytgesamtvolumen: Number(String(inputs.elektrolytgesamtvolumen).replace(',','.')),
        elektrolytvolumen: inputs.elektrolytvolumen,
      };
    }
    let separatorData: null | SeparatorForBatteryData = null;
    if (inputs.separator && inputs.separatoranzahl!== undefined && inputs.separatorgroesse!== undefined) {
      let nameID = materialNames.separator.map(x => x.name).indexOf(inputs.separator);
      let materialID = materialNames.separator[nameID].id;
      separatorData = {
        id: '',
        separatorName: inputs.separator,
        separatorId: materialID,
        separatorgroesse: Number(String(inputs.separatorgroesse).replace(',','.')),
        separatoranzahl: Number(inputs.separatoranzahl),
      };
    }
    let featherData: null | FeatherForBatteryData = null;
    if (inputs.feder) {
      let nameID = materialNames.feather.map(x => x.name).indexOf(inputs.feder);
      let materialID = materialNames.feather[nameID].id;
      featherData = {
        id: '',
        federName: inputs.feder,
        federId: materialID,
      };
    }
    let spacerData: null | SpacerForBatteryData = null;
    if (inputs.spacerEdit && inputs.spacerdicke!== undefined && inputs.spacergroesse!== undefined) {
      let nameID = materialNames.spacer.map(x => x.name).indexOf(inputs.spacerEdit);
      let materialID = materialNames.spacer[nameID].id;
      spacerData = {
        id: '',
        spacerName: inputs.spacerEdit,
        spacerId: materialID,
        spacergroesse: Number(String(inputs.spacergroesse).replace(',','.')),
        spacerdicke: Number(String(inputs.spacerdicke).replace(',','.')),
      };
    }
    let caseData: null | CaseForBatteryData = null;
    if (inputs.gehaeuse) {
      let nameID = materialNames.case.map(x => x.name).indexOf(inputs.gehaeuse);
      let materialID = materialNames.case[nameID].id;
      caseData = {
        id: '',
        gehaeuseName: inputs.gehaeuse,
        gehaeuseId: materialID,
      };
    }
    if(!constructorName.includes(inputs.selectedConstructor)){
      constructorName.push(inputs.selectedConstructor);
    }
    let requestData: BatteryDataForModal = {
      responsibleUser: constructorName,
      celltype: inputs.celltype,
      state: inputs.state,
      date: inputs.date,
      anode: anodeData ?? null,
      kathode: cathodedata ?? null,
      elektolyt: electrolytData ?? null,
      separator: separatorData ?? null,
      feder: featherData ?? null,
      spacer: spacerData ?? null,
      gehaeuse: caseData ?? null,
    };
    changeHandler(requestData);
    event.preventDefault();
  };
  const onDismiss = () => {
    setInputs({
      celltype: selectedCelltype,
      constructorName: constructorName,
      state: state,
      date: date,
      selectedConstructor: constructorName[0],
      anodeEdit: anode?.anodeName,
      anodengroesse: anode?.anodengroesse,
      kathode: kathode?.kathodeName,
      kathodengroesse: kathode?.kathodengroesse,
      elektrolyt: elektrolyt?.elektrolytName,
      elektrolytvolumen: elektrolyt?.elektrolytvolumen,
      elektrolytgesamtvolumen: elektrolyt?.elektrolytgesamtvolumen,
      separator: separator?.separatorName,
      separatorgroesse: separator?.separatorgroesse,
      separatoranzahl: separator?.separatoranzahl,
      feder: feder?.federName,
      spacerEdit: spacer?.spacerName,
      spacergroesse: spacer?.spacergroesse,
      spacerdicke: spacer?.spacerdicke,
      gehaeuse: gehaeuse?.gehaeuseName,
    });
  };
  
  //handle functions

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleDeleteFixedData = (kindOfFixedData: string ) => {
    setInputs((values) => ({ ...values, [kindOfFixedData]: undefined }));
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if(name === 'state' && value === 'abgeschlossen'){
      let newInputs = {...inputs};
      newInputs.date = new Date();
      newInputs.state = value;
      setInputs(newInputs);
    }
    else{
      setInputs((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  
  useEffect(()=>{
    //HIDDEN IS NOT WORKING IN DATALIST -> HANDLE REMOVE INVALID ENTRIES ADDITIONALLY
    //remove Anode from datalist with invalid state
    console.log('First, ', materialNames);
    const invalidAnodeNames = materialNames.anode.filter(x => x.state).map(x => x.name);
    invalidAnodeNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#anodeOptionsEdit option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Cathode from datalist with invalid state
    const invalidCathodeNames = materialNames.cathode.filter(x => x.state).map(x => x.name);
    invalidCathodeNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#cathodeOptionsEdit option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Elektrolyt from datalist with invalid state
    const invalidElectrolytNames = materialNames.electrolyt.filter(x => x.state).map(x => x.name);
    invalidElectrolytNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#electrolytOptionsEdit option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Separator from datalist with invalid state
    const invalidSeparatorNames = materialNames.separator.filter(x => x.state).map(x => x.name);
    invalidSeparatorNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#separatorOptionsEdit option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Feather from datalist with invalid state
    const invalidFeatherNames = materialNames.feather.filter(x => x.state).map(x => x.name);
    invalidFeatherNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#featherOptionsEdit option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Spacer from datalist with invalid state
    const invalidSpacerNames = materialNames.spacer.filter(x => x.state).map(x => x.name);
    invalidSpacerNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#spacerOptionsEdit option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Case from datalist with invalid state
    const invalidCaseNames = materialNames.case.filter(x => x.state).map(x => x.name);
    invalidCaseNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#caseOptionsEdit option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
  },[]);
  useEffect(()=>{
    //check inputs and set save to disabled if names not known
    let checkIfMaterialsListExsits = materialNames.anode.length > 0 && materialNames.cathode.length > 0 && materialNames.case.length > 0 && materialNames.separator.length > 0 && materialNames.separator.length > 0 && materialNames.feather.length && materialNames.electrolyt.length > 0;
    if(checkIfMaterialsListExsits && (((inputs.anodeEdit !== undefined && inputs.anodeEdit !== null) && !materialNames.anode.map(x => x.name).includes(inputs.anodeEdit))
      ||((inputs.kathode !== undefined && inputs.kathode !== null) && !materialNames.cathode.map(x => x.name).includes(inputs.kathode))
      ||((inputs.separator !== undefined && inputs.separator !== null) && !materialNames.separator.map(x => x.name).includes(inputs.separator))
      ||((inputs.spacerEdit !== undefined && inputs.spacerEdit !== null) && !materialNames.spacer.map(x => x.name).includes(inputs.spacerEdit))
      ||((inputs.elektrolyt !== undefined && inputs.elektrolyt !== null) && !materialNames.electrolyt.map(x => x.name).includes(inputs.elektrolyt))
      ||((inputs.feder !== undefined && inputs.feder !== null) && !materialNames.feather.map(x => x.name).includes(inputs.feder))
      ||((inputs.gehaeuse !== undefined && inputs.gehaeuse !== null) && !materialNames.case.map(x => x.name).includes(inputs.gehaeuse)))){
        setSavingDisabled(true);
    }
    else{
      setSavingDisabled(false);
    }
  },[inputs]);
  //clear input on dismiss
  const modalElement = document.getElementById(modalID) as HTMLElement;
  useEffect(()=>{
    if(modalElement){
      modalElement.addEventListener('hidden.bs.modal', () => onDismiss() );
    }
  });
  return (
    <div className='modal fade mt-4' id={modalID} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog px-4 modal-xl">
        <div className="modal-content modal-background-lightgrey batteriebau-input-modal">
          <div className="modal-header py-2">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className='modal-body px-4 py-0'>
            <div className='row'>
              <div className='col-2'>
                <h2 className='pt-4 ps-4'>Zelltyp:</h2>
              </div>
              <div className='col-4'>
                <label htmlFor='celltyp'>Zelltyp</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                  <InputField
                    value={inputs.celltype}
                    inputType='text'
                    name='celltype'
                    placeholder='symmetrische Zelle'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <select
                  name='celltype'
                  value={inputs.celltype}
                  onChange={handleOptionChange}
                  className='input-lg text-center text-md border-bottom-primary margin-right-5'
                >
                  {celltypeList.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-2'>
                <h2 className='pt-4 ps-4'>Status:</h2>
              </div>
              <div className='col-4'>
                <label htmlFor='state'>Status</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                  <InputField
                    value={inputs.state}
                    inputType='text'
                    name='state'
                    placeholder=''
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <select
                  name='state'
                  value={inputs.state}
                  onChange={(event) => handleOptionChange(event)}
                  className='input-lg text-md border-bottom-primary'
                >
                  {enums.WorkBlockState.map((option: string) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='col-2'>
                <h2 className='pt-4 ps-4'>Zellbauer:</h2>
              </div>
              <div className='col-4'>
                <label htmlFor='constructorName'>Verantwortlicher</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                  <InputField
                    value={inputs.selectedConstructor}
                    inputType='text'
                    name='selectedConstructor'
                    placeholder=''
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <select
                  name='selectedConstructor'
                  value={inputs.selectedConstructor}
                  onChange={(event) => handleOptionChange(event)}
                  className='input-lg text-md border-bottom-primary'
                >
                  {userListDict.map(option => (
                    <option key={option.displayName} value={option.displayName} hidden={!option.stateIsActve}>
                      {option.displayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row mt-1'>
              
            </div>
            {inputs.anodeEdit !== undefined ? (
              <div className='row'>
                <div className='col-2'>
                  <div className='row pt-4'>
                    <div className='col-2 ps-0'>
                      <button
                        className='btn-delete bg-transparent ps-0 ms-2'
                        type='button'
                        data-bs-toggle='tooltip' 
                        data-bs-placement="bottom" 
                        title='Anode löschen'
                        onClick={event => handleDeleteFixedData('anodeEdit')}
                      ><FontAwesomeIcon
                        icon={faXmark}
                        size='sm'
                        />
                      </button>
                    </div>
                    <div className='col-10'>
                      <h2 className='pt-2'>Anode</h2>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <label className="form-label mb-0">Anode</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="anodeOptionsEdit" 
                    placeholder={'Anode wählen ...'}
                    value={inputs.anodeEdit}
                    name='anodeEdit'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="anodeOptionsEdit">
                    {materialNames.anode.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='anodengroesse'>Anodengröße (mm)</label>
                  <InputField
                    value={inputs.anodengroesse}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='anodengroesse'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {inputs.kathode !== undefined ? (
              <div className='row'>
                <div className='col-2'>
                  <div className='row py-0'>
                    <div className='col-2 ps-0 pt-3'>
                      <button
                        className='btn-delete bg-transparent ps-0 ms-2'
                        type='button'
                        data-bs-toggle='tooltip' 
                        data-bs-placement="bottom" 
                        title='Kathode löschen'
                        onClick={event => handleDeleteFixedData('kathode')}
                      ><FontAwesomeIcon
                        icon={faXmark}
                        size='sm'
                        />
                      </button>
                    </div>
                    <div className='col-10'>
                      <h2 className='pt-4'>Kathode</h2>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <label htmlFor="kathode" className="form-label mb-0">Kathode</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="cathodeOptionsEdit" 
                    id="kathode" 
                    placeholder={'Kathode wählen ...'}
                    value={inputs.kathode}
                    name='kathode'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="cathodeOptionsEdit">
                    {materialNames.cathode.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='kathodengroesse'>Kathodengröße (mm)</label>
                  <InputField
                    value={inputs.kathodengroesse}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='kathodengroesse'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {inputs.elektrolyt !== undefined ? (
              <div className='row'>
                <div className='col-2'>
                  <div className='row py-0'>
                    <div className='col-2 ps-0 pt-3'>
                      <button
                        className='btn-delete bg-transparent ps-0 ms-2'
                        type='button'
                        data-bs-toggle='tooltip' 
                        data-bs-placement="bottom" 
                        title='Elektrolyt löschen'
                        onClick={event => handleDeleteFixedData('elektrolyt')}
                      ><FontAwesomeIcon
                        icon={faXmark}
                        size='sm'
                        />
                      </button>
                    </div>
                    <div className='col-10'>
                      <h2 className='pt-4'>Elektrolyt</h2>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <label htmlFor="elektrolyt" className="form-label mb-0">Elektrolyt</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="electrolytOptionsEdit" 
                    id="elektrolyt" 
                    placeholder={'Elektrolyt wählen ...'}
                    value={inputs.elektrolyt}
                    name='elektrolyt'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="electrolytOptionsEdit">
                    {materialNames.electrolyt.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='elektrolytvolumen'>Volumen</label>
                  <InputField
                    value={inputs.elektrolytvolumen}
                    inputType='text'
                    name='elektrolytvolumen'
                    placeholder='2x2,5ul'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary'
                  />
                </div>
                <div className='col-3'>
                  <label htmlFor='elektrolytgesamtvolumen'>
                    Gesamtvolumen (ul)
                  </label>
                  <InputField
                    value={inputs.elektrolytgesamtvolumen}
                    inputType="number" 
                    step="0.01"
                    name='elektrolytgesamtvolumen'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {inputs.separator !== undefined ? (
              <div className='row'>
                <div className='col-2'>
                  <div className='row py-0'>
                    <div className='col-2 ps-0 pt-2 mt-1'>
                      <button
                        className='btn-delete bg-transparent ps-0 ms-2'
                        type='button'
                        data-bs-toggle='tooltip' 
                        data-bs-placement="bottom" 
                        title='Separator löschen'
                        onClick={event => handleDeleteFixedData('separator')}
                      ><FontAwesomeIcon
                        icon={faXmark}
                        size='sm'
                        />
                      </button>
                    </div>
                    <div className='col-10'>
                      <h2 className='pt-3'>Separator</h2>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <label htmlFor="separator" className="form-label mb-0">Separator</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="separatorOptionsEdit" 
                    id="separator" 
                    placeholder={'Separator wählen ...'}
                    value={inputs.separator}
                    name='separator'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="separatorOptionsEdit">
                    {materialNames.separator.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='separatorgroesse'>Separatorgröße (mm)</label>
                  <InputField
                    value={inputs.separatorgroesse}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='separatorgroesse'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary'
                  />
                </div>
                <div className='col-3'>
                  <label htmlFor='separatoranzahl'>Separatoranzahl</label>
                  <p></p>
                    <input
                      type='number'
                      name='separatoranzahl'
                      placeholder={'0'}
                      value={inputs.separatoranzahl}
                      min = '0'
                      onChange={handleChange}
                      className='input-lg text-md border-bottom-primary'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {inputs.feder !== undefined ? (
              <div className='row'>
                <div className='col-2'>
                  <div className='row py-2'>
                    <div className='col-2 ps-0 pt-3'>
                      <button
                        className='btn-delete bg-transparent ps-0 ms-2'
                        type='button'
                        data-bs-toggle='tooltip' 
                        data-bs-placement="bottom" 
                        title='Feder löschen'
                        onClick={event => handleDeleteFixedData('feder')}
                      ><FontAwesomeIcon
                        icon={faXmark}
                        size='sm'
                        />
                      </button>
                    </div>
                    <div className='col-10'>
                      <h2 className='pt-4'>Feder</h2>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                <label htmlFor="feder" className="form-label mb-0">Feder</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="featherOptionsEdit" 
                    id="feder" 
                    placeholder={'Feder wählen ...'}
                    value={inputs.feder}
                    name='feder'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="featherOptionsEdit">
                    {materialNames.feather.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
            ) : (
              <></>
            )}
            {inputs.spacerEdit !== undefined ? (
              <div className='row'>
                <div className='col-2'>
                  <div className='row py-0'>
                    <div className='col-2 ps-0 pt-3'>
                      <button
                        className='btn-delete bg-transparent ps-0 ms-2'
                        type='button'
                        data-bs-toggle='tooltip' 
                        data-bs-placement="bottom" 
                        title='Spacer löschen'
                        onClick={event => handleDeleteFixedData('spacerEdit')}
                      ><FontAwesomeIcon
                        icon={faXmark}
                        size='sm'
                        />
                      </button>
                    </div>
                    <div className='col-10'>
                      <h2 className='pt-4'>Spacer</h2>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <label  className="form-label mb-0">Spacer</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="spacerOptionsEdit" 
                    placeholder={'Spacer wählen ...'}
                    value={inputs.spacerEdit}
                    name='spacerEdit'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="spacerOptionsEdit">
                    {materialNames.spacer.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='spacergroesse'>Spacergröße (mm)</label>
                  <InputField
                    value={inputs.spacergroesse}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='spacergroesse'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <div className='col-3'>
                  <label htmlFor='spacerdicke'>Spacerdicke (mm)</label>
                  <InputField
                    value={inputs.spacerdicke}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='spacerdicke'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {inputs.gehaeuse !== undefined ? (
              <div className='row'>
                <div className='col-2'>
                  <div className='row py-0'>
                    <div className='col-2 ps-0 pt-3'>
                      <button
                        className='btn-delete bg-transparent ps-0 ms-2'
                        type='button'
                        data-bs-toggle='tooltip' 
                        data-bs-placement="bottom" 
                        title='Gehäuse löschen'
                        onClick={event => handleDeleteFixedData('gehaeuse')}
                      ><FontAwesomeIcon
                        icon={faXmark}
                        size='sm'
                        />
                      </button>
                    </div>
                    <div className='col-10'>
                      <h2 className='pt-4'>Gehäuse</h2>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <label htmlFor="gehaeuse" className="form-label mb-0">Gehäuse</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="caseOptionsEdit" 
                    id="gehaeuse" 
                    placeholder={'Gehäuse wählen ...'}
                    value={inputs.gehaeuse}
                    name='gehaeuse'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="caseOptionsEdit">
                    {materialNames.case.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className='modal-footer pt-0'>
            {savingDisabled 
              ? <p className='text-err-color-font'>Einige Materialen wurden nicht erkannt oder sind nicht mehr verfügbar. Bitte wählen Sie valide Materialien aus!</p>
              : <></>
            }
            <button 
              type="button" 
              className="btn-main margin-top-5" 
              data-bs-dismiss="modal"> Abbrechen
            </button>
            <button
              disabled = {savingDisabled}
              className='btn-main margin-top-5'
              type='button'
              onClick={handleSubmit} 
              data-bs-dismiss="modal"
              > Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export function BatteriebauInputModalCreate({
  anode,
  kathode,
  elektrolyt,
  separator,
  feder,
  spacer,
  gehaeuse,
  modalID,
  materialNames,
  changeHandler

}: BatteriebauInputModalProps) {
  const [inputs, setInputs] = useState<BatteriebauModalInputProps>({
    anodeCreate:(anode.anode && anode.anode !== "") ? anode.anode : materialNames.anode.filter(x => !x.state)[0].name,
    anodengroesse: anode.anodengroesse,
    kathodeCreate: (kathode.kathode && kathode.kathode !== "") ? kathode.kathode : materialNames.cathode.filter(x => !x.state)[0].name,
    kathodengroesse: kathode.kathodengroesse,
    elektrolytCreate: (elektrolyt.elektrolyt && elektrolyt.elektrolyt !== "") ? elektrolyt.elektrolyt : materialNames.electrolyt.filter(x => !x.state)[0].name,
    elektrolytvolumen: elektrolyt.elektrolytvolumen,
    elektrolytgesamtvolumen: elektrolyt.elektrolytgesamtvolumen,
    separatorCreate: (separator.separator && separator.separator !== "") ? separator.separator : materialNames.separator.filter(x => !x.state)[0].name,
    separatorgroesse: separator.separatorgroesse,
    separatoranzahl: separator.separatoranzahl,
    federCreate: (feder.feder && feder.feder !== "") ? feder.feder : materialNames.feather.filter(x => !x.state)[0].name,
    spacerCreate: (spacer.spacer && spacer.spacer !== "") ? spacer.spacer : materialNames.spacer.filter(x => !x.state)[0].name,
    spacergroesse: spacer.spacergroesse,
    spacerdicke: spacer.spacerdicke,
    gehaeuseCreate: (gehaeuse.gehaeuse && gehaeuse.gehaeuse !== "") ? gehaeuse.gehaeuse : materialNames.case.filter(x => !x.state)[0].name,
    checkAnode: anode.checked,
    checkCathode: kathode.checked,
    checkElectrolyt: elektrolyt.checked,
    checkSeparator: separator.checked,
    checkSpacer: spacer.checked,
    checkFeather: feder.checked,
    checkCase: gehaeuse.checked,
  });
  const [savingDisabled, setSavingDisabled] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    let anodeData: null | AnodeForBatteryData = null;
    if (anode.checked) {
      let nameID = materialNames.anode.map(x => x.name).indexOf(inputs.anodeCreate);
      let materialID = materialNames.anode[nameID].id;
      anodeData = {
        id: '',
        anodeName: inputs.anodeCreate !== '' ? inputs.anodeCreate : materialNames.anode.filter(x => !x.state)[0].name,
        anodeId: materialID ?? materialNames.anode.filter(x => !x.state)[0].id,
        anodengroesse: Number(String(inputs.anodengroesse).replace(',','.')) ?? 0,
      };
    }
    let cathodedata: null | CathodeForBatteryData = null;
    if (kathode.checked) {
      let nameID = materialNames.cathode.map(x => x.name).indexOf(inputs.kathodeCreate);
      let materialID = materialNames.cathode[nameID].id;
      cathodedata = {
        id: '',
        kathodeName: inputs.kathodeCreate  !== '' ? inputs.kathodeCreate :  materialNames.cathode.filter(x => !x.state)[0].name,
        kathodeId: materialID ?? materialNames.cathode.filter(x => !x.state)[0].id,
        kathodengroesse: Number(String(inputs.kathodengroesse).replace(',','.')) ?? 0,
      };
    }
    let electrolytData: null | ElectrolytForBatteryData = null;
    if (elektrolyt.checked ) {
      let nameID = materialNames.electrolyt.map(x => x.name).indexOf(inputs.elektrolytCreate);
      let materialID = materialNames.electrolyt[nameID].id;
      electrolytData = {
        id: '',
        elektrolytName: inputs.elektrolytCreate !== '' ? inputs.elektrolytCreate :  materialNames.electrolyt.filter(x => !x.state)[0].name,
        elektrolytId: materialID ?? materialNames.electrolyt.filter(x => !x.state)[0].id,
        elektrolytgesamtvolumen: Number(String(inputs.elektrolytgesamtvolumen).replace(',','.')) ?? 0,
        elektrolytvolumen: inputs.elektrolytvolumen ?? '',
      };
    }
    let separatorData: null | SeparatorForBatteryData = null;
    if (separator.checked) {
      let nameID = materialNames.separator.map(x => x.name).indexOf(inputs.separatorCreate);
      let materialID = materialNames.separator[nameID].id;
      separatorData = {
        id: '',
        separatorName: inputs.separatorCreate  !== '' ? inputs.separatorCreate :  materialNames.separator.filter(x => !x.state)[0].name,
        separatorId: materialID ?? materialNames.separator.filter(x => !x.state)[0].id,
        separatorgroesse: Number(String(inputs.separatorgroesse).replace(',','.'))?? 0,
        separatoranzahl: Number(inputs.separatoranzahl) ?? 0,
      };
    }
    let featherData: null | FeatherForBatteryData = null;
    if (feder.checked) {
      let nameID = materialNames.feather.map(x => x.name).indexOf(inputs.federCreate);
      let materialID = materialNames.feather[nameID].id;
      featherData = {
        id: '',
        federName: inputs.federCreate  !== '' ? inputs.federCreate :  materialNames.feather.filter(x => !x.state)[0].name,
        federId: materialID ?? materialNames.feather.filter(x => !x.state)[0].id,
      };
    }
    let spacerData: null | SpacerForBatteryData = null;
    if (spacer.checked) {
      let nameID = materialNames.spacer.map(x => x.name).indexOf(inputs.spacerCreate);
      let materialID = materialNames.spacer[nameID].id;
      spacerData = {
        id: '',
        spacerName: inputs.spacerCreate  !== '' ? inputs.spacerCreate : materialNames.spacer.filter(x => !x.state)[0].name,
        spacerId: materialID ?? materialNames.spacer.filter(x => !x.state)[0].id,
        spacergroesse: Number(String(inputs.spacergroesse).replace(',','.')) ?? 0,
        spacerdicke: Number(String(inputs.spacerdicke).replace(',','.')) ?? 0,
      };
    }
    let caseData: null | CaseForBatteryData = null;
    if (gehaeuse.checked) {
      let nameID = materialNames.case.map(x => x.name).indexOf(inputs.gehaeuseCreate);
      let materialID = materialNames.case[nameID].id;
      caseData = {
        id: '',
        gehaeuseName: inputs.gehaeuseCreate  !== '' ? inputs.gehaeuseCreate :  materialNames.case.filter(x => !x.state)[0].name,
        gehaeuseId: materialID ?? materialNames.case.filter(x => !x.state)[0].id,
      };
    }
    let requestData: BatteryDataForModal = {
      responsibleUser: [],
      celltype: enums.CellType[0],
      state: enums.WorkBlockState[0],
      anode: anodeData,
      date: new Date(),
      kathode: cathodedata,
      elektolyt: electrolytData,
      separator: separatorData,
      feder: featherData,
      spacer: spacerData,
      gehaeuse: caseData,
    };
    event.preventDefault();
    changeHandler(requestData);
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const onDismiss = () => {
    setInputs({
      anodeCreate:(anode.anode && anode.anode !== "") ? anode.anode : materialNames.anode.filter(x => !x.state)[0].name,
      anodengroesse: anode.anodengroesse,
      kathodeCreate: (kathode.kathode && kathode.kathode !== "") ? kathode.kathode : materialNames.cathode.filter(x => !x.state)[0].name,
      kathodengroesse: kathode.kathodengroesse,
      elektrolytCreate: (elektrolyt.elektrolyt && elektrolyt.elektrolyt !== "") ? elektrolyt.elektrolyt : materialNames.electrolyt.filter(x => !x.state)[0].name,
      elektrolytvolumen: elektrolyt.elektrolytvolumen,
      elektrolytgesamtvolumen: elektrolyt.elektrolytgesamtvolumen,
      separatorCreate: (separator.separator && separator.separator !== "") ? separator.separator : materialNames.separator.filter(x => !x.state)[0].name,
      separatorgroesse: separator.separatorgroesse,
      separatoranzahl: separator.separatoranzahl,
      federCreate: (feder.feder && feder.feder !== "") ? feder.feder : materialNames.feather.filter(x => !x.state)[0].name,
      spacerCreate: (spacer.spacer && spacer.spacer !== "") ? spacer.spacer : materialNames.spacer.filter(x => !x.state)[0].name,
      spacergroesse: spacer.spacergroesse,
      spacerdicke: spacer.spacerdicke,
      gehaeuseCreate: (gehaeuse.gehaeuse && gehaeuse.gehaeuse !== "") ? gehaeuse.gehaeuse : materialNames.case.filter(x => !x.state)[0].name,
      checkAnode: anode.checked,
      checkCathode: kathode.checked,
      checkElectrolyt: elektrolyt.checked,
      checkSeparator: separator.checked,
      checkSpacer: spacer.checked,
      checkFeather: feder.checked,
      checkCase: gehaeuse.checked,
    });
  };

  useEffect(()=>{
    //HIDDEN IS NOT WORKING IN DATALIST -> HANDLE REMOVE INVALID ENTRIES ADDITIONALLY
    //remove Anode from datalist with invalid state
    const invalidAnodeNames = materialNames.anode.filter(x => x.state).map(x => x.name);
    invalidAnodeNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#anodeOptionsCreate option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Cathode from datalist with invalid state
    const invalidCathodeNames = materialNames.cathode.filter(x => x.state).map(x => x.name);
    invalidCathodeNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#cathodeOptionsCreate option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Elektrolyt from datalist with invalid state
    const invalidElectrolytNames = materialNames.electrolyt.filter(x => x.state).map(x => x.name);
    invalidElectrolytNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#electrolytOptionsCreate option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Separator from datalist with invalid state
    const invalidSeparatorNames = materialNames.separator.filter(x => x.state).map(x => x.name);
    invalidSeparatorNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#separatorOptionsCreate option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Feather from datalist with invalid state
    const invalidFeatherNames = materialNames.feather.filter(x => x.state).map(x => x.name);
    invalidFeatherNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#featherOptionsCreate option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Spacer from datalist with invalid state
    const invalidSpacerNames = materialNames.spacer.filter(x => x.state).map(x => x.name);
    invalidSpacerNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#spacerOptionsCreate option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Case from datalist with invalid state
    const invalidCaseNames = materialNames.case.filter(x => x.state).map(x => x.name);
    invalidCaseNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#caseOptionsCreate option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
  },[]);
  useEffect(()=>{
    //check inputs and set save to disabled if names not known
    if((inputs.anodeCreate !== undefined && !materialNames.anode.map(x => x.name).includes(inputs.anodeCreate))
      ||(inputs.kathodeCreate !== undefined && !materialNames.cathode.map(x => x.name).includes(inputs.kathodeCreate))
      ||(inputs.separatorCreate !== undefined && !materialNames.separator.map(x => x.name).includes(inputs.separatorCreate))
      ||(inputs.spacerCreate !== undefined && !materialNames.spacer.map(x => x.name).includes(inputs.spacerCreate))
      ||(inputs.elektrolytCreate !== undefined && !materialNames.electrolyt.map(x => x.name).includes(inputs.elektrolytCreate))
      ||(inputs.federCreate !== undefined && !materialNames.feather.map(x => x.name).includes(inputs.federCreate))
      ||(inputs.gehaeuseCreate !== undefined && !materialNames.case.map(x => x.name).includes(inputs.gehaeuseCreate))){
        setSavingDisabled(true);
    }
    else{
      setSavingDisabled(false);
    }

  },[inputs]);
  const modalElement = document.getElementById(modalID) as HTMLElement;
  useEffect(()=>{
    if(modalElement){
      modalElement.addEventListener('hidden.bs.modal', () => onDismiss() );
    }
  });
    
  return (
    <>
    <div className='modal fade mt-4' id={modalID} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-middle modal-xl">
        <div className="modal-content modal-background-lightgrey batteriebau-input-modal">
          <div className="modal-header pb-2">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className='modal-body pb-1'>
            {inputs.checkAnode ? (
                <div className='row'>
                  <div className='col-2'>
                    <h2 className='pt-4'>Anode</h2>
                  </div>
                  <div className='col-4'>
                    <label className="form-label mb-0">Anode</label>
                    <input 
                      className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                      list="anodeOptionsCreate"
                      placeholder={'Anode wählen ...'}
                      value={inputs.anodeCreate}
                      name='anodeCreate'
                      autoCorrect="off"
                      autoComplete='new-password'
                      onChange={(event) => handleOptionChange(event)}
                    />
                    <datalist id="anodeOptionsCreate">
                      {materialNames.anode.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                        <option key={option.name} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </datalist>
                  </div>
                  <div className='col-3'>
                    <label htmlFor='anodengroesse'>Anodengröße (mm)</label>
                    <InputField
                      value={inputs.anodengroesse}
                      inputType="number" 
                      step="0.01"
                      min='0'
                      name='anodengroesse'
                      placeholder='5'
                      changeHandler={handleChange}
                      inputClass='text-md border-bottom-primary margin-bottom-10'
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            {inputs.checkCathode ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Kathode</h2>
                </div>
                <div className='col-4'>
                  <label htmlFor="kathode" className="form-label mb-0">Kathode</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="cathodeOptionsCreate" 
                    id="kathode" 
                    placeholder={'Kathode wählen ...'}
                    value={inputs.kathodeCreate}
                    name='kathodeCreate'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="cathodeOptionsCreate">
                    {materialNames.cathode.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='kathodengroesse'>Kathodengröße (mm)</label>
                  <InputField
                    value={inputs.kathodengroesse}
                    inputType='text'
                    min='0'
                    name='kathodengroesse'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {inputs.checkElectrolyt ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Elektrolyt</h2>
                </div>
                <div className='col-4'>
                  <label htmlFor="elektrolyt" className="form-label mb-0">Elektrolyt</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="electrolytOptionsCreate" 
                    id="elektrolyt" 
                    placeholder={'Elektrolyt wählen ...'}
                    value={inputs.elektrolytCreate}
                    name='elektrolytCreate'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="electrolytOptionsCreate">
                    {materialNames.electrolyt.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='elektrolytvolumen'>Volumen</label>
                  <InputField
                    value={inputs.elektrolytvolumen}
                    inputType='text'
                    name='elektrolytvolumen'
                    placeholder='2x2,5ul'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <div className='col-3'>
                  <label htmlFor='elektrolytgesamtvolumen'>
                    Gesamtvolumen (ul)
                  </label>
                  <InputField
                    value={inputs.elektrolytgesamtvolumen}
                    inputType="number" 
                    step="0.01"
                    name='elektrolytgesamtvolumen'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {inputs.checkSeparator ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Separator</h2>
                </div>
                <div className='col-4'>
                  <label htmlFor="separator" className="form-label mb-0">Separator</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="separatorOptionsCreate" 
                    id="separator" 
                    placeholder={'Separator wählen ...'}
                    value={inputs.separatorCreate}
                    name='separatorCreate'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="separatorOptionsCreate">
                    {materialNames.separator.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='separatorgroesse'>Separatorgröße (mm)</label>
                  <InputField
                    value={inputs.separatorgroesse}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='separatorgroesse'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <div className='col-3'>
                  <label htmlFor='separatoranzahl'>Separatoranzahl</label>
                  <p></p>
                    <input
                      type='number'
                      name='separatoranzahl'
                      placeholder={'0'}
                      value={inputs.separatoranzahl}
                      onChange={handleChange}
                      min = '0'
                      className='input-lg text-md border-bottom-primary'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {inputs.checkFeather ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Feder</h2>
                </div>
                <div className='col-4'>
                  <label htmlFor="feder" className="form-label mb-0">Feder</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="featherOptionsCreate" 
                    id="feder" 
                    placeholder={'Feder wählen ...'}
                    value={inputs.federCreate}
                    name='federCreate'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="featherOptionsCreate">
                    {materialNames.feather.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
            ) : (
              <></>
            )}
            {inputs.checkSpacer ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Spacer</h2>
                </div>
                <div className='col-4'>
                  <label className="form-label mb-0">Spacer</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="spacerOptionsCreate" 
                    placeholder={'Spacer wählen ...'}
                    value={inputs.spacerCreate}
                    name='spacerCreate'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="spacerOptionsCreate">
                    {materialNames.spacer.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={idx} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='spacergroesse'>Spacergröße (mm)</label>
                  <InputField
                    value={inputs.spacergroesse}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='spacergroesse'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <div className='col-3'>
                  <label htmlFor='spacerdicke'>Spacerdicke (mm)</label>
                  <InputField
                    value={inputs.spacerdicke}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='spacerdicke'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {inputs.checkCase ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Gehäuse</h2>
                </div>
                <div className='col-4'>
                  <label htmlFor="gehaeuse" className="form-label mb-0">Gehäuse</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="caseOptionsCreate" 
                    placeholder={'Gehäuse wählen ...'}
                    value={inputs.gehaeuseCreate}
                    name='gehaeuseCreate'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="caseOptionsCreate">
                    {materialNames.case.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
            
          <div className='modal-footer pt-1'>
            {savingDisabled 
              ? <p className='text-err-color-font'>Einige Materialen wurden nicht erkannt oder sind nicht mehr verfügbar. Bitte wählen Sie valide Materialien aus!</p>
              : <></>
            }
            <button 
              type="button" 
              className="btn-main margin-top-5" 
              data-bs-dismiss="modal"> Abbrechen
            </button>
            <button
              disabled={savingDisabled}
              className='btn-main margin-top-5'
              type='button'
              onClick={handleSubmit} 
              data-bs-dismiss="modal"
              > Speichern
            </button>
          </div>
        </div>
      </div>
      
    </div>
  </>

  );
};
export function BatteriebauInputModalForVariation({
  materialNames,
  anode,
  kathode,
  elektrolyt,
  separator,
  feder,
  spacer,
  gehaeuse,
  checkAnode,
  checkElektrolyt,
  checkFeder,
  checkGehaeuse,
  checkKathode,
  checkSeparator,
  checkSpacer,
  variation,
  index,
  modalID,
  changeHandler
}: BatteriebauInputModalPropsVariation) {
  const [inputs, setInputs] = useState({
    anodeVar: variation.anode?.materialName,
    anodengroesse: variation.anode?.diameter,
    kathodeVar: variation.cathode?.materialName,
    kathodengroesse: variation.cathode?.diameter,
    elektrolytVar: variation.electrolyt?.materialName,
    elektrolytvolumen: variation.electrolyt?.volumeText,
    elektrolytgesamtvolumen: variation.electrolyt?.volume,
    separatorVar: variation.separator?.materialName,
    separatorgroesse: variation.separator?.diameter,
    separatoranzahl: variation.separator?.numberOfSeperators,
    federVar: variation.feather?.materialName,
    spacerVar: variation.spacer?.materialName,
    spacergroesse: variation.spacer?.diameter,
    spacerdicke: variation.spacer?.thickness,
    gehaeuseVar: variation.caseData?.materialName,
  });
  const [savingDisabled, setSavingDisabled] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    let anodeData: undefined | __DataAnodeConverted = undefined;
    if (checkAnode) {
      let nameID = inputs.anodeVar ? materialNames.anode.map(x => x.name).indexOf(inputs.anodeVar) : null;
      let materialID = nameID ? materialNames.anode[nameID].id : null;
      anodeData = {
        id: variation?.anode?.id,
        materialName: inputs.anodeVar ?? materialNames.anode.filter(x => !x.state)[0].name,
        material: materialID ?? materialNames.anode.filter(x => !x.state)[0].id,
        diameter: inputs.anodengroesse ? Number(String(inputs.anodengroesse).replace(',','.')) : 0
      };
    }
    let cathodedata: undefined | __DataCathodeConverted = undefined;
    if (checkKathode) {
      let nameID = inputs.kathodeVar? materialNames.cathode.map(x => x.name).indexOf(inputs.kathodeVar) : null;
      let materialID = nameID ? materialNames.cathode[nameID].id : null;
      cathodedata = {
        id: variation?.cathode?.id,
        materialName: inputs.kathodeVar?? materialNames.cathode.filter(x => !x.state)[0].name,
        material: materialID?? materialNames.cathode.filter(x => !x.state)[0].id,
        diameter: inputs.kathodengroesse ? Number(String(inputs.kathodengroesse).replace(',','.')) : 0
      };
    }
    let electrolytData: undefined | __DataElectrolytConverted = undefined;
    if (checkElektrolyt) {
      let nameID = inputs.elektrolytVar? materialNames.electrolyt.map(x => x.name).indexOf(inputs.elektrolytVar) : null;
      let materialID = nameID ? materialNames.electrolyt[nameID].id : null;
      electrolytData = {
        id: variation?.electrolyt?.id,
        materialName: inputs.elektrolytVar ?? materialNames.electrolyt.filter(x => !x.state)[0].name,
        material: materialID ?? materialNames.electrolyt.filter(x => !x.state)[0].id,
        volume: inputs.elektrolytgesamtvolumen? Number(String(inputs.elektrolytgesamtvolumen).replace(',','.')) : 0,
        volumeText: inputs.elektrolytvolumen ?? '', 
        volumeUnit: variation?.electrolyt?.volumeUnit ??'',
      };
    }
    let separatorData: undefined | __DataSeperatorConverted = undefined;
    if (checkSeparator) {
      let nameID = inputs.separatorVar? materialNames.separator.map(x => x.name).indexOf(inputs.separatorVar) : null;
      let materialID = nameID ? materialNames.separator[nameID].id : null;
      separatorData = {
        id: variation?.separator?.id,
        materialName: inputs.separatorVar ?? materialNames.separator.filter(x => !x.state)[0].name,
        material: materialID ?? materialNames.separator.filter(x => !x.state)[0].id,
        diameter: inputs.separatorgroesse ? Number(String(inputs.separatorgroesse).replace(',','.')) : 0,
        numberOfSeperators: inputs.separatoranzahl ? Number(inputs.separatoranzahl) : 0
      };
    }
    let featherData: undefined | __DataFeatherConverted = undefined;
    if (checkFeder) {
      let nameID = inputs.federVar ? materialNames.feather.map(x => x.name).indexOf(inputs.federVar) : null;
      let materialID = nameID ? materialNames.feather[nameID].id : null;
      featherData = {
        id: variation?.feather?.id,
        materialName: inputs.federVar?? materialNames.feather.filter(x => !x.state)[0].name,
        material: materialID ?? materialNames.feather.filter(x => !x.state)[0].id,
      };
    }
    let spacerData: undefined | __DataSpacerConverted = undefined;
    if (checkSpacer) {
      let nameID = inputs.spacerVar ? materialNames.spacer.map(x => x.name).indexOf(inputs.spacerVar) : null;
      let materialID = nameID ? materialNames.spacer[nameID].id : null;
      spacerData = {
        id: variation?.spacer?.id,
        materialName: inputs.spacerVar ?? materialNames.spacer.filter(x => !x.state)[0].name,
        material: materialID?? materialNames.spacer.filter(x => !x.state)[0].id,
        diameter: inputs.spacergroesse ? Number(String(inputs.spacergroesse).replace(',','.')) : 0,
        thickness: inputs.spacerdicke ? Number(String(inputs.spacerdicke).replace(',','.')) : 0
      };
    }
    let caseData: undefined | __DataCaseConverted = undefined;
    if (checkGehaeuse) {
      let nameID = inputs.gehaeuseVar? materialNames.case.map(x => x.name).indexOf(inputs.gehaeuseVar) : null;
      let materialID = nameID ? materialNames.case[nameID].id : null;
      caseData = {
        id: variation?.caseData?.id,
        materialName: inputs.gehaeuseVar ?? materialNames.case.filter(x => !x.state)[0].name,
        material: materialID?? materialNames.case.filter(x => !x.state)[0].id
      };
    }
    let requestData: __BatteryVariationConverted = {
      id: variation?.id,
      anode: anodeData,
      cathode: cathodedata,
      electrolyt: electrolytData,
      separator: separatorData,
      feather: featherData,
      spacer: spacerData,
      caseData: caseData,
    };
    changeHandler(requestData, index);
    event.preventDefault();
  };
  const onDismiss = () => {
    setInputs({
      anodeVar: variation.anode?.materialName,
      anodengroesse: variation.anode?.diameter,
      kathodeVar: variation.cathode?.materialName,
      kathodengroesse: variation.cathode?.diameter,
      elektrolytVar: variation.electrolyt?.materialName,
      elektrolytvolumen: variation.electrolyt?.volumeText,
      elektrolytgesamtvolumen: variation.electrolyt?.volume,
      separatorVar: variation.separator?.materialName,
      separatorgroesse: variation.separator?.diameter,
      separatoranzahl: variation.separator?.numberOfSeperators,
      federVar: variation.feather?.materialName,
      spacerVar: variation.spacer?.materialName,
      spacergroesse: variation.spacer?.diameter,
      spacerdicke: variation.spacer?.thickness,
      gehaeuseVar: variation.caseData?.materialName,
    });
  };

  //Handle functions
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(()=>{
    //HIDDEN IS NOT WORKING IN DATALIST -> HANDLE REMOVE INVALID ENTRIES ADDITIONALLY
    //remove Anode from datalist with invalid state
    const invalidAnodeNames = materialNames.anode.filter(x => x.state).map(x => x.name);
    invalidAnodeNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#anodeOptionsEditVar option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Cathode from datalist with invalid state
    const invalidCathodeNames = materialNames.cathode.filter(x => x.state).map(x => x.name);
    invalidCathodeNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#cathodeOptionsEditVar option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Elektrolyt from datalist with invalid state
    const invalidElectrolytNames = materialNames.electrolyt.filter(x => x.state).map(x => x.name);
    invalidElectrolytNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#electrolytOptionsEditVar option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Separator from datalist with invalid state
    const invalidSeparatorNames = materialNames.separator.filter(x => x.state).map(x => x.name);
    invalidSeparatorNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#separatorOptionsEditVar option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Feather from datalist with invalid state
    const invalidFeatherNames = materialNames.feather.filter(x => x.state).map(x => x.name);
    invalidFeatherNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#featherOptionsEditVar option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Spacer from datalist with invalid state
    const invalidSpacerNames = materialNames.spacer.filter(x => x.state).map(x => x.name);
    invalidSpacerNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#spacerOptionsEditVar option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
    //remove Case from datalist with invalid state
    const invalidCaseNames = materialNames.case.filter(x => x.state).map(x => x.name);
    invalidCaseNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#caseOptionsEditVar option[value='${value}']`);
      if(optionToHide){

        optionToHide.remove();
      }
    });
  },[]);
  useEffect(()=>{
    //check inputs and set save to disabled if names not known
    if((inputs.anodeVar !== undefined && !materialNames.anode.map(x => x.name).includes(inputs.anodeVar))
      ||(inputs.kathodeVar !== undefined && !materialNames.cathode.map(x => x.name).includes(inputs.kathodeVar))
      ||(inputs.separatorVar !== undefined && !materialNames.separator.map(x => x.name).includes(inputs.separatorVar))
      ||(inputs.spacerVar !== undefined && !materialNames.spacer.map(x => x.name).includes(inputs.spacerVar))
      ||(inputs.elektrolytVar !== undefined && !materialNames.electrolyt.map(x => x.name).includes(inputs.elektrolytVar))
      ||(inputs.federVar !== undefined && !materialNames.feather.map(x => x.name).includes(inputs.federVar))
      ||(inputs.gehaeuseVar !== undefined && !materialNames.case.map(x => x.name).includes(inputs.gehaeuseVar))){
        setSavingDisabled(true);
    }
    else{
      setSavingDisabled(false);
    }

  },[inputs]);
  const modalElement = document.getElementById(modalID) as HTMLElement;
  useEffect(()=>{
    if(modalElement){
      modalElement.addEventListener('hidden.bs.modal', () => onDismiss() );
    }
  });
  return (
    <div className='modal fade mt-4' id={modalID} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-middle modal-xl">
        <div className="modal-content modal-background-lightgrey batteriebau-input-modal">
          <div className="modal-header py-2">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className='modal-body'>
          {!anode ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Anode</h2>
                </div>
                <div className='col-4'>
                  <label htmlFor="anodeVar" className="form-label mb-0">Anode</label>
                    <input 
                      className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                      list="anodeOptionsEditVar"
                      placeholder={'Anode wählen ...'}
                      value={inputs.anodeVar}
                      name='anodeVar'
                      autoCorrect="off"
                      autoComplete='new-password'
                      onChange={(event) => handleOptionChange(event)}
                    />
                    <datalist id="anodeOptionsEditVar">
                      {materialNames.anode.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                        <option key={option.name} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='anodengroesse'>Anodengröße (mm)</label>
                  <InputField
                    value={inputs.anodengroesse}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='anodengroesse'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {!kathode ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Kathode</h2>
                </div>
                <div className='col-4'>
                  <label htmlFor="kathode" className="form-label mb-0">Kathode</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="cathodeOptionsEditVar" 
                    placeholder={'Kathode wählen ...'}
                    value={inputs.kathodeVar}
                    name='kathodeVar'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="cathodeOptionsEditVar">
                    {materialNames.cathode.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='kathodengroesse'>Kathodengröße (mm)</label>
                  <InputField
                    value={inputs.kathodengroesse}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='kathodengroesse'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {!elektrolyt ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Elektrolyt</h2>
                </div>
                <div className='col-4'>
                  <label htmlFor="elektrolyt" className="form-label mb-0">Elektrolyt</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="electrolytOptionsEditVar" 
                    placeholder={'Elektrolyt wählen ...'}
                    value={inputs.elektrolytVar}
                    name='elektrolytVar'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="electrolytOptionsEditVar">
                    {materialNames.electrolyt.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='elektrolytvolumen'>Volumen</label>
                  <InputField
                    value={inputs.elektrolytvolumen}
                    inputType='text'
                    name='elektrolytvolumen'
                    placeholder='2x2,5ul'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <div className='col-3'>
                  <label htmlFor='elektrolytgesamtvolumen'>
                    Gesamtvolumen (ul)
                  </label>
                  <InputField
                    value={inputs.elektrolytgesamtvolumen}
                    inputType="number" 
                    step="0.01"
                    name='elektrolytgesamtvolumen'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {!separator ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Separator</h2>
                </div>
                <div className='col-4'>
                  <label htmlFor="separator" className="form-label mb-0">Separator</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="separatorOptionsEditVar" 
                    placeholder={'Separator wählen ...'}
                    value={inputs.separatorVar}
                    name='separatorVar'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="separatorOptionsEditVar">
                    {materialNames.separator.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='separatorgroesse'>Separatorgröße (mm)</label>
                  <InputField
                    value={inputs.separatorgroesse}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='separatorgroesse'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <div className='col-3'>
                  <label htmlFor='separatoranzahl'>Separatoranzahl</label>
                  <p></p>
                    <input
                      type='number'
                      name='separatoranzahl'
                      placeholder={'0'}
                      value={inputs.separatoranzahl}
                      onChange={handleChange}
                      min = '0'
                      className='input-lg text-md border-bottom-primary'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {!feder ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Feder</h2>
                </div>
                <div className='col-4'>
                  <label htmlFor="feder" className="form-label mb-0">Feder</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="featherOptionsEditVar" 
                    placeholder={'Feder wählen ...'}
                    value={inputs.federVar}
                    name='federVar'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="featherOptionsEditVar">
                    {materialNames.feather.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
            ) : (
              <></>
            )}
            {!spacer ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Spacer</h2>
                </div>
                <div className='col-4'>
                  <label className="form-label mb-0">Spacer</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="spacerOptionsEditVar" 
                    placeholder={'Spacer wählen ...'}
                    value={inputs.spacerVar}
                    name='spacerVar'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="spacerOptionsEditVar">
                    {materialNames.spacer.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className='col-3'>
                  <label htmlFor='spacergroesse'>Spacergröße (mm)</label>
                  <InputField
                    value={inputs.spacergroesse}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='spacergroesse'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <div className='col-3'>
                  <label htmlFor='spacerdicke'>Spacerdicke (mm)</label>
                  <InputField
                    value={inputs.spacerdicke}
                    inputType="number" 
                    step="0.01"
                    min='0'
                    name='spacerdicke'
                    placeholder='5'
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {!gehaeuse ? (
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Gehäuse</h2>
                </div>
                <div className='col-4'>
                  <label htmlFor="gehaeuse" className="form-label mb-0">Gehäuse</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="caseOptionsEditVar" 
                    placeholder={'Gehäuse wählen ...'}
                    value={inputs.gehaeuseVar}
                    name='gehaeuseVar'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="caseOptionsEditVar">
                    {materialNames.case.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className='modal-footer pt-0'>
            {savingDisabled 
              ? <p className='text-err-color-font'>Einige Materialen wurden nicht erkannt oder sind nicht mehr verfügbar. Bitte wählen Sie valide Materialien aus!</p>
              : <></>
            }
            <button 
              type="button" 
              className="btn-main margin-top-5" 
              data-bs-dismiss="modal"> Abbrechen
            </button>
            <button
              disabled={savingDisabled}
              className='btn-main margin-top-5'
              type='button'
              onClick={handleSubmit} 
              data-bs-dismiss="modal"
              > Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export function BatteriebauInputModalCreateVariaton({
  anode,
  kathode,
  elektrolyt,
  separator,
  feder,
  spacer,
  gehaeuse,
  index,
  modalID,
  materialNames,
  submitChangeHandler

}: BatteriebauInputModalPropsVariationCreate) {
  const [inputs, setInputs] = useState({
    anodeVar:(anode.anode && anode.anode !== "") ? anode.anode :  materialNames.anode.filter(x => !x.state)[0].name,
    anodengroesse: anode.anodengroesse,
    kathodeVar: (kathode.kathode && kathode.kathode !== "") ? kathode.kathode :   materialNames.cathode.filter(x => !x.state)[0].name,
    kathodengroesse: kathode.kathodengroesse,
    elektrolytVar: (elektrolyt.elektrolyt && elektrolyt.elektrolyt !== "") ? elektrolyt.elektrolyt :  materialNames.electrolyt.filter(x => !x.state)[0].name,
    elektrolytvolumen: elektrolyt.elektrolytvolumen,
    elektrolytgesamtvolumen: elektrolyt.elektrolytgesamtvolumen,
    separatorVar: (separator.separator && separator.separator !== "") ? separator.separator : materialNames.separator.filter(x => !x.state)[0].name,
    separatorgroesse: separator.separatorgroesse,
    separatoranzahl: separator.separatoranzahl,
    federVar: (feder.feder && feder.feder !== "") ? feder.feder : materialNames.feather.filter(x => !x.state)[0].name,
    spacerVarCreate: (spacer.spacer && spacer.spacer !== "") ? spacer.spacer : materialNames.spacer.filter(x => !x.state)[0].name,
    spacergroesse: spacer.spacergroesse,
    spacerdicke: spacer.spacerdicke,
    gehaeuseVar: (gehaeuse.gehaeuse && gehaeuse.gehaeuse !== "") ? gehaeuse.gehaeuse : materialNames.case.filter(x => !x.state)[0].name,
    checkAnode: anode.checked,
    checkCathode: kathode.checked,
    checkElectrolyt: elektrolyt.checked,
    checkSeparator: separator.checked,
    checkSpacer: spacer.checked,
    checkFeather: feder.checked,
    checkCase: gehaeuse.checked,
  });
  const [savingDisabled, setSavingDisabled] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
     let anodeData:undefined|__DataAnodeConverted=undefined;
    if(anode.checked){
      let nameID = inputs.anodeVar? materialNames.anode.map(x => x.name).indexOf(inputs.anodeVar) : null;
      let materialID = nameID ? materialNames.anode[nameID].id : null;
      anodeData = {
        id: undefined,
        materialName:inputs.anodeVar ?? materialNames.anode.filter(x => !x.state)[0].name,
        material:materialID?? materialNames.anode.filter(x => !x.state)[0].id,
        diameter: inputs.anodengroesse ? Number(String(inputs.anodengroesse).replace(',','.')) : 0
      }
    }
    let cathodedata:undefined|__DataCathodeConverted =undefined;
    if(kathode.checked){
      let nameID = inputs.kathodeVar? materialNames.cathode.map(x => x.name).indexOf(inputs.kathodeVar) : null;
      let materialID = nameID ? materialNames.cathode[nameID].id : null;
      cathodedata = {
        id: undefined,
        materialName:inputs.kathodeVar?? materialNames.cathode.filter(x => !x.state)[0].name,
        material:materialID ?? materialNames.cathode.filter(x => !x.state)[0].id,
        diameter: inputs.kathodengroesse ? Number(String(inputs.kathodengroesse).replace(',','.')) : 0
      }
    }
    let electrolytData:undefined|__DataElectrolytConverted =undefined;
    if(elektrolyt.checked){
      let nameID = inputs.elektrolytVar? materialNames.electrolyt.map(x => x.name).indexOf(inputs.elektrolytVar) : null;
      let materialID = nameID ? materialNames.electrolyt[nameID].id : null;
      electrolytData = {
        id: undefined,
        materialName:inputs.elektrolytVar?? materialNames.electrolyt.filter(x => !x.state)[0].name,
        material:materialID ?? materialNames.electrolyt.filter(x => !x.state)[0].id,
        volume: inputs.elektrolytgesamtvolumen? Number(String(inputs.elektrolytgesamtvolumen).replace(',','.')) : 0,
        volumeText: inputs.elektrolytvolumen ??'',
        volumeUnit: ''
      }
    }
    let separatorData:undefined|__DataSeperatorConverted = undefined;
    if(separator.checked){
      let nameID = inputs.separatorVar?materialNames.separator.map(x => x.name).indexOf(inputs.separatorVar) : null;
      let materialID = nameID ? materialNames.separator[nameID].id : null;
      separatorData ={
        id: undefined,
        materialName:inputs.separatorVar?? materialNames.separator.filter(x => !x.state)[0].name,
        material:materialID ?? materialNames.separator.filter(x => !x.state)[0].id,
        diameter: inputs.separatorgroesse ? Number(String(inputs.separatorgroesse).replace(',','.')) : 0,
        numberOfSeperators: inputs.separatoranzahl ? Number(inputs.separatoranzahl) : 0
      };
    }
    let featherData: undefined|__DataFeatherConverted= undefined;
    if(feder.checked){
      let nameID = inputs.federVar?materialNames.feather.map(x => x.name).indexOf(inputs.federVar) : null;
      let materialID = nameID ? materialNames.feather[nameID].id : null;
      featherData ={
        id: undefined,
        materialName:inputs.federVar??materialNames.feather.filter(x => !x.state)[0].name,
        material:materialID ?? materialNames.feather.filter(x => !x.state)[0].id,
      }
    }
    let spacerData: undefined|__DataSpacerConverted = undefined;
    if(spacer.checked){
      let nameID =inputs.spacerVarCreate? materialNames.spacer.map(x => x.name).indexOf(inputs.spacerVarCreate) : null;
      let materialID = nameID ? materialNames.spacer[nameID].id : null;
      spacerData ={
        id: undefined,
        materialName:inputs.spacerVarCreate ?? materialNames.spacer.filter(x => !x.state)[0].name,
        material:materialID ?? materialNames.spacer.filter(x => !x.state)[0].id,
        diameter: inputs.spacergroesse ? Number(String(inputs.spacergroesse).replace(',','.')) : 0,
        thickness: inputs.spacerdicke ? Number(String(inputs.spacerdicke).replace(',','.')) : 0
      };
    }
    let caseData: undefined|__DataCaseConverted = undefined;
    if(gehaeuse.checked){
      let nameID = inputs.gehaeuseVar?materialNames.case.map(x => x.name).indexOf(inputs.gehaeuseVar) : null;
      let materialID = nameID ? materialNames.case[nameID].id : null;
      caseData ={
        id: undefined,
        materialName:inputs.gehaeuseVar ?? materialNames.case.filter(x => !x.state)[0].name,
        material:materialID ?? materialNames.case.filter(x => !x.state)[0].id,
      }
    }
    let requestData: __BatteryVariationConverted = {
      id: undefined,
      anode: anodeData,
      cathode: cathodedata,
      electrolyt: electrolytData,
      separator: separatorData,
      feather: featherData,
      spacer: spacerData,
      caseData: caseData     
    };
    submitChangeHandler(requestData, index);
    event.preventDefault();
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });
  };

  const onDismiss = () => {
    setInputs({
      anodeVar:(anode.anode && anode.anode !== "") ? anode.anode :  materialNames.anode.filter(x => !x.state)[0].name,
      anodengroesse: anode.anodengroesse,
      kathodeVar: (kathode.kathode && kathode.kathode !== "") ? kathode.kathode :   materialNames.cathode.filter(x => !x.state)[0].name,
      kathodengroesse: kathode.kathodengroesse,
      elektrolytVar: (elektrolyt.elektrolyt && elektrolyt.elektrolyt !== "") ? elektrolyt.elektrolyt :  materialNames.electrolyt.filter(x => !x.state)[0].name,
      elektrolytvolumen: elektrolyt.elektrolytvolumen,
      elektrolytgesamtvolumen: elektrolyt.elektrolytgesamtvolumen,
      separatorVar: (separator.separator && separator.separator !== "") ? separator.separator : materialNames.separator.filter(x => !x.state)[0].name,
      separatorgroesse: separator.separatorgroesse,
      separatoranzahl: separator.separatoranzahl,
      federVar: (feder.feder && feder.feder !== "") ? feder.feder : materialNames.feather.filter(x => !x.state)[0].name,
      spacerVarCreate: (spacer.spacer && spacer.spacer !== "") ? spacer.spacer : materialNames.spacer.filter(x => !x.state)[0].name,
      spacergroesse: spacer.spacergroesse,
      spacerdicke: spacer.spacerdicke,
      gehaeuseVar: (gehaeuse.gehaeuse && gehaeuse.gehaeuse !== "") ? gehaeuse.gehaeuse : materialNames.case.filter(x => !x.state)[0].name,
      checkAnode: anode.checked,
      checkCathode: kathode.checked,
      checkElectrolyt: elektrolyt.checked,
      checkSeparator: separator.checked,
      checkSpacer: spacer.checked,
      checkFeather: feder.checked,
      checkCase: gehaeuse.checked,
    });
  };
  

  useEffect(()=>{
    //HIDDEN IS NOT WORKING IN DATALIST -> HANDLE REMOVE INVALID ENTRIES ADDITIONALLY
    //remove Anode from datalist with invalid state
    const invalidAnodeNames = materialNames.anode.filter(x => x.state).map(x => x.name);
    invalidAnodeNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#anodeOptionsCreateVar option[value='${value}']`);
      if(optionToHide){
        optionToHide.remove();
      }
    });
    //remove Cathode from datalist with invalid state
    const invalidCathodeNames = materialNames.cathode.filter(x => x.state).map(x => x.name);
    invalidCathodeNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#cathodeOptionsCreateVar option[value='${value}']`);
      if(optionToHide){
        optionToHide.remove();
      }
    });
    //remove Elektrolyt from datalist with invalid state
    const invalidElectrolytNames = materialNames.electrolyt.filter(x => x.state).map(x => x.name);
    invalidElectrolytNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#electrolytOptionsCreateVar option[value='${value}']`);
      if(optionToHide){
        optionToHide.remove();
      }
    });
    //remove Separator from datalist with invalid state
    const invalidSeparatorNames = materialNames.separator.filter(x => x.state).map(x => x.name);
    invalidSeparatorNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#separatorOptionsCreateVar option[value='${value}']`);
      if(optionToHide){
        optionToHide.remove();
      }
    });
    //remove Feather from datalist with invalid state
    const invalidFeatherNames = materialNames.feather.filter(x => x.state).map(x => x.name);
    invalidFeatherNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#featherOptionsCreateVar option[value='${value}']`);
      if(optionToHide){
        optionToHide.remove();
      }
    });
    //remove Spacer from datalist with invalid state
    const invalidSpacerNames = materialNames.spacer.filter(x => x.state).map(x => x.name);
    invalidSpacerNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#spacerOptionsCreateVar option[value='${value}']`);
      if(optionToHide){
        optionToHide.remove();
      }
    });
    //remove Case from datalist with invalid state
    const invalidCaseNames = materialNames.case.filter(x => x.state).map(x => x.name);
    invalidCaseNames.forEach(function(value){
      const optionToHide = document.querySelector(`datalist#caseOptionsCreateVar option[value='${value}']`);
      if(optionToHide){
        optionToHide.remove();
      }
    });
  },[]);
  useEffect(()=>{
    //check inputs and set save to disabled if names not known
    if((inputs.anodeVar !== undefined && !materialNames.anode.map(x => x.name).includes(inputs.anodeVar))
      ||(inputs.kathodeVar !== undefined && !materialNames.cathode.map(x => x.name).includes(inputs.kathodeVar))
      ||(inputs.separatorVar !== undefined && !materialNames.separator.map(x => x.name).includes(inputs.separatorVar))
      ||(inputs.spacerVarCreate !== undefined && !materialNames.spacer.map(x => x.name).includes(inputs.spacerVarCreate))
      ||(inputs.elektrolytVar !== undefined && !materialNames.electrolyt.map(x => x.name).includes(inputs.elektrolytVar))
      ||(inputs.federVar !== undefined && !materialNames.feather.map(x => x.name).includes(inputs.federVar))
      ||(inputs.gehaeuseVar !== undefined && !materialNames.case.map(x => x.name).includes(inputs.gehaeuseVar))){
        setSavingDisabled(true);
    }
    else{
      setSavingDisabled(false);
    }

  },[inputs]);
  const modalElement = document.getElementById(modalID) as HTMLElement;
  useEffect(()=>{
    if(modalElement){
      modalElement.addEventListener('hidden.bs.modal', () => onDismiss() );
    }
  });

  return (
    <div className='modal fade mt-4 bg-transparent' id={modalID} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-middle modal-xl">
        <div className="modal-content modal-background-lightgrey batteriebau-input-modal">
          <div className="modal-header pb-2">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className='modal-body py-1'>
            {inputs.checkAnode?(
              <div className='row'>
                <div className='col-2'>
                  <h2 className='pt-4'>Anode</h2>
                </div>
              <div className='col-4'>
                <label htmlFor="anodeVar" className="form-label mb-0">Anode</label>
                <input 
                  className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                  list="anodeOptionsCreateVar" 
                  placeholder={'Anode wählen ...'}
                  value={inputs.anodeVar}
                  name='anodeVar'
                  autoCorrect="off"
                  autoComplete='new-password'
                  onChange={(event) => handleOptionChange(event)}
                />
                <datalist id="anodeOptionsCreateVar">
                  {materialNames.anode.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </datalist>
              </div>
              <div className='col-3'>
                <label htmlFor='anodengroesse'>Anodengröße (mm)</label>
                <InputField
                  value={inputs.anodengroesse}
                  inputType="number" 
                  step="0.01"
                  min='0'
                  name='anodengroesse'
                  placeholder='5'
                  changeHandler={handleChange}
                  inputClass='text-md border-bottom-primary margin-bottom-10'
                />
              </div>
            </div>
          ):(<></>)
          }
          {inputs.checkCathode?(
            <div className='row'>
              <div className='col-2'>
                <h2 className='pt-4'>Kathode</h2>
              </div>
              <div className='col-4'>
                <label htmlFor="kathode" className="form-label mb-0">Kathode</label>
                <input 
                  className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                  list="cathodeOptionsCreateVar" 
                  placeholder={'Kathode wählen ...'}
                  value={inputs.kathodeVar}
                  name='kathodeVar'
                  autoCorrect="off"
                  autoComplete='new-password'
                  onChange={(event) => handleOptionChange(event)}
                />
                <datalist id="cathodeOptionsCreateVar">
                  {materialNames.cathode.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </datalist>
              </div>
              <div className='col-3'>
                <label htmlFor='kathodengroesse'>Kathodengröße (mm)</label>
                <InputField
                  value={inputs.kathodengroesse}
                  inputType="number" 
                  step="0.01"
                  min='0'
                  name='kathodengroesse'
                  placeholder='5'
                  changeHandler={handleChange}
                  inputClass='text-md border-bottom-primary margin-bottom-10'
                />
              </div>
            </div>
          ):(<></>)
          }
          {inputs.checkElectrolyt?(
            <div className='row'>
              <div className='col-2'>
                <h2 className='pt-4'>Elektrolyt</h2>
              </div>
              <div className='col-4'>
                <label htmlFor="elektrolyt" className="form-label mb-0">Elektrolyt</label>
                <input 
                  className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                  list="electrolytOptionsCreateVar" 
                  placeholder={'Elektrolyt wählen ...'}
                  value={inputs.elektrolytVar}
                  name='elektrolytVar'
                  autoCorrect="off"
                  autoComplete='new-password'
                  onChange={(event) => handleOptionChange(event)}
                />
                <datalist id="electrolytOptionsCreateVar">
                  {materialNames.electrolyt.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </datalist>
              </div>
              <div className='col-3'>
                <label htmlFor='elektrolytvolumen'>Volumen</label>
                <InputField
                  value={inputs.elektrolytvolumen}
                  inputType='text'
                  name='elektrolytvolumen'
                  placeholder='2x2,5ul'
                  changeHandler={handleChange}
                  inputClass='text-md border-bottom-primary margin-bottom-10'
                />
              </div>
              <div className='col-3'>
                <label htmlFor='elektrolytgesamtvolumen'>Gesamtvolumen (ul)</label>
                <InputField
                  value={inputs.elektrolytgesamtvolumen}
                  inputType="number" 
                  step="0.01"
                  name='elektrolytgesamtvolumen'
                  placeholder='5'
                  changeHandler={handleChange}
                  inputClass='text-md border-bottom-primary margin-bottom-10'
                />
              </div>
            </div>
            ):(<></>)
          }
          {inputs.checkSeparator?(
            <div className='row'>
              <div className='col-2'>
                <h2 className='pt-4'>Separator</h2>
              </div>
              <div className='col-4'>
                <label htmlFor="separatorVar" className="form-label mb-0">Separator</label>
                <input 
                  className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                  list="separatorOptionsCreateVar" 
                  placeholder={'Separator wählen ...'}
                  value={inputs.separatorVar}
                  name='separatorVar'
                  autoCorrect="off"
                  autoComplete='new-password'
                  onChange={(event) => handleOptionChange(event)}
                />
                <datalist id="separatorOptionsCreateVar">
                  {materialNames.separator.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </datalist>
              </div>
              <div className='col-3'>
                <label htmlFor='separatorgroesse'>Separatorgröße (mm)</label>
                <InputField
                  value={inputs.separatorgroesse}
                  inputType="number" 
                  step="0.01"
                  min='0'
                  name='separatorgroesse'
                  placeholder='5'
                  changeHandler={handleChange}
                  inputClass='text-md border-bottom-primary margin-bottom-10'
                />
              </div>
              <div className='col-3'>
                <label htmlFor='separatoranzahl'>Separatoranzahl</label>
                <p></p>
                  <input
                    type='number'
                    name='separatoranzahl'
                    placeholder={'0'}
                    value={inputs.separatoranzahl}
                    onChange={handleChange}
                    min = '0'
                    className='input-lg text-md border-bottom-primary'
                />
              </div>
            </div>
            ):(<></>)
          }
          {inputs.checkFeather?(
            <div className='row'>
              <div className='col-2'>
                <h2 className='pt-4'>Feder</h2>
              </div>
              <div className='col-4'>
                <label htmlFor="federVar" className="form-label mb-0">Feder</label>
                <input 
                  className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                  list="featherOptionsCreateVar" 
                  placeholder={'Feder wählen ...'}
                  value={inputs.federVar}
                  name='federVar'
                  autoCorrect="off"
                  autoComplete='new-password'
                  onChange={(event) => handleOptionChange(event)}
                />
                <datalist id="featherOptionsCreateVar">
                  {materialNames.feather.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </datalist>
              </div>
            </div>
            ):(<></>)
          }
          {inputs.checkSpacer?(
            <div className='row'>
              <div className='col-2'>
                <h2 className='pt-4'>Spacer</h2>
              </div>
              <div className='col-4'>
                <label htmlFor="spacerVar" className="form-label mb-0">Spacer</label>
                  <input 
                    className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                    list="spacerOptionsCreateVar" 
                    placeholder={'Spacer wählen ...'}
                    value={inputs.spacerVarCreate}
                    name='spacerVarCreate'
                    autoCorrect="off"
                    autoComplete='new-password'
                    onChange={(event) => handleOptionChange(event)}
                  />
                  <datalist id="spacerOptionsCreateVar">
                    {materialNames.spacer.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </datalist>
              </div>
              <div className='col-3'>
                <label htmlFor='spacergroesse'>Spacergröße (mm)</label>
                <InputField
                  value={inputs.spacergroesse}
                  inputType="number" 
                  step="0.01"
                  name='spacergroesse'
                  min='0'
                  placeholder='5'
                  changeHandler={handleChange}
                  inputClass='text-md border-bottom-primary margin-bottom-10'
                />
              </div>
              <div className='col-3'>
                <label htmlFor='spacerdicke'>Spacerdicke (mm)</label>
                <InputField
                  value={inputs.spacerdicke}
                  inputType="number" 
                  step="0.01"
                  min='0'
                  name='spacerdicke'
                  placeholder='5'
                  changeHandler={handleChange}
                  inputClass='text-md border-bottom-primary margin-bottom-10'
                />
              </div>
            </div>
            ):(<></>)
          }
          {inputs.checkCase?(
            <div className='row'>
              <div className='col-2'>
                <h2 className='pt-4'>Gehäuse</h2>
              </div>
              <div className='col-4'>
                <label htmlFor="gehaeuseVar" className="form-label mb-0">Gehäuse</label>
                <input 
                  className="form-control input-lg text-md border-bottom-primary margin-top-0" 
                  list="caseOptionsCreateVar" 
                  placeholder={'Gehäuse wählen ...'}
                  value={inputs.gehaeuseVar}
                  name='gehaeuseVar'
                  autoCorrect="off"
                  autoComplete='new-password'
                  onChange={(event) => handleOptionChange(event)}
                />
                <datalist id="caseOptionsCreateVar">
                  {materialNames.case.map((option: {name:string, id:string, state: boolean}, idx:number) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </datalist>
              </div>
            </div>
            ):(<></>)
          }
          </div>
          <div className='modal-footer'>
            {savingDisabled 
              ? <p className='text-err-color-font'>Einige Materialen wurden nicht erkannt oder sind nicht mehr verfügbar. Bitte wählen Sie valide Materialien aus!</p>
              : <></>
            }
            <button 
              type="button" 
              className="btn-main margin-top-5" 
              data-bs-dismiss="modal"> Abbrechen
            </button>
            <button
              disabled={savingDisabled}
              className='btn-main margin-top-5'
              type='button'
              onClick={handleSubmit} 
              data-bs-dismiss="modal"
              > Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
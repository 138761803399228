import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import InputField from '../../UI/InputField';
//import { enums } from '../../data';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { ApiAuth } from '../../backend/ApiAuth';
import Config from '../../util/Config';
import { useUserList } from '../../hooks/UserHooks';

interface BenutzererstellungProps {
  handleModal: () => void;
  id: string;
}
export default function Benutzererstellung({
  id,
  handleModal,
}: BenutzererstellungProps) {
  // call Apis for basic data
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const [userListDict,userDataLoaded, updateUserList] = useUserList();

  const [inputs, setInputs] = useState({
    loginName: '',
    role: enums.Role[0],
    authorization: '',
    username: '',
    password: '',
    passwordRepetition: '',
  });
  const [loginNameError, setLoginNameError] = useState(false);
  const [passwordError, setpasswordError] = useState(false);
  const [loginNameEmpty, setLoginNameEmpty] = useState(false);
  const [displayNameEmpty, setdisplayNameEmpty] = useState(false);
  const [passwordEmpty, setpasswordEmpty] = useState(false);
  const [missingInput, setMissingInputs] = useState(false);

  //Validation
  const validateFields = () =>{
    setLoginNameEmpty(false);
    setdisplayNameEmpty(false);
    setpasswordEmpty(false);
    setMissingInputs(false);
    setpasswordError(false);
    setLoginNameError(false);
    if(inputs.loginName!=='' && userListDict.map(x=>x.loginName).includes(inputs.loginName)){
      setLoginNameError(true)
      setMissingInputs(true);
    }
    if(inputs.password !== inputs.passwordRepetition){
        setpasswordError(true);
        setMissingInputs(true);
    }
    if(inputs.loginName === ''){
        setLoginNameEmpty(true);
        setMissingInputs(true);
    }
    if(inputs.username === ''){
      setdisplayNameEmpty(true);
      setMissingInputs(true);
    }
    if(inputs.password === '' || inputs.passwordRepetition === ''){
      setpasswordEmpty(true);
      setMissingInputs(true);
    }
  };

  //Handle Functions
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if(!missingInput){
        await ApiAuth.Create({
          platform: Config.Platform,
          loginName: inputs.loginName,
          displayName: inputs.username,
          position: inputs.authorization,
          state: 'aktiv',
          password: inputs.password,
          role: inputs.role
        })
        .then(data => {
          handleModal();
          ApiAuth.GetUserNamesList(true);
          updateUserList(true);
        })
        .catch((e)=>{
          console.log(e);
        });
        clearInput();
    }
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });
  };

  //clear input on dismiss
  const clearInput = () => {
    setInputs({
      loginName: '',
      role: enums.Role[0],
      authorization: '',
      username: '',
      password: '',
      passwordRepetition: '',
    });
  };
  //UseEffects
  const modalElement = document.getElementById(id) as HTMLElement;
  useEffect(()=>{
    if(modalElement){
      modalElement.addEventListener('hidden.bs.modal', () => clearInput() );
    }
  });
  useEffect(() => {
    if(userDataLoaded){
      validateFields();
    }
  }, [inputs,userDataLoaded]);

  
  return (
    <div className='modal fade mt-4' id={id} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-middle">
          <div className="modal-content px-3 modal-background-lightgrey benutzererstellung-modal">
            <div className="modal-header pb-1">
                <p className="modal-title fs-3">Benutzer anlegen</p>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <label htmlFor=''>Login-Name</label>
              <InputField
                inputType='text'
                name='loginName'
                placeholder={'Login-Name'}
                value={inputs.loginName}
                changeHandler={handleChange}
                inputClass={(loginNameEmpty || loginNameError)?'text-md border-bottom-error':'text-md border-bottom-primary margin-bottom-5'}
              />
              {
                loginNameError? 
                  <p className = 'text-err margin-bottom-5'>Name existiert bereits.</p>
                : <></>
              }
              {
                loginNameEmpty?
                  <p className = 'text-err margin-bottom-5'>Feld darf nicht leer sein</p>
                : <></>
              }
              <div>
                <label htmlFor='loginName'>Anzeige-Name</label>
              <InputField
                inputType='text'
                name='username'
                placeholder='Anzeige-Name'
                value={inputs.username}
                changeHandler={handleChange}
                inputClass={(displayNameEmpty)? ' text-md border-bottom-error':' text-md border-bottom-primary margin-bottom-5'}
              />
              {
                displayNameEmpty ?
                  <p className = 'text-err margin-bottom-5'>Feld darf nicht leer sein.</p>
                : <></>
              }
              </div>
              
              <label className='margin-top-0' htmlFor=''>
                Position
              </label>
              <InputField
                inputType='text'
                name='authorization'
                placeholder='Laborant'
                value={inputs.authorization}
                changeHandler={handleChange}
                inputClass=' text-md border-bottom-primary margin-bottom-5'
              />
              <label htmlFor='role'>Typ</label>
              <div style={{ visibility: 'hidden', height: 0 }}>
                <InputField
                  inputType='text'
                  name='role'
                  placeholder={inputs.role}
                  value={inputs.role}
                  changeHandler={handleChange}
                  disabled={false}
                  inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-bottom-5'
                />
              </div>
              <select
                name='role'
                value={inputs.role}
                onChange={(event) => handleOptionChange(event)}
                className='input-lg text-md border-bottom-primary margin-bottom-5'
              >
                {enums.Role.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              
              
              
              <div>
                <label htmlFor=''>Passwort</label>
              <InputField
                inputType='password'
                name='password'
                placeholder='*****'
                value={inputs.password}
                changeHandler={handleChange}
                inputClass={(passwordError || passwordEmpty)?' text-md border-bottom-error margin-bottom-5':' text-md border-bottom-primary margin-bottom-5'}
              />
              <label htmlFor=''>Passwort wiederholen</label>
              <InputField
                inputType='password'
                name='passwordRepetition'
                placeholder='*****'
                value={inputs.passwordRepetition}
                changeHandler={handleChange}
                inputClass={(passwordError || passwordEmpty)?' text-md border-bottom-error':' text-md border-bottom-primary margin-bottom-5'}
              />
              {
                passwordError ?
                  <p className = {`text-err ${passwordEmpty ? 'mb-0' : 'margin-bottom-5'}`}>Passwörter stimmmen nicht überein.</p>
                :<></>
              }
              {
                passwordEmpty ?
                  <p className = 'text-err margin-bottom-5'>Felder dürfen nicht leer sein.</p>
                : <></>
              }
              </div>
            </div>

            <div className="modal-footer justify-content-center">
              <button 
                  type="button" 
                  className= {`btn-main`} 
                  onClick={event=> handleSubmit(event)} 
                  data-bs-dismiss="modal"
                  disabled={missingInput} 
              >
                  Erstellen
              </button>
            </div>
        </div>
      </div>
    </div>
  );
}

import {
  faAnchor,
  faBattery,
  faBatteryCar,
  faClipboard,
  faFolderOpen,
  faHouse,
  faLaptop,
  faLightbulb,
  faPeopleGroup,
  faUser,
  faWarehouse
} from '@fortawesome/free-solid-svg-icons';
import { SidebarNavigationProps } from './components/Sidebar';

// https://www.npmjs.com/package/ts-sync-request
//import ApiEnumeration from './backend/ApiBackendData';

export const SidebarTest: SidebarNavigationProps[] = [
  {
    icon: faHouse,
    title: 'Dashboard',
    selected: false,
    link: '/dashboard',
    role: 'User'
  },
  {
    icon: faLaptop,
    title: 'Arbeitspakete',
    selected: true,
    link: '/gesamtuebersicht',
    role: 'User'
  },
  {
    icon: faWarehouse,
    title: 'Material',
    selected: false,
    link: '/material',
    role: 'User'
  },
  {
    icon: faUser,
    title: 'Benutzer',
    selected: false,
    link: '/benutzer',
    role: 'Admin'
  },
  {
    icon: faFolderOpen,
    title: 'Einzelansichten',
    selected: false,
    link: '/einzelansichten',
    role: 'User',
    dropdownelements: [
      {
        icon: faClipboard,
        title: 'Planung',
        selected: false,
        link: '/planung',
        role: 'User'
      },
      {
        icon: faLightbulb,
        title: 'Arbeitspakete',
        selected: false,
        link: '/arbeitspakete',
        role: 'User'
      },
      {
        icon: faAnchor,
        title: 'Anodenbau',
        selected: false,
        link: '/anodenbau',
        role: 'User'
      },
      {
        icon: faBattery,
        title: 'Batteriebau',
        selected: false,
        link: '/batteriebau',
        role: 'User'
      },
      {
        icon: faBatteryCar,
        title: 'Batterietest',
        selected: false,
        link: '/batterietest',
        role: 'User'
      },
      {
        icon: faPeopleGroup,
        title: 'Charakterisierung',
        selected: false,
        link: '/charakterisierung',
        role: 'User'
      },
    ]
  }
];

/**
 * /data/getEnumerations
 */

/*

enums contains:

CellType
FileType
Location
MaterialOrigin
MaterialState
MaterialType
MeanderExpressionState
MeasuringPoint
Platform
PmAnalysisState
Role
UserState
WorkBlockState
WorkPackageState

*/

//export const enums: Record<string, string[]> = await ApiEnumeration.GetEnumerations();

/**
 * /user/list
 */

// http://localhost:5000/user/list

export interface BackendUser {
  id: string;
  loginName: string;
  displayName: string;
};

export interface IBackendUserList {
  success: boolean;
  data: BackendUser[];
};


import Config from "../util/Config";
import { ApiClient } from "./ApiClient";
import BasicResponse from "./BasicResponse";
import { ApiPrepareRequest } from './ApiPrepareRequest';
import { __FileResponse } from "./types/__GeneralStructures";

export class ApiFile {

    static basePath: string = "/files";
    static baseUrl: string = Config.BackendUrl + ApiFile.basePath;
    //Get
    static GetInfo(id: string = ''): Promise<__FileResponse> {
        let path = '/getinfo';

        // prepare query
        let query = ApiPrepareRequest.PrepareGetQuery(id);
        let url = ApiFile.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.get(url);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject = (await response.json()) as BasicResponse<__FileResponse>;
        resolve(responseObject.data);
        });
    };
    static Upload(formData: FormData, path:string, id: string): Promise<boolean> {
        let url = Config.BackendUrl + path + '/upload';
        formData.append('id',id);

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.postFile(url, formData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }
        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    static UploadFileForCharacteristics(formData: FormData, path:string, id: string): Promise<boolean> {
        let url = Config.BackendUrl + path;
        formData.append('id',id);

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.postFile(url, formData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }
        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };

    static Delete(id: string): Promise<boolean> {
        let path = '/delete';
        let url = ApiFile.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, {id:id});
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }
        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    static Download(id: string): Promise<string> {
        let path = '/downloadorig';
        let query = '?id=' + id;
        let url = ApiFile.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.get(url);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject = (await response.json()) as BasicResponse<string>;

        resolve(responseObject.data);
        });
    };
};




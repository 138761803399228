import {useState, useEffect} from "react"
import { ConfirmationModal } from "./Modal";

// Define the props for the table component
type TableProps = {
  modalIDList: string[];
  modalTextList?: string[];

  headers: string[];
  valuesList: (string | number)[][];
  stateList: string[][] | null;
  functionsList: { func: Function[]; val: string[]; defaultState: string[]; modal:boolean[]};
};

interface ITableRow {
  entries: (string|number)[];
  index: number;
  [key: string]: number|(string|number)[]; // add an index signature
}

export const Table : React.FC<TableProps> = ({ headers, valuesList, stateList=null, functionsList, modalIDList, modalTextList }: TableProps) => {
  // Create table rows
  const [tableRows, setTableRows] = useState<ITableRow[]>([]);

  useEffect(() => {

    setTableRows(valuesList.map((value, index) => {
      let row : ITableRow = {
        entries: value,
        index: index
      };
      return row;
    }));
  
  }, [valuesList]);

  // Render the table
  return (
    <div className="table-responsive margin-top-25">
      <table className='table main-table table-hover align-middle'>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th className="table-vertical-align-middle text-center">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRows.map((values, index) => 
              <tr key={index+"_"+values.index}>
                {values.entries.length > 0 && values.entries.map((entry, entryIndex) => {
                  if (entryIndex === 0) return null;
                  return <td key={index+"_"+entryIndex} className="table-vertical-align-middle">{entry}</td>;
                })}
                {functionsList.func.length > 0 ? (
                  <td className="table-vertical-align-middle">
                    {functionsList.func.map((fun, functionIndex) => {
                      let functionState = stateList === null ? 
                          functionsList.defaultState[functionIndex] : 
                          stateList[index][functionIndex];

                      return (
                        <>
                        <button
                          key={index+"_"+functionIndex}
                          data-bs-toggle={functionsList.modal[functionIndex] ? 'modal' : ''}
                          data-bs-target={'#' + modalIDList[functionIndex] + '_' + String(index)}
                          className={`btn-main ${
                              functionIndex > 0 ? 'margin-left-5' : ''
                            } 
                            ${
                              functionState === "hidden" ? "hidden" : "" 
                            }`
                          }

                          type='button'
                          onClick={!functionsList.modal[functionIndex] ? () => fun(index) : () => ('')}
                          disabled={functionState === "disabled" ? true : false}
                        > {functionsList.val[functionIndex]}
                        </button>
                        <ConfirmationModal
                          modalID={modalIDList[functionIndex] + '_' + String(index)}
                          modalText= {modalTextList ? modalTextList[functionIndex] : ''}
                          confirmationText= {functionsList.val[functionIndex]}
                          handleConfirm={() => fun(index)}
                        />
                      </>
                      );
                    })}
                  </td>
                ) : (
                  <></>
                )}
              </tr>
          )
        }
        </tbody>
      </table>
    </div>
    
  );
};
import Config from "../util/Config";
import { __BatteryConverted, __CreateBatteryIDObject, __BatteryIDObject, __BatteryDataConverted, __EvaluationConverted, __BatteryWishConverted} from "./types/__GeneralStructures";
import { ApiClient } from "./ApiClient";
import BasicResponse from "./BasicResponse";
import * as requestTypes from "./types/__RequestStructures"
import * as responseTypes from "./types/__ResponseStructures"
import { ApiPrepareRequest } from './ApiPrepareRequest';

export class ApiBatteryConstruction {

    static basePath: string = "/battery";
    static baseUrl: string = Config.BackendUrl + ApiBatteryConstruction.basePath;
    //Get
    static Get(id: string = ''): Promise<responseTypes.IBackendBatteryConstructionGet> {
        let path = '/get';

        // prepare query
        let query = ApiPrepareRequest.PrepareGetQuery(id);
        let url = ApiBatteryConstruction.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.get(url);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendBatteryConstructionGet>;

        resolve(responseObject.data);
        });
    };
    // TODO: add filter and search
    /**
     * /auth/list
     */
    static ListAll(): Promise<responseTypes.IBackendBatteryConstructionList[]> {

        let path = "/list";
        let url = ApiBatteryConstruction.baseUrl + path;

        return new Promise(async (resolve, reject) => {

            let response = await ApiClient.get(url);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendBatteryConstructionList[]>;

            resolve(responseObject.data);

        });

    };
    static ListBatteries(): Promise<responseTypes.IBackendBatteryList[]> {

        let path = "/listbatteries";
        let url = ApiBatteryConstruction.baseUrl + path;

        return new Promise(async (resolve, reject) => {

            let response = await ApiClient.get(url);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendBatteryList[]>;

            resolve(responseObject.data);

        });

    };
    static Create(requestData: requestTypes.__BatteryConstructionCreate): Promise<__BatteryIDObject> {
        let path = "/create";
        let url = ApiBatteryConstruction.baseUrl + path;
        //convert battery
        let battery:__BatteryConverted[] = [];
        for (let item of requestData.battery){
            let convertedBattery =JSON.parse(JSON.stringify(item)) as __BatteryConverted
            battery.push(convertedBattery);
        }

        return new Promise(async (resolve, reject) => {
            let convertedRequestData = {
                platform: requestData.platform,
                workBlock: requestData.workBlock,
                commentWish: requestData.commentWish,
                state: requestData.state,
                evaluation: JSON.parse(JSON.stringify(requestData.evaluation)) as __EvaluationConverted,
                responsibleUser: requestData.responsibleUser,
                fixedBatteryData: JSON.parse(JSON.stringify(requestData.fixedBatteryData)) as __BatteryDataConverted,
                batteryWishes: JSON.parse(JSON.stringify(requestData.batteryWishes)) as __BatteryWishConverted,
                battery: battery
            };
            let response = await ApiClient.post(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as __CreateBatteryIDObject<__BatteryIDObject>;

            resolve(responseObject.data);
        });
    };
    static Edit(requestData: requestTypes.__BatteryConstructionEdit): Promise<boolean> {
        let path = "/edit";
        let url = ApiBatteryConstruction.baseUrl + path;
        
        return new Promise(async (resolve, reject) => {
            let convertedRequestData = {
                id: requestData.id,
                state: requestData.state,
                date: requestData.date,
                commentIs: requestData.commentIs,
                evaluation: JSON.parse(JSON.stringify(requestData.evaluation)) as __EvaluationConverted,
                responsibleUser: requestData.responsibleUser,
                battery: requestData.battery,
                fixBatteryData: requestData.fixBatteryData,
                batterieIDListToDelete: requestData.batterieIDListToDelete
            };
            let response = await ApiClient.put(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<string>;

            resolve(responseObject.success);
        });
    };
    //delete
    static Delete(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/delete';
        let url = ApiBatteryConstruction.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //lock
    static Lock(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/lock';
        let url = ApiBatteryConstruction.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //activate
    static Activate(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/activate';
        let url = ApiBatteryConstruction.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //close
    static Close(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/close';
        let url = ApiBatteryConstruction.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //upload
    static Upload(requestData: requestTypes.__UploadFiles): Promise<boolean> {
        let path = '/upload';
        let url = ApiBatteryConstruction.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //generatepdf
    static GeneratePdf(id: string): Promise<string> {
        let path = '/generatepdf';
        let query = '?id=' + id;
        let url = ApiBatteryConstruction.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.get(url);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject = (await response.json()) as BasicResponse<string>;

        resolve(responseObject.data);
        });
    };
    //generatepdfwish
    static GeneratePdfWish(id: string): Promise<string> {
        let path = '/generatepdfwish';
        let query = '?id=' + id;
        let url = ApiBatteryConstruction.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.get(url);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject = (await response.json()) as BasicResponse<string>;

        resolve(responseObject.data);
        });
    };
}

export class BatteryConstructionConverter {
    // Temporärer Hinweis: Gedacht für die Tabellen Planung und Arbeitspakete
    static ConvertFromApiToTableList(data: responseTypes.IBackendBatteryConstructionList[]) {
        // [1, 'Anode 1', 'Tony', '15.03.2023', 'abgeschlossen', 'Halle'],
        let simpleList: string[][] = [];
        data.forEach(item => {
            let typeOfBattery:string = item.fixedBatteryData
                ? item.fixedBatteryData.typeID
                    ?? ''
                : ''
            simpleList.push([

                item.id,
                item.workblock.name ?? '',
                typeOfBattery === 'Halbzelle' ? 'HZ' : 'VZ',
                item.responsibleUser.length > 0 ? item.responsibleUser[0].displayName : "",
                new Date(item.date).toLocaleDateString(),
                item.state.name,
                item.batteryWishes.length > 0 
                    ? item.batteryWishes[0].locationOfConstructionID
                    : 'Halle'
            ]);
        });

        return simpleList;
    }
}


import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiWorkPackage } from '../../backend/ApiWorkPackage';
import {
  IBackendMaterialList,
  IBackendWorkPackage,
} from '../../backend/types/__ResponseStructures';
import {  __FileResponse, __PlannedStepsUnconverted, __WorkBlockResponse } from '../../backend/types/__GeneralStructures';
import InputField from '../../UI/InputField';
import AblaufConverterWithID from '../../components/AblaufConverterWithID';
import { ApiBackendData } from '../../backend/ApiBackendData';
import Loading from '../../components/Ladebalken';
import { ApiMaterial } from '../../backend/ApiMaterial';
import FileUploadAccordeon from '../../components/FileUpload';
import { ApiFile } from '../../backend/ApiFiles';
import { ApiWorkBlock } from '../../backend/ApiWorkBlock';
import { InfoModal } from '../../components/Modal';
import {faGear, faFilePdf}  from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArbeitsblockEditModal from '../Arbeitsbloecke/ArbeitsblockEditModal';
import { useUserList } from '../../hooks/UserHooks';

interface InputsHeaderProps {
  id: string,
  name: string;
  priority:number;
  datum: Date;
  verantwortlicher: string[];
  status: string;
  syntheseort:string;
  beschreibung: string;
  projektbeschreibung: string;
};
//Icon style
const gear = faGear;
const pdf = faFilePdf;

export default function ArbeitspaketDetail() {
  const location = useLocation();
  const [userListDict, userDataLoaded] = useUserList();

  const [initialized, setInitialized] = useState(false);
  const [loadFiles, setLoadFiles] = useState(false);
  const [workpackage, setWorkpackage] = useState<IBackendWorkPackage>();
  const [files, setFiles] = useState<__FileResponse[]>([]);
  const [inputsHeader, setInputsHeader] = useState<InputsHeaderProps>({
    id:'',
    name: '',
    priority: 0,
    verantwortlicher:[],
    status: '',
    syntheseort:'',
    beschreibung:'',
    datum: new Date(),
    projektbeschreibung: ''
  });
  const [workblocks, setWorkblocks] = useState<__WorkBlockResponse[]>([]);
  const [modalDataLoaded, setModalDataLoaded] = useState(false);
  const [materialList, setMaterialList] = useState<IBackendMaterialList[]>([]);
  const [hasAddition, setOccurenceOfAddition] = useState(false);
  const navigate = useNavigate();
  
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  //Loading
  const loadData = async() =>{
      await ApiWorkPackage.Get(location.state.workpackage[0])
      .then(async (data) => {
        const fileList = data.files?? []
        setFiles(fileList);
        setLoadFiles(true);
        let workblocks = [...data.workBlocks];//.filter(arbeitsblock => arbeitsblock.stateID !== 'gelöscht' && arbeitsblock.stateID !== 'gesperrt' );
        await setWorkblocks(workblocks);
        for(let block of data.workBlocks){
          if(block.addition.length > 0){
            setOccurenceOfAddition(true);
          }
        }
        document.title = 'Arbeitspaket ' + data.name;
        await setInputsHeader({
          id: data.id,
          name: data.name,
          priority: data.priority,
          datum: new Date(data.date),
          verantwortlicher: data.responsibleUser.map(x=>x.displayName),
          status: data.state.name,
          syntheseort: data.locationOfSynthesis.name,
          beschreibung: data.shortDescription,
          projektbeschreibung: data.projectDescription
        });
        await setWorkpackage(data);
      })
      .catch(e=>{
        console.log(e);
      });
    await ApiMaterial.ListAll()
      .then(async(data)=>{
        await setMaterialList(data);
      })
      .catch(e=>{
        console.log(e);
      });
    setOpenLoadingModal(false);
  };

  //Handle Functions
  // const handleGeneratePdf = async () => {
  //   setOpenLoadingModal(true);
  //   let packageId = inputsHeader.id;
  //   ApiWorkPackage.GeneratePdf(packageId).then((url) => {
  //     window.open(url, '_blank');
  //     setOpenLoadingModal(false);
  //   })
  //   .catch((e:unknown)=>{
  //     setOpenLoadingModal(false);
  //     //open error modal to show fail
  //     let errorModal = document.getElementById("ErrorDownloadFailed");
  //     if(!errorModal) return;
  //     $('#ErrorDownloadFailed').modal('show');
  //   });
  // };
  
  // const handleGeneratePdfAddition = async () => {
  //   setOpenLoadingModal(true);
  //   let packageId = inputsHeader.id;
  //   ApiWorkPackage.GeneratePdfAddition(packageId).then((url) => {
  //     window.open(url, '_blank');
  //     setOpenLoadingModal(false);
  //   })
  //   .catch((e:unknown)=>{
  //     setOpenLoadingModal(false);
  //     //open error modal to show fail
  //     let errorModal = document.getElementById("ErrorDownloadFailed");
  //     if(!errorModal) return;
  //     $('#ErrorDownloadFailed').modal('show');
  //   });
  // };

  const handleGeneratePdfSummary = async () => {
    setOpenLoadingModal(true);
    let packageId = inputsHeader.id;
    ApiWorkPackage.GeneratePdfSummary(packageId).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };
  const handleGeneratePdfWorkblock = (id:string) =>{
    setOpenLoadingModal(true);
    ApiWorkBlock.GeneratePdf(id).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };

  const submitModalClose = async(response:InputsHeaderProps) =>{
    await setOpenLoadingModal(true);
    await setModalDataLoaded(false);
    let inputsTemp = {...inputsHeader};
    if(!inputsTemp.verantwortlicher.includes(response.verantwortlicher[0])){
      inputsTemp.verantwortlicher.push(response.verantwortlicher[0]);
    }
    response.verantwortlicher = inputsTemp.verantwortlicher;
    
    let user:string[] = response.verantwortlicher.map(name => userListDict[userListDict.map(x=>x.displayName).indexOf(name)].loginName);

    await ApiWorkPackage.Edit({
      id: response.id,
      name: response.name,
      priority: response.priority,
      date: response.datum,
      shortDescription: response.beschreibung,
      projectDescription: response.projektbeschreibung,
      locationOfSynthesis: response.syntheseort,
      state: response.status,
      responsibleUser: user
    })
    .then(async()=>{
      await setOpenLoadingModal(false);
      loadData();
    })
    .catch(e =>{
      console.log(e)
    });
    return response;
  };
  const submitEditModalBlock = () => {
    setInitialized(false);
    loadData();
  };

  const handleReloadPage = () => {
    setLoadFiles(false);
  };
  const submitModalCloseAccordion = (response:FormData)  =>{
    sendFileData(response);
    // send formdata to backend
    return response;
  };
  const sendFileData = async(data:FormData) =>{
    await ApiFile.Upload(data,'/workpackage',inputsHeader.id).then(async ()=>{
      await ApiWorkPackage.Get(inputsHeader.id).then((data)=>{
        const fileList = data.files?? []
        setFiles(fileList);
        setLoadFiles(false);
      });
    });
  };

  //Use Effects
  useEffect(() => {
    if (!initialized && userDataLoaded) {
      setOpenLoadingModal(true);
      loadData();
    }
  },[userDataLoaded]);
  useEffect(()=>{
    if(workpackage){
      setInitialized(true);
    }
  },[workpackage]);
  useEffect(()=>{
    if(initialized){
      setModalDataLoaded(true);
    }
  },[initialized, inputsHeader]);
  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      {(workpackage && initialized) ? (
        <>
          { modalDataLoaded
            ?   <>
                  <h1>{workpackage.name}</h1>
                    <div className='margin-top-20 row'>
                      <div className='col-3'>
                        <p className="fw-bold"> Name:</p>
                      </div>
                      <div className='col-2'>
                        <p>{workpackage.name}</p>
                      </div>
                      <div className='col-3 '>
                        <p className="fw-bold"> Syntheseort:</p>
                      </div>
                      <div className='col-2'>
                        <p>{workpackage.locationOfSynthesis.name}</p>
                      </div>
                    </div>
                    <div className='row mt-2'>
                      <div className='col-3'>
                        <p className='fw-bold'>Verantwortlicher: </p>
                      </div>
                      <div className='col-2'>
                        <p>{inputsHeader.verantwortlicher.join(', ')}</p>
                      </div>
                      {/* <div className='col-3'>
                        <p className='fw-bold'>Kurzbeschreibung: </p>
                      </div>
                      <div className='col-2'>
                        <p>{workpackage.shortDescription}</p>
                      </div>    */}
                    </div>
                    <div className='row mt-2'>
                      <div className='col-3'>
                        <p className='fw-bold'>Status: </p>
                      </div>
                      <div className='col-2'>
                        <p>{workpackage.state.name === "inPlanung" ? "in Planung" : workpackage.state.name}</p>
                      </div>
                    </div>
                    <div className='row mt-2'>
                      <div className='col-3'>
                        <p className='fw-bold'>Projekbeschreibung:</p>
                      </div>
                      <div className='col-7'>
                        <p>{workpackage.projectDescription}</p>
                      </div>
                    </div>
                    <button
                      className='btn-main margin-top-15'
                      type='button'
                      data-bs-toggle='modal'
                      data-bs-target='#arbeitspaketEditModal'
                    >Daten bearbeiten
                    </button>
                    <ArbeitspaketBearbeiten
                      modalId='arbeitspaketEditModal'
                      changeHandler={submitModalClose}
                      id = {inputsHeader.id}
                      priority = {inputsHeader.priority}
                      name={inputsHeader.name}
                      datum={inputsHeader.datum}
                      syntheseort={inputsHeader.syntheseort}
                      verantwortlicher={inputsHeader.verantwortlicher[0]}
                      status = {inputsHeader.status}
                      beschreibung={inputsHeader.beschreibung}
                      projektbeschreibung={inputsHeader.projektbeschreibung}
                    />
                </>
              : <></>
          }
          <h2 className='margin-top-25'>Arbeitsblöcke</h2>
          <div className=''>
            {workblocks.map((arbeitsblock, index) => (
              <>{index !== 0 && <hr />}
              <div className='row overflow-auto pb-3' id={arbeitsblock.name}>
                <div className='col-2'>
                  <div className='row'>
                    <button
                      className={`btn-main-light margin-top-15 margin-left-0 ${arbeitsblock.stateID === 'gelöscht' || arbeitsblock.stateID === 'gesperrt' ? 'text-danger' : ''}`}
                      type='button'
                      style={{width:'125px'}}
                      onClick={() => console.log(index)}
                      data-bs-toggle='modal'
                      data-bs-target={'#arbeitsblockEditModal_'+String(index)}
                    >
                      <FontAwesomeIcon 
                        className='pe-3 '
                        data-bs-toggle='tooltip' data-bs-placement="bottom" title='Arbeitsblock bearbeiten'
                        icon={gear}
                        // style={{color: neutral}}
                      />
                      {`# ${index + 1}`}
                    </button>
                    <ArbeitsblockEditModal 
                      modalID={'arbeitsblockEditModal_'+String(index)}
                      workblockData={arbeitsblock}
                      changeHandler={submitEditModalBlock}
                    />
                  </div>
                  <div className='row'>
                    <button
                      className='btn-main margin-top-15 margin-left-0'
                      type='button'
                      disabled = {arbeitsblock.id ? false: true}
                      style={{width:'125px'}}
                      onClick={event =>handleGeneratePdfWorkblock(arbeitsblock.id)}
                    >
                      <FontAwesomeIcon
                        icon={pdf}
                        className='pe-3'
                      />
                      PDF
                    </button>
                  </div>
                </div>
                <div className='col-10'>
                  <AblaufConverterWithID
                    key={Math.random()}
                    blockID={arbeitsblock.id}
                    responsibleUser={[workpackage.responsibleUser[0].loginName]}
                    materialsList = {materialList}
                  />
                </div>
              </div>
              <div className='row mt-3 ms-4'>
                <div className='col-2'></div>
                <div className='col-10 text-start'>
                  <p className='ms-3'>{workblocks[index].comment} </p>
                </div>
              </div>
              </>
            ))}
            <div className='clearfix'></div> {/*cleart float*/}
            <div className='row arbeitspaket-detail-button-container mt-4'>
              <div className='col'> 
                <input
                  className='btn-main margin-top-10 mx-2'
                  type='button'
                  value='Zurück'
                  onClick={() => navigate(-1)}
                />
                {/* <input  
                  className='btn-main margin-top-10 mx-2'
                  type='button'
                  value='Dokumente generieren'
                  onClick={handleGeneratePdf}
                /> */}
                <input
                  className='btn-main margin-top-10 mx-2'
                  type='button'
                  value={workpackage.summary? 'Zusammenfassung bearbeiten' :'Zusammenfassung erstellen '}
                  onClick={() => navigate(`/arbeitspakete/zusammenfassung`,
                    {state:{
                      packageID: inputsHeader.id,
                      user: inputsHeader.verantwortlicher
                    }})
                }
                />
              </div>
            </div>
            <div className='row arbeitspaket-detail-button-container'>
              <div className='col'>
                <input
                  className='btn-main margin-top-10 mx-2'
                  type='button'
                  value='Arbeitsblock hinzufügen'
                  onClick={() => navigate(`/arbeitsbloecke/ergaenzung`,
                    {
                      state:{
                        workpackageID: inputsHeader.id,
                        workpackage: location.state.workpackage
                      }
                    }
                  )}
                  />
                {/* {hasAddition ?
                  <input
                    className='btn-main margin-top-10 mx-2'
                    type='button'
                    value='PDF Ergänzung generieren'
                    onClick={handleGeneratePdfAddition}
                  />
                  :<></>
                } */}
                {workpackage.summary?
                  <input
                    className='btn-main margin-top-10 mx-2'
                    type='button'
                    value='PDF Zusammenfassung generieren'
                    onClick={handleGeneratePdfSummary}
                  />
                  : <></>
                }
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {loadFiles ? 
        <div className='margin-top-20 paket-information-container row'>
            <div className='column'>
              <FileUploadAccordeon
                handleConfirm={submitModalCloseAccordion}
                handleReloadPage={handleReloadPage}
                files={files}
              />
            </div>
          </div>
      : <></>}
      <InfoModal
        modalID='ErrorDownloadFailed'
        infoText='Download fehlgeschlagen'
        confirmationText='Ok'
      />
    </div>
  );
};

interface ArbeitspaketBearbeitenProps {
  changeHandler: (item:InputsHeaderProps) => Promise<InputsHeaderProps>;
  modalId: string;
  id: string;
  name: string;
  priority: number,
  datum: Date;
  syntheseort: string;
  verantwortlicher: string;
  beschreibung: string;
  projektbeschreibung: string;
  status:string;
};

const ArbeitspaketBearbeiten = ({
  changeHandler,
  modalId,
  id,
  name,
  priority,
  datum,
  syntheseort,
  verantwortlicher,
  beschreibung,
  projektbeschreibung,
  status
}: ArbeitspaketBearbeitenProps) => {
  // call Apis for basic data
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const [userListDict] = useUserList();

  const [inputs, setInputs] = useState({
    id: id,
    paketName: name,
    priority: priority,
    datum: datum,
    verantwortlicher: verantwortlicher,
    syntheseort: syntheseort,
    beschreibung: beschreibung,
    projektbeschreibung: projektbeschreibung,
    status: status
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    if(name === 'status' && value === 'abgeschlossen'){
      let newInputs = {...inputs};
      newInputs.datum = new Date();
      newInputs.status = value;
      setInputs(newInputs);
    }
    else{
      setInputs((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = async () => {
    changeHandler({
      id: id,
      name: inputs.paketName,
      priority: inputs.priority,
      datum: inputs.datum,
      verantwortlicher: [inputs.verantwortlicher],
      status : inputs.status,
      syntheseort: inputs.syntheseort,
      beschreibung: inputs.beschreibung,
      projektbeschreibung: inputs.projektbeschreibung
    })
  };

  const onDismiss = () => {
    setInputs({
    id: id,
    paketName: name,
    priority:priority,
    datum: datum,
    verantwortlicher: verantwortlicher,
    syntheseort: syntheseort,
    beschreibung: beschreibung,
    projektbeschreibung: projektbeschreibung,
    status: status
  });
  };
  //clear input on dismiss
  const modalElement = document.getElementById(modalId) as HTMLElement;
  useEffect(()=>{
    if(modalElement){
      modalElement.addEventListener('hidden.bs.modal', () => onDismiss() );
    }
  });
  return (
    <div className='modal fade mt-4' id={modalId} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-middle">
        <div className="modal-content px-3 modal-background-lightgrey arbeitspaket-detail-modal">
          <div className="modal-header pb-1">
            <h2 className="modal-title">Arbeitspaket bearbeiten</h2>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body py-1">
            <div className='row margin-top-0'>
              <div className='col-3'>
                <label htmlFor='paketName'>Name</label>
                <InputField
                  inputType='text'
                  name='paketName'
                  placeholder=''
                  value={inputs.paketName}
                  changeHandler={handleChange}
                  disabled={true}
                  inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
                />
              </div>
              <div className='col-3'>
                <label htmlFor='syntheseort'>Syntheseort</label>
                <select
                  name='syntheseort'
                  value={inputs.syntheseort}
                  onChange={handleOptionChange}
                  className='input-lg text-center text-md border-bottom-primary  margin-top-5'
                >
                  {enums.Location.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-3'>
                <label htmlFor='verantwortlicher'>Verantwortlicher</label>
                <select
                  name='verantwortlicher'
                  value={inputs.verantwortlicher}
                  onChange={handleOptionChange}
                  className='input-lg text-center text-md border-bottom-primary  margin-top-5'
                >
                  {userListDict.map((option) => (
                    <option key={option.displayName} value={option.displayName} hidden={!option.stateIsActve}>
                      {option.displayName}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-3'>
                <label htmlFor='status'>Status</label>
                <select
                  name='status'
                  value={inputs.status}
                  onChange={handleOptionChange}
                  className='input-lg text-center text-md border-bottom-primary  margin-top-5'
                >
                  {enums.WorkPackageState.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* <div className='row margin-top-15'>
              <div className='col-12 mb-3'>
                <label htmlFor='beschreibung'>Kurzbeschreibung</label>
                <textarea
                  className='input-lg-textarea text-md border-bottom-primary cut-text-overflow margin-top-5'
                  name='beschreibung'
                  value={inputs.beschreibung}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}
            <div className='row margin-top-5 mb-2' style={{height:'200px'}}>
              <div className='col-12'>
                <label htmlFor='projektbeschreibung'>Projekt Beschreibung</label>
                <textarea
                  className='input-lg-textarea border-bottom-primary cut-text-overflow  margin-top-5'
                  name='projektbeschreibung'
                  value={inputs.projektbeschreibung}
                  onChange={handleInputChange}
                  style={{height:'90%'}}
                />
              </div>
            </div>
          </div>
          <div className='modal-footer pt-1'>
            <button 
              type="button" 
              className="btn-main margin-top-5" 
              data-bs-dismiss="modal"> Abbrechen
            </button>
            <button
              className='btn-main margin-top-5'
              type='button'
              onClick={handleSubmit} 
              data-bs-dismiss="modal"
              > Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

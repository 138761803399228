import { useEffect, useState } from "react";
import { UserDictProps } from "../types/user";
import { ApiAuth } from "../backend/ApiAuth";


export const useUserList = () : [userListDict: UserDictProps[],dataLoaded:boolean, setReloadUserList:(entry:boolean)=>void] => {

    const [userListDict, setUserListDict] = useState<UserDictProps[]>([]);
    const [reloadUserList, setReloadUserList] = useState(true);
    const [userDataLoaded, setUserDataLoaded] = useState(false);

    function loadUserList() {
        let userList: UserDictProps[] = [];
        ApiAuth.ListAll()
        .then(data=>{
            for(let user of data){
                userList.push({
                    id: user.id,
                    displayName: user.displayName,
                    loginName: user.loginName,
                    stateIsActve: !user.deleted && user.state.name === 'aktiv'
                })
            }
            setUserListDict(userList);
            setReloadUserList(false);
        })
        .catch(e=>{
            console.log(e);
        })
    }

    useEffect(() => {
        loadUserList();
    }, []);
    useEffect(()=>{
        if(reloadUserList){
            loadUserList();
        }
        else{
            setUserDataLoaded(true);
        }
    },[reloadUserList]);

    return [userListDict, userDataLoaded, setReloadUserList];
}


import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ApiWorkBlock } from "../../backend/ApiWorkBlock";
import { IBackendWorkBlock } from "../../backend/types/__ResponseStructures";
import { ApiWorkPackage } from "../../backend/ApiWorkPackage";
import Cookies from "js-cookie";

interface DescriptionModalProps {
    blockID: string; 
    modalID:string;
    handleChangeShortDescription: (shortDescription:string, blockID:string) => void;
    handleOnDismiss: () => void;
}

export const DescriptionModal = ({
    blockID,
    modalID,
    handleChangeShortDescription,
    handleOnDismiss
}:DescriptionModalProps) => {
    const [inputs, setInputs] = useState({projectDescription: '', shortDescription: ''});
    const [initialized, setInitialized] = useState(false);
    const [blockData, setBlockData] = useState<IBackendWorkBlock|null>(null);
    const BlockID = blockID;

    //resizing textarea
    const [textAreaHeightProjectDescription, setTextAreaHeightProjectDescription] = useState('120px');
    const textareaRefProjectDescription = useRef<HTMLTextAreaElement>(null);
    const [textAreaHeightBlockDescription, setTextAreaHeightBlockDescription] = useState('120px');
    const textareaRefBlockDescription = useRef<HTMLTextAreaElement>(null);


    const loadBlockData = () => {
        if(!BlockID){
            return;
        }
        ApiWorkBlock.Get(BlockID)
        .then(async(data)=>{
            let newInputs = {...inputs};
            newInputs.projectDescription = data.workpackage.projectDescription;
            newInputs.shortDescription = data.description;
            await setBlockData(data);
            await setInputs(newInputs);
            await setInitialized(true);
        })
        .catch((e)=>{
            console.log(e);
        });
    };

    const adjustHeight = async(id:string) => {
        let idProjectDescription = 'textareaHeightProjectDescription_'+BlockID;
        let paddingBottom = 5;
        if(id === idProjectDescription  && textareaRefProjectDescription.current){
            textareaRefProjectDescription.current.style.height = 'auto'; // Zurücksetzen, um die Scrollhöhe neu zu berechnen
            textareaRefProjectDescription.current.style.height = `${textareaRefProjectDescription.current.scrollHeight+paddingBottom}px`;
            // textareaRefProjectDescription.current.style.overflow = 'hidden';
            setTextAreaHeightProjectDescription(`${textareaRefProjectDescription.current.scrollHeight+paddingBottom}px`);
        }
        let idBlockDescription = 'textareaHeightBlockDescription_'+BlockID;
        if(id === idBlockDescription && textareaRefBlockDescription.current){
            textareaRefBlockDescription.current.style.height = 'auto'; // Zurücksetzen, um die Scrollhöhe neu zu berechnen
            textareaRefBlockDescription.current.style.height = `${textareaRefBlockDescription.current.scrollHeight +paddingBottom}px`;
            // textareaRefBlockDescription.current.style.overflow = 'hidden';
            setTextAreaHeightBlockDescription(`${textareaRefBlockDescription.current.scrollHeight +paddingBottom}px`);
        }
    };
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));

        let idProjectDescription = 'textareaHeightProjectDescription_'+BlockID;
        if(event.target.id===idProjectDescription){
            adjustHeight(idProjectDescription);
        }
        let idBlockDescription = 'textareaHeightBlockDescription_'+BlockID;
        if(event.target.id === idBlockDescription){
            adjustHeight(idBlockDescription);
        }        
    };
    const handleSubmit = async() => {
        if(!blockData) return;
        await ApiWorkBlock.EditDescription(blockData.id,inputs.shortDescription)
        .then(()=>{
            handleChangeShortDescription(inputs.shortDescription,blockData.id);
        })
        .catch(e=>{
            console.log(e)
        });
        await ApiWorkPackage.EditProjectDescription(blockData.workpackage.id,inputs.projectDescription)
        .catch(e=>{
            console.log(e)
        });
    };
    // const handleChangeTextAreaHeightBlockDescription = async() => {
    //     //check seccond textare
    //     const textareaBlock = textareaRefBlockDescription.current;
    //     if(textareaBlock){
    //         let value = String(textareaBlock.scrollHeight)+'px';
    //         if(value!==textAreaHeightBlockDescription){
    //             //set textarea height
    //             setTextAreaHeightBlockDescription(value);
    //             Cookies.set('textareaHeightBlockDescription_'+BlockID, value);
    //         }
    //     }
    // };
    // const handleChangeTextAreaHeightProjectDescription = async() => {
    //     //notify if height changed
    //     const textarea = textareaRefProjectDescription.current;
    //     if(textarea){
    //         let value = String(textarea.scrollHeight)+'px';
    //         if(value!==textAreaHeightProjectDescription){
    //             //set textarea height
    //             setTextAreaHeightProjectDescription(value);
    //             let cookieKey = 'textareaHeightProjectDescription_'+BlockID;
    //             console.log(cookieKey);
    //             Cookies.set(cookieKey, value);
    //         }
    //     };
    // };

    useEffect(() => {
        loadBlockData();
    },[]);
    // useEffect(() => {
    //     //check if textarea height changed with MutationObserver
    //     const textarea1 = textareaRefBlockDescription.current;
    //     if(!textarea1) return;

    //     const observer1 = new MutationObserver((mutations) => {
    //         mutations.forEach((mutation) => {
    //             if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
    //                 if (!isProgrammaticResizeBlock) {
    //                     handleChangeTextAreaHeightProjectDescription();
    //                 }
    //             }
    //         });
    //     });

    //     observer1.observe(textarea1, {
    //     attributes: true,
    //     attributeFilter: ['style']
    //     });

    //     return () => {
    //     observer1.disconnect();
    //     };
    // });
    // useEffect(() => {
    //     //check if textarea height changed with MutationObserver
    //     const textarea2 = textareaRefProjectDescription.current;
    //     if(!textarea2) return;

    //     const observer2 = new MutationObserver((mutations) => {
    //         mutations.forEach((mutation) => {
    //             if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
    //                 console.log(isProgrammaticResizeProject)
    //                 if (!isProgrammaticResizeProject) {
    //                     console.log('Set cookies')
    //                     handleChangeTextAreaHeightBlockDescription();
    //                 }
    //             }
    //         });
    //     });

    //     observer2.observe(textarea2, {
    //     attributes: true,
    //     attributeFilter: ['style']
    //     });

    //     return () => {
    //     observer2.disconnect();
    //     };
    // });
    useEffect(()=>{
        if(initialized){
            let idProjectDescription = 'textareaHeightProjectDescription_'+BlockID;
            adjustHeight(idProjectDescription);
            let idBlockDescription = 'textareaHeightBlockDescription_'+BlockID;
            adjustHeight(idBlockDescription);
        }
    },[initialized]);

    return(
        <>
            <div className='modal mt-4' id={modalID} tabIndex={-1} aria-hidden="true" onBlur={handleOnDismiss}>
                <>
                    <div className="modal-dialog px-4 modal-xl">
                        <div className="modal-content px-3 modal-background-lightgrey description-modal">
                            <div className="modal-header pb-0">
                                <div className="fs-3 fw-bold">Beschreibungen bearbeiten
                                    <div className="fs-5 fw-bold">Block: {blockData?.name}</div>
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                

                            </div>
                            <div className='modal-body py-0'>
                            { initialized 
                                ?   <>
                                    <div className='row margin-top-15'>
                                            <label htmlFor='projectDescription'>Projektbeschreibung</label>
                                            <textarea
                                                ref={textareaRefProjectDescription}
                                                name='projectDescription'
                                                placeholder=''
                                                id={'textareaHeightProjectDescription_'+BlockID}
                                                value={inputs.projectDescription}
                                                style={{height:textAreaHeightProjectDescription}}
                                                onChange={handleChange}
                                                className='input-lg-textarea border-bottom-primary margin-top-5'
                                            />
                                    </div>
                                    <div className='row margin-top-15'>
                                            <label htmlFor='shortDescription'>Kurzbeschreibung</label>
                                            <textarea
                                                ref={textareaRefBlockDescription}
                                                name='shortDescription'
                                                style={{height:textAreaHeightBlockDescription}}
                                                placeholder=''
                                                id={'textareaHeightBlockDescription_'+BlockID}
                                                value={inputs.shortDescription}
                                                onChange={handleChange}
                                                className='input-lg-textarea border-bottom-primary  margin-top-5'
                                            />
                                    </div>
                                    </>
                                    :   <></>
                                }
                                </div>
                            <div className='modal-footer pt-0 mt-4'>
                                <button 
                                    type="button" 
                                    className="btn-main margin-top-15" 
                                    onClick={handleOnDismiss}
                                    data-bs-dismiss="modal"> 
                                    Abbrechen
                                </button>
                                <button
                                    className='btn-main margin-top-15'
                                    type='button'
                                    onClick={handleSubmit} 
                                    data-bs-dismiss="modal"
                                > Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                        
                </>
            </div>
        </>
    )
};
import { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputField from '../../UI/InputField';
//import { enums } from '../../data';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { ApiMaterial } from '../../backend/ApiMaterial';
import Config from '../../util/Config';
import * as requestTypes from '../../backend/types/__RequestStructures';
import Loading from '../../components/Ladebalken';

const defaultMaterial = {
  id: '',
  name: '',
  type: 'Anode',
  total: '0',
  available: '0',
  orderNumber: '',
  state: 'gebraucht',
  origin: 'gekauft',
  annotation: '',
  deleted: 'false',
  //editionaly params on surface
  aufbewahrungsort: '',
  hersteller: '',
  zulieferer: '',
  bestellinformationen: '',
};

export default function NeuesMaterial() {
  // call Apis for basic data
  const location = useLocation();

  useEffect(() => {
    document.title = location.state.title;
  },[]);
  return (
    <div className='content-main-container'>
      <h1>{location.state.title}</h1>
      {<MaterialCreationForm />}
    </div>
  );
}
const MaterialCreationForm = () => {
  // call Apis for basic data
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    ...defaultMaterial
  });
  const [emptyName,setEmptyname] = useState(false);

  const setDataObject = () => {
    let dataObject: requestTypes.__MaterialUnconverted = {
      id: inputs.id,
      platform: Config.Platform,
      name: inputs.name,
      materialType: inputs.type,
      total: inputs.total.replace(',','.'),
      available: inputs.available.replace(',','.'),
      orderNumber: inputs.orderNumber,
      state: inputs.state,
      origin: inputs.origin,
      annotation: inputs.annotation,
      deleted: inputs.deleted,
      delieverer: inputs.zulieferer,
      placeOfStorage: inputs.aufbewahrungsort,
      producer: inputs.hersteller,
      orderInfo: inputs.bestellinformationen
    };
    return dataObject;
  };
  useEffect(()=>{
    if(inputs.name === ''){
      setEmptyname(true);
    }
    else{
      setEmptyname(false);
    }
  },[inputs.name]);

  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setOpenLoadingModal(true);
    event.preventDefault();
    let data = setDataObject();
   
    let requestData = JSON.parse(
      JSON.stringify(data)
    ) as requestTypes.__MaterialUnconverted;
    // create and directly forward to details send data to api
    await ApiMaterial.Create(requestData);

    navigate('/material', {
      state: {
        currentMaterialIndex: null
      },
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit}>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      <div className='row'>
        <div className='col-4'>
          <label htmlFor=''>Name</label>
          <InputField
            inputType='text'
            name='name'
            placeholder={inputs.name}
            value={inputs.name}
            changeHandler={handleChange}
            disabled={false}
            inputClass={`text-center text-md ${emptyName ? "border-bottom-error":"border-bottom-primary"} cut-text-overflow margin-top-5`}
          />
          {emptyName ?
                  <p className = 'text-err ps-1 margin-bottom-5'>Name darf nicht leer sein.</p> : 
                  <></>
          }
        </div>
        <div className='col-4'>
          <label htmlFor=''>Typ</label>
          <div style={{ visibility: 'hidden', height: 0 }}>
            <InputField
              inputType='text'
              name='aufbewahrungsort'
              placeholder={inputs.aufbewahrungsort}
              value={inputs.aufbewahrungsort}
              changeHandler={handleChange}
              disabled={false}
              inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
            />
          </div>
          <select
            name='type'
            value={inputs.type}
            onChange={(event) => handleOptionChange(event)}
            className='input-lg text-center text-md border-bottom-primary  margin-top-5'
          >
            {enums.MaterialType.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className='col-4'>
          <label htmlFor=''>Zustand</label>
          <div style={{ visibility: 'hidden', height: 0 }}>
            <InputField
              inputType='text'
              name='state'
              placeholder={inputs.state}
              value={inputs.state}
              changeHandler={handleChange}
              disabled={false}
              inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
            />
          </div>
          <select
            name='state'
            value={inputs.state}
            onChange={(event) => handleOptionChange(event)}
            className='input-lg text-center text-md border-bottom-primary  margin-top-5'
          >
            {enums.MaterialState.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='row margin-top-5'>
        <div className='col-4'>
          <label htmlFor=''>Gesamtmenge</label>
          <InputField
            inputType='number'
            name='total'
            min='0'
            placeholder={inputs.total}
            value={inputs.total}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
        <div className='col-4'>
          <label htmlFor=''>Vorhanden</label>
          <InputField
            inputType='number'
            name='available'
            min='0'
            placeholder={inputs.available}
            value={inputs.available}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
        <div className='col-4'>
          <label htmlFor=''>Aufbewahrungsort</label>
          <InputField
            inputType='text'
            name='aufbewahrungsort'
            placeholder={inputs.aufbewahrungsort}
            value={inputs.aufbewahrungsort}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
      </div>

      <div className='row margin-top-5'>
        <div className='col-4'>
          <label htmlFor=''>Herkunft</label>
          <div style={{ visibility: 'hidden', height: 0 }}>
            <InputField
              inputType='text'
              name='origin'
              placeholder={inputs.origin}
              value={inputs.origin}
              changeHandler={handleChange}
              disabled={false}
              inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
            />
          </div>
          <select
            name='origin'
            value={inputs.origin}
            onChange={(event) => handleOptionChange(event)}
            className='input-lg text-center text-md border-bottom-primary  margin-top-5'
          >
            {enums.MaterialOrigin.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className='col-4'>
          <label htmlFor=''>Hersteller</label>
          <InputField
            inputType='text'
            name='hersteller'
            placeholder={inputs.hersteller}
            value={inputs.hersteller}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
        <div className='col-4'>
          <label htmlFor=''>Zulieferer</label>
          <InputField
            inputType='text'
            name='zulieferer'
            placeholder={inputs.zulieferer}
            value={inputs.zulieferer}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
      </div>
      <div className='row margin-top-5'>
        <div className='col-4'>
          <label htmlFor=''>Bestellnummer</label>
          <InputField
            inputType='text'
            name='orderNumber'
            placeholder={inputs.orderNumber}
            value={inputs.orderNumber}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
        <div className='col-4'>
          <label htmlFor=''>Bestellinformationen</label>
          <InputField
            inputType='text'
            name='bestellinformationen'
            placeholder={inputs.bestellinformationen}
            value={inputs.bestellinformationen}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
      </div>
      <div className='row margin-top-5'>
        <div className='col-8'>
          <label htmlFor='beschreibung'>Beschreibung</label>
          <textarea
            className='input-lg-textarea text border-bottom-primary cut-text-overflow margin-top-5'
            name='annotation'
            id='annotation'
            placeholder={inputs.annotation}
            value={inputs.annotation}
            onChange={handleInputChange}
          ></textarea>
        </div>
      </div>
      <div className='mt-5'>
        <input
          type='button'
          value='Zurück'
          onClick={()=>navigate(-1)}
          className='btn-main text-center text-md padding-5 margin-top-10 margin-right-15'
        />
        <input
          type='submit'
          value='Speichern'
          disabled={inputs.name === ''}
          className='btn-main text-center text-md padding-5 margin-top-10'
        />
      </div>
    </form>
  );
};
export { MaterialCreationForm };

import { faCheck, faCirclePlus, faFilePen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { ApiWorkPackage } from "../../backend/ApiWorkPackage";
import { __PlannedStepsConverted, __WorkBlockResponseList } from "../../backend/types/__GeneralStructures";
import { useNavigate } from "react-router-dom";
import { IBackendMaterialGet, IBackendWorkPackageList} from "../../backend/types/__ResponseStructures";
import { ApiAnodeConstruction } from "../../backend/ApiAnodeConstruction";
import { ApiMaterial } from "../../backend/ApiMaterial";
import { ApiAnodeConstructionR2R } from "../../backend/ApiAnodeConstructionR2R";
import { ApiBatteryConstruction } from "../../backend/ApiBatteryConstruction";
import { ApiBackendData } from "../../backend/ApiBackendData";
import { ApiWorkBlock } from "../../backend/ApiWorkBlock";
import { ApiBatteryTest } from "../../backend/ApiBatteryTest";
import { TableSettingsFullTable, filterSelectProps} from "../../components/TableSettingsFullTable";
import Cookies from 'js-cookie';
import { DescriptionModal } from "./DescriptionModal";

interface stepProps{
    id: string | null;
    date: Date;
    state:string;
};

export interface FullTableProps {
    priorityOfPackage: number;
    nameOfPackage: string;
    workpackageData: string[];
    stateOfPackage: string;
    dateOfWorkpackage: Date;
    stateOfBlock: string;
    summary:boolean;
    workblockData: __WorkBlockResponseList;
    nameOfBlock: string;
    responsibleUser: string;
    shortDescriptionOfBlock: string;
    anode: {
        name: string;
        state: string;
        date: Date;
        id: string | null;
        type: 's2s' | 'r2r'
    }| null,
    batteryConstruction: {
        vz: stepProps | null;
        hz: stepProps | null;
        pz: stepProps | null
    }| null;
    batteryTest: {
        vz: stepProps | null;
        hz: stepProps | null;
        pz: stepProps | null
    }| null;
    characteristics:{
        id: string;
        date: Date;
        state: string;
        hafttest: stepProps | null;
        microscopy: stepProps | null;
        rem: stepProps | null;
        xrd: stepProps | null;
        raman:stepProps | null;
        postMortem: stepProps | null;
    }| null;
    stateOfRelease: boolean;
};
/**
   * Charakterisierung
   * Umbenennungen:
      Hafttest -> Sonstiges
      Post Mortem -> PM Foto
      Raman -> PM REM
      Mikroskopie -> LiMi
  */

export default function GesamtUebersicht() {
    const navigate = useNavigate();

    const [inputs, setInputs] = useState<FullTableProps[]>([]);
    const [showInvalidStates, setShowInvalidStates] = useState(Cookies.get('tablefull_settings_ ShowLockedSteps')==='true');
    const [numberOfValidPackages, setNumberOfValidPackages] = useState(1);
    const [showInvalidStatesOfPackages,setShowInvalidStatesPackages] = useState(Cookies.get('tablefull_settings_ ShowLockedBlocksAndPackages')==='true');
    const [sorted, setSorted] = useState(false);
    const [selectedPrioRow, setSelectedPrioRow] = useState<number|null>(null);//index
    const [selectedPriority, setSelectedPriority] = useState<string>('');//value
    const scrollRef = useRef<HTMLTableRowElement>(null);

    const [selectedFilter, setSelectedFilter] = useState<filterSelectProps>(
        Cookies.get('tableFullSettings_filter') !== undefined
        ?   JSON.parse(Cookies.get('tableFullSettings_filter')?? '') as filterSelectProps
        :   {
                anodenbautyp: [],
                batteriebautype: [],
                batterietest: [],
                characterisierung: [],
                confirmationStateOfPackage: [],
                state: []
            }
    );
    const [searchFilter, setSearchFilter] = useState<string>(Cookies.get('tableFullSettings_searchValue') ??'');
    const [initialized, setInitialization] = useState(false);
    const [inputsTable, setInputsTable] = useState<any>([]);
    const [sortBy, setSortBy] = useState<string>(Cookies.get('tableFullSettings_sort') ??'prio');

    const [materials, setMaterials] = useState<IBackendMaterialGet[]>([]);
    const [loadMaterialFinished, setLoadMaterialFinished] = useState(false);
    const [modalBlockID, setModalBlockID] = useState('');
    const [modalLoaded, setModalLoaded] = useState(false);

    let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
    let halbzellenList = ['Halbzelle'];
    let vollzellenList = enums.CellType.filter(x=> x!== 'Halbzelle');

    /**
     * Table
     */
    const loadData = async() => {
        let newTableData: FullTableProps[] = []
        let allPackages = await ApiWorkPackage.ListAll();

        let allOpenPackages = allPackages.filter(x => x.state.name === 'aktiv' || x.state.name === 'inPlanung');
        let allClosedPackages = allPackages.filter(x => x.state.name !== 'aktiv' && x.state.name !== 'inPlanung');

        setNumberOfValidPackages(allOpenPackages.length);
        //all closed or deleted packages have prio 0 -> has to come at the end
        allOpenPackages = allOpenPackages.sort((a,b) => a.priority - b.priority);
        //sort closed packages by date
        allClosedPackages = allClosedPackages.sort((a,b) => {
                //sort by name reverse
                if(a.name.toLowerCase() > b.name.toLowerCase()){
                    return -1;
                }
                if(a.name.toLowerCase() < b.name.toLowerCase()){
                    return 1;
                }
                else{
                    return 0;
            }});

        //concat active packages with closed ones
        allPackages = [...allOpenPackages,...allClosedPackages];
        //packages
        for(let onePackage of allPackages){
            if((onePackage.state.name === 'gesperrt' || onePackage.state.name === 'gelöscht') && !showInvalidStatesOfPackages){
                    continue;
                }
            //blocks
            for(let block of onePackage.workBlocks){
                if((block.stateID === 'gesperrt' || block.stateID === 'gelöscht') && !showInvalidStatesOfPackages){
                    continue;
                }
                let steps: __PlannedStepsConverted = JSON.parse(block.plannedSteps) as __PlannedStepsConverted;
                /**
                 * Data For Batteries
                 */
                let batteryConstVZ: stepProps | null = null; 
                let batteryConstHZ: stepProps | null = null; 
                let batteryConstPZ: stepProps | null = null; 
                let batteryTestVZ: stepProps | null = null; 
                let batteryTestHZ: stepProps | null = null; 
                let batteryTestPZ: stepProps | null = null; 

                //boolean to check deleted/ locked battterietest and constructions
                let invalidBatteryConstructionVZ = false;
                let invalidBatteryConstructionHZ = false;
                let invalidBatteryTestVZ = false;
                let invalidBatteryTestHZ = false;


                //find kind of batteryconstruction if it is already created
                for(let item of block.batteryConstruction){
                    if(item.fixedBatteryData){
                        if((!showInvalidStates && item.state.name !== 'gelöscht' && item.state.name !== 'gesperrt') || showInvalidStates){
                            if(item.fixedBatteryData.celltype.name === 'Halbzelle'){
                                batteryConstHZ = {
                                    id: item.id,
                                    date: item.date,
                                    state: item.state.name,
                                }
                            }
                            // else if(item.fixedBatteryData.celltype.name === 'Pouchzelle'){
                            //     batteryConstPZ = {
                            //         id: item.id,
                            //         date: item.date,
                            //         state: item.state.name,
                            //     }
                            // }
                            else{
                                batteryConstVZ = {
                                    id: item.id,
                                    date: item.date,
                                    state: item.state.name,
                                }
                            }
                        }
                        else{
                            if(!showInvalidStates && item.state.name === 'gelöscht' || item.state.name === 'gesperrt'){
                                if(item.fixedBatteryData.celltype.name  === 'Halbzelle'){
                                    invalidBatteryConstructionHZ = true;
                                }
                                else{
                                    invalidBatteryConstructionVZ = true;
                                }
                            }
                        }
                    }
                };
                if (!batteryConstHZ && steps.batteryConstruction?.HZ && ! invalidBatteryConstructionHZ){
                    // check if construction is not created yet, but planned
                    if(steps.batteryConstruction?.HZ){
                        batteryConstHZ = {
                            id: null,
                            state: 'inPlanung',
                            date: new Date(),
                        }
                    }
                }
                if (!batteryConstVZ && steps.batteryConstruction?.VZ && !invalidBatteryConstructionVZ){
                    if(steps.batteryConstruction?.VZ){
                        batteryConstVZ = {
                            id: null,
                            state: 'inPlanung',
                            date: new Date(),
                        }
                    }
                }
                //find kind of batterytest if it is already created
                for(let item of block.batteryTest){
                    if((!showInvalidStates && item.state.name !== 'gelöscht' && item.state.name !== 'gesperrt') || showInvalidStates){
                        if(item.batteryTestWishes && item.batteryTestWishes.length > 0){
                            if(item.batteryTestWishes[0].type.name === 'Halbzelle'){
                                batteryTestHZ = {
                                    id: item.id,
                                    date: item.date,
                                    state: item.state.name,
                                }
                            }
                            // else if(item.batteryTestWishes[0].type.name === 'Pouchzelle'){
                            //     batteryTestPZ = {
                            //         id: item.id,
                            //         date: item.date,
                            //         state: item.state.name,
                            //     }
                            // }
                            else{
                                batteryTestVZ = {
                                    id: item.id,
                                    date: item.date,
                                    state: item.state.name,
                                }
                            }
                        }
                    }
                    else{
                        if((!showInvalidStates && item.state.name === 'gelöscht' || item.state.name === 'gesperrt') && item.batteryTestWishes && item.batteryTestWishes.length > 0){
                            if(item.batteryTestWishes[0].type.name === 'Halbzelle'){
                                invalidBatteryTestHZ = true;
                            }
                            else{
                                invalidBatteryTestVZ = true;
                            }
                        }
                    }
                };
                if(!batteryTestHZ && steps.batteryTest?.HZ && !invalidBatteryTestHZ){
                    // check if test is not created yet, but planned
                    if(steps.batteryTest?.HZ){
                        batteryTestHZ = {
                            id: null,
                            state: 'inPlanung',
                            date: new Date(),
                        }
                    }
                }
                if(!batteryTestVZ && steps.batteryTest?.VZ && !invalidBatteryTestVZ){
                    if(steps.batteryTest?.VZ){
                        batteryTestVZ = {
                            id: null,
                            state: 'inPlanung',
                            date: new Date(),
                        }
                    }
                }

                

                /**
                 * Data For Table
                 */

                let tableData: FullTableProps = {
                    priorityOfPackage: onePackage.priority,
                    nameOfPackage: onePackage.name,
                    stateOfPackage: onePackage.state.name,
                    workpackageData: [onePackage.id, onePackage.name, onePackage.responsibleUser[0].displayName,onePackage.name, onePackage.state.name, onePackage.locationOfSynthesis.name],
                    dateOfWorkpackage: new Date(onePackage.date),
                    stateOfBlock: block.stateID,
                    workblockData: block,
                    nameOfBlock: block.name,
                    summary: onePackage.summary ? true : false,
                    stateOfRelease: onePackage.state.name !== 'inPlanung',
                    shortDescriptionOfBlock: block.description,
                    responsibleUser: block.responsibleUser[0].displayName,
                    anode: block.anodeConstruction !== null || block.anodeConstructionR2R !== null || steps.anodeConstructionSheetToSheet !== null || steps.anodeConstructionR2R !== null 
                        ? block.anodeConstruction || block.anodeConstructionR2R
                            ? block.anodeConstruction
                                ?   (!showInvalidStates && block.anodeConstruction.state.name !== 'gelöscht' && block.anodeConstruction.state.name !== 'gesperrt') || showInvalidStates
                                    ?   {
                                            id: block.anodeConstruction.id,
                                            state: block.anodeConstruction.state.name,
                                            date: new Date(block.anodeConstruction.date),
                                            type: 's2s',
                                            name: block.anodeConstruction.anode?.name ?? "neu"
                                        }
                                    :   null
                                : 
                                        (!showInvalidStates && block.anodeConstructionR2R.state.name !== 'gelöscht' && block.anodeConstructionR2R.state.name !== 'gesperrt') || showInvalidStates
                                        ?    {
                                                id: block.anodeConstructionR2R.id,
                                                state: block.anodeConstructionR2R.state.name,
                                                date: new Date(block.anodeConstructionR2R.date),
                                                type: 'r2r',
                                                name: block.anodeConstructionR2R.anode?.name ?? "neu"
                                            }
                                        : null
                            : steps.anodeConstructionSheetToSheet
                                ? {
                                    id: null,
                                    name: 'neu',
                                    state: 'inPlanung',
                                    date: new Date(),
                                    type: 's2s'
                                }
                                : {
                                    id: null,
                                    name: 'neu',
                                    state: 'inPlanung',
                                    date: new Date(),
                                    type: 'r2r'
                                }
                        : null,
                    batteryConstruction: block.batteryConstruction.length > 0 || steps.batteryConstruction !== null 
                        ?   {
                            vz: batteryConstVZ,
                            hz: batteryConstHZ,
                            pz: batteryConstPZ
                        }
                        : null,
                    batteryTest: block.batteryTest.length > 0 || steps.batteryTest !== null 
                    ?   {
                        vz: batteryTestVZ,
                        hz: batteryTestHZ,
                        pz: batteryTestPZ
                    }
                    : null,
                    characteristics: block.characteristics 
                        ?   {
                                id: block.characteristics.id,
                                date: block.characteristics.date,
                                state: block.characteristics.state.name,
                                hafttest: block.characteristics.adhesionTest !== null || steps.analysis?.adhesionTest 
                                    ?   block.characteristics.adhesionTest
                                        ?   (!showInvalidStates && block.characteristics.adhesionTest.state?.name !== 'gelöscht' && block.characteristics.adhesionTest.state?.name !== 'gesperrt') || showInvalidStates
                                            ?   {
                                                    id: block.characteristics.adhesionTest.id,
                                                    date: block.characteristics.adhesionTest.date,
                                                    state: block.characteristics.adhesionTest.state?.name ?? 'inPlanung',
                                                }
                                            : null
                                        :   {
                                                id: null,
                                                date: new Date(),
                                                state: 'inPlanung',
                                            }
                                    : null,
                                microscopy: block.characteristics.microscopy !== null || steps.analysis?.microscopy 
                                    ?   block.characteristics.microscopy
                                        ?   (!showInvalidStates && block.characteristics.microscopy.state?.name !== 'gelöscht' && block.characteristics.microscopy.state?.name !== 'gesperrt') || showInvalidStates
                                            ?   {
                                                id: block.characteristics.microscopy.id,
                                                date: block.characteristics.microscopy.date,
                                                state: block.characteristics.microscopy.state?.name ?? 'inPlanung',
                                            }
                                            : null
                                        :   {
                                                id: null,
                                                date: new Date(),
                                                state: 'inPlanung',
                                            }
                                    : null,
                                rem: block.characteristics.rem !== null || steps.analysis?.rem 
                                    ?   block.characteristics.rem
                                        ?   (!showInvalidStates && block.characteristics.rem.state?.name !== 'gelöscht' && block.characteristics.rem.state?.name !== 'gesperrt') || showInvalidStates
                                            ?   {
                                                id: block.characteristics.rem.id,
                                                date: block.characteristics.rem.date,
                                                state: block.characteristics.rem.state?.name ?? 'inPlanung',
                                            }
                                            : null
                                        :   {
                                                id: null,
                                                date: new Date(),
                                                state: 'inPlanung',
                                            }
                                    : null,
                                xrd: block.characteristics.xrd !== null || steps.analysis?.xrd 
                                    ?   block.characteristics.xrd
                                        ?   (!showInvalidStates && block.characteristics.xrd.state?.name !== 'gelöscht' && block.characteristics.xrd.state?.name !== 'gesperrt') || showInvalidStates
                                            ?   {
                                                id: block.characteristics.xrd.id,
                                                date: block.characteristics.xrd.date,
                                                state: block.characteristics.xrd.state?.name ?? 'inPlanung',
                                            }
                                            : null
                                        :   {
                                                id: null,
                                                date: new Date(),
                                                state: 'inPlanung',
                                            }
                                    : null,
                                raman: block.characteristics.raman !== null || steps.analysis?.raman 
                                    ?   block.characteristics.raman
                                        ?   (!showInvalidStates && block.characteristics.raman.state?.name !== 'gelöscht' && block.characteristics.raman.state?.name !== 'gesperrt') || showInvalidStates
                                            ?   {
                                                id: block.characteristics.raman.id,
                                                date: block.characteristics.raman.date,
                                                state: block.characteristics.raman.state?.name ?? 'inPlanung',
                                            }
                                            : null
                                        :   {
                                                id: null,
                                                date: new Date(),
                                                state: 'inPlanung',
                                            }
                                    : null,
                                postMortem:  block.characteristics.postMortemAnalysis !== null || steps.analysis?.postMortemAnalysis 
                                    ?   block.characteristics.postMortemAnalysis
                                        ?   (!showInvalidStates && block.characteristics.postMortemAnalysis.state?.name !== 'gelöscht' && block.characteristics.postMortemAnalysis.state?.name !== 'gesperrt') || showInvalidStates
                                            ?   {
                                                id: block.characteristics.postMortemAnalysis.id,
                                                date: block.characteristics.postMortemAnalysis.date,
                                                state: block.characteristics.postMortemAnalysis.state?.name ?? 'inPlanung',
                                            }
                                            : null
                                        :   {
                                                id: null,
                                                date: new Date(),
                                                state: 'inPlanung',
                                            }
                                    : null
                            }
                        : null
                }
                newTableData.push(tableData);
            }
        }
        
        await filterTableData(newTableData);
    };
    const loadInitialData = async() => {       
        await ApiMaterial.ListAll().then((data) => {
            let newMaterials = data.filter(x =>  x.state.name !== 'gesperrt' && !x.deleted);
            setMaterials(newMaterials);
            setLoadMaterialFinished(true);
        });
    };
    
    const tableHeadersList = ['Prio','Paket','Block','Verantwortlicher','Kurzbeschreibung','Anode','Batteriebau','Batterietest','Charakterisierung','Zsf.']

    const tableHeaders = (
        <tr>
           {tableHeadersList.map((item) => (
            <th>{item}</th>
           ))}
        </tr>
    );
    const createTable = async () => {
        let sortedInputs =[...inputs];
        let tableData = sortedInputs.map((object,index) => {
            return (
                <tr key={index} ref={Cookies.get('tableFull_scrollPosition')===object.workblockData.id ? scrollRef : undefined} className={
                    showInvalidStatesOfPackages && (object.stateOfPackage === 'gesperrt' || object.stateOfPackage === 'gelöscht')
                    ? 'table-row-invalid-state'
                    : !object.stateOfRelease 
                        ? 'table-row-red' 
                        : ''}>
                    <td style={{minWidth: '120px'}}>
                        { index === selectedPrioRow && (object.stateOfPackage === 'aktiv' || object.stateOfPackage === 'inPlanung' )
                            ?   <div className="input-group mb-3">
                                    <input 
                                        type="number" 
                                        min={1}
                                        max={numberOfValidPackages}
                                        className="form-control" 
                                        value={selectedPriority} 
                                        onChange={async(e) => await setSelectedPriority(e.target.value)}
                                        aria-label="priorityOfPackage" 
                                        aria-describedby="prioButton"
                                    />
                                    <span 
                                        className="input-group-text border border-start-0 rounded-end p-1" 
                                        id="prioButton">
                                        <button
                                            className="btn text-success border-0 p-1"
                                            onClick={e => handleChangePriority(object.workpackageData[0])}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                size="xs"
                                            />
                                        </button>
                                    </span>
                                </div>
                            :   <div onClick={(e) => handleClick(index)}
                                >
                                    {object.priorityOfPackage !== 0 || (object.stateOfPackage === 'aktiv' || object.stateOfPackage === 'inPlanung') ? object.priorityOfPackage : ''}
                                </div>
                        }
                    </td>
                    <td >
                        <button className={`btn text-dark ${
                            object.stateOfPackage === 'gesperrt' || object.stateOfPackage === 'gelöscht'
                            ? ' text-dark btn-invalid-state'
                            :   !object.stateOfRelease 
                                    ? 'btn-planned'
                                    : object.stateOfPackage === 'aktiv'
                                        ? 'btn-processing'
                                        : object.stateOfPackage === 'abgeschlossen'
                                            ? 'btn-done'
                                            : 'btn-unstarted'
                            }`}
                            onClick={() => handleNavigate(object.workblockData.id,`/arbeitspakete/details/#`+object.nameOfBlock, object.workpackageData, 'arbeitspaket')}
                            >
                            {object.nameOfPackage}
                        </button>
                    </td>
                    <td >
                        <button className={`btn text-dark ${
                            object.stateOfBlock === 'gesperrt' || object.stateOfBlock === 'gelöscht'
                            ? ' text-dark btn-invalid-state'
                            :   !object.stateOfRelease
                                    ? 'btn-planned'
                                    : object.stateOfBlock === 'aktiv'
                                        ? 'btn-processing'
                                        : object.stateOfBlock === 'abgeschlossen'
                                            ? 'btn-done'
                                            : 'btn-unstarted'
                            }`}
                            onClick={() => handleOpenModal(object.workblockData.id)}
                            >
                            {object.nameOfBlock}
                        </button>

                    </td>
                
                    <td style={{minWidth: '150px'}} >{object.responsibleUser}</td>
                    <td style={{minWidth: '300px'}} > <p>{object.shortDescriptionOfBlock}</p></td>
                    <td style={{minWidth: '150px'}}>
                        {object.anode !== null 
                            ?  object.anode.state !== 'gelöscht' && object.anode.state !== 'gesperrt'
                                ? <>
                                    <button className={`btn text-dark ${
                                        !object.stateOfRelease 
                                            ? 'btn-planned'
                                            : object.anode.state === 'aktiv'
                                                ? 'btn-processing'
                                                : object.anode.state === 'abgeschlossen'
                                                    ? 'btn-done'
                                                    : 'btn-unstarted'
                                        }`}
                                        onClick={() => handleNavigate(object.workblockData.id,object.anode?.type=== 's2s' ? '/anodenbau/sheetToSheet' : '/anodenbau/rollToRoll', object.anode?.id, object.anode?.type === 's2s' ? 'anodeS2S' : 'anodeR2R', object.workblockData.id)}
                                        >
                                        {object.anode.name}
                                    </button>    
                                    <p>Typ: {object.anode.type}</p>
                                </>
                                : <>
                                    <button className={'btn text-dark btn-invalid-state'}
                                        onClick={() => handleNavigate(object.workblockData.id,object.anode?.type=== 's2s' ? '/anodenbau/sheetToSheet' : '/anodenbau/rollToRoll', object.anode?.id, object.anode?.type === 's2s' ? 'anodeS2S' : 'anodeR2R', object.workblockData.id)}
                                        >
                                        {object.anode.name}
                                    </button>    
                                    <p>Typ: {object.anode.type}</p>
                                </>
                            : <></>
                        }
                    </td>
                    <td style={{minWidth: '150px'}}>
                        {object.batteryConstruction 
                            ?   <>
                                    {object.batteryConstruction.vz
                                        ?  object.batteryConstruction.vz.state !== 'gelöscht' && object.batteryConstruction.vz.state !== 'gesperrt'
                                            ? <>
                                                <button className={`btn text-dark ${
                                                    !object.stateOfRelease 
                                                        ? 'btn-planned'
                                                        : object.batteryConstruction.vz.state === 'aktiv'
                                                            ? 'btn-processing'
                                                            : object.batteryConstruction.vz.state === 'abgeschlossen'
                                                                ? 'btn-done'
                                                                : 'btn-unstarted'
                                                    }`}
                                                    onClick={() => handleNavigate(object.workblockData.id,object.batteryConstruction?.vz?.id !== null ? '/batteriebau/details' : '/batteriebau/neu', object.batteryConstruction?.vz?.id, 'batteriebau',object.workblockData.id, 'VZ' )}
                                                    >
                                                    VZ
                                                </button>  
                                             </>
                                            : <>
                                                <button className={'btn text-dark btn-invalid-state'}
                                                    onClick={() => handleNavigate(object.workblockData.id,object.batteryConstruction?.vz?.id !== null ? '/batteriebau/details' : '/batteriebau/neu', object.batteryConstruction?.vz?.id, 'batteriebau',object.workblockData.id, 'VZ' )}
                                                    >
                                                    VZ
                                                </button>    
                                            </>
                                        : <></>
                                    }
                                    {object.batteryConstruction.hz
                                        ?  object.batteryConstruction.hz.state !== 'gelöscht' && object.batteryConstruction.hz.state !== 'gesperrt'
                                            ? <>
                                               <button className={`btn text-dark ${
                                                    !object.stateOfRelease 
                                                        ? 'btn-planned'
                                                        : object.batteryConstruction.hz.state === 'aktiv'
                                                            ? 'btn-processing'
                                                            : object.batteryConstruction.hz.state === 'abgeschlossen'
                                                                ? 'btn-done'
                                                                : 'btn-unstarted'
                                                    }`}
                                                    onClick={() => handleNavigate(object.workblockData.id,object.batteryConstruction?.hz?.id !== null ? '/batteriebau/details' : '/batteriebau/neu', object.batteryConstruction?.hz?.id, 'batteriebau',object.workblockData.id, 'HZ' )}
                                                    >
                                                    HZ
                                                </button>  
                                            </>
                                            : <>
                                                <button className={'btn text-dark btn-invalid-state'}
                                                    onClick={() => handleNavigate(object.workblockData.id,object.batteryConstruction?.hz?.id !== null ? '/batteriebau/details' : '/batteriebau/neu', object.batteryConstruction?.hz?.id, 'batteriebau',object.workblockData.id, 'HZ' )}
                                                    >
                                                    HZ
                                                </button>    
                                            </>
                                        : <></>
                                    }
                                    {/* {object.batteryConstruction.pz
                                        ?  object.batteryConstruction.pz.state !== 'gelöscht' && object.batteryConstruction.pz.state !== 'gesperrt'
                                            ? <>
                                               <button className={`btn text-dark ${
                                                !object.stateOfRelease
                                                    ? 'btn-planned'
                                                    : object.batteryConstruction.pz.state === 'aktiv'
                                                        ? 'btn-processing'
                                                        : object.batteryConstruction.pz.state === 'abgeschlossen'
                                                            ? 'btn-done'
                                                            : 'btn-unstarted'
                                                }`}
                                                onClick={() => handleNavigate(object.workblockData.id,object.batteryConstruction?.pz?.id !== null ? '/batteriebau/details' : '/batteriebau/neu', object.batteryConstruction?.pz?.id, 'batteriebau',object.workblockData.id, 'PZ' )}
                                                >
                                                PZ
                                            </button>  
                                         </>
                                            : <>
                                                <button className={'btn text-dark btn-invalid-state'}
                                                    onClick={() => handleNavigate(object.workblockData.id,object.batteryConstruction?.pz?.id !== null ? '/batteriebau/details' : '/batteriebau/neu', object.batteryConstruction?.pz?.id, 'batteriebau',object.workblockData.id, 'PZ' )}
                                                >
                                                PZ
                                            </button>     
                                            </>
                                        : <></>
                                    } */}
                                </>
                            : <></>
                        }
                    </td>
                    <td style={{minWidth: '150px'}}>
                    {object.batteryTest 
                        ?   <>
                                {object.batteryTest.vz
                                    ?  object.batteryTest.vz.state !== 'gelöscht' && object.batteryTest.vz.state !== 'gesperrt'
                                        ? <>
                                            <button className={`btn text-dark ${
                                                !object.stateOfRelease 
                                                    ? 'btn-planned'
                                                    : object.batteryTest.vz.state === 'aktiv'
                                                        ? 'btn-processing'
                                                        : object.batteryTest.vz.state === 'abgeschlossen'
                                                            ? 'btn-done'
                                                            : 'btn-unstarted'
                                                }`}
                                                onClick={() => handleNavigate(object.workblockData.id,object.batteryTest?.vz?.id !== null ? '/batterietest/details' : '/batterietest/neu', object.batteryTest?.vz?.id, 'batterietest',object.workblockData.id, 'VZ' )}
                                                >
                                                VZ
                                            </button>  
                                        </>
                                        : <>
                                            <button className={'btn text-dark btn-invalid-state'}
                                                onClick={() => handleNavigate(object.workblockData.id,object.batteryTest?.vz?.id !== null ? '/batterietest/details' : '/batterietest/neu', object.batteryTest?.vz?.id, 'batterietest',object.workblockData.id, 'VZ' )}
                                                >
                                                VZ
                                            </button>      
                                        </>
                                    : <></>
                                }
                                {object.batteryTest.hz
                                    ?   object.batteryTest.hz.state !== 'gelöscht' && object.batteryTest.hz.state !== 'gesperrt'
                                        ? <>
                                            <button className={`btn text-dark ${
                                            !object.stateOfRelease
                                                ? 'btn-planned'
                                                : object.batteryTest.hz.state === 'aktiv'
                                                    ? 'btn-processing'
                                                    : object.batteryTest.hz.state === 'abgeschlossen'
                                                        ? 'btn-done'
                                                        : 'btn-unstarted'
                                            }`}
                                            onClick={() => handleNavigate(object.workblockData.id,object.batteryTest?.hz?.id !== null ? '/batterietest/details' : '/batterietest/neu', object.batteryTest?.hz?.id, 'batterietest',object.workblockData.id, 'HZ' )}
                                            >
                                            HZ
                                        </button>  
                                    </>
                                        : <>
                                            <button className={'btn text-dark btn-invalid-state'}
                                                onClick={() => handleNavigate(object.workblockData.id,object.batteryTest?.hz?.id !== null ? '/batterietest/details' : '/batterietest/neu', object.batteryTest?.hz?.id, 'batterietest',object.workblockData.id, 'HZ' )}
                                                >
                                                HZ
                                            </button>      
                                        </>
                                    : <></>
                                }
                                {/* {object.batteryTest.pz
                                    ?   object.batteryTest.pz.state !== 'gelöscht' && object.batteryTest.pz.state !== 'gesperrt'
                                        ? <>
                                            <button className={`btn text-dark ${
                                            !object.stateOfRelease 
                                                ? 'btn-planned'
                                                : object.batteryTest.pz.state === 'aktiv'
                                                    ? 'btn-processing'
                                                    : object.batteryTest.pz.state === 'abgeschlossen'
                                                        ? 'btn-done'
                                                        : 'btn-unstarted'
                                            }`}
                                            onClick={() => handleNavigate(object.workblockData.id,object.batteryTest?.pz?.id !== null ? '/batterietest/details' : '/batterietest/neu', object.batteryTest?.pz?.id, 'batterietest',object.workblockData.id, 'PZ' )}
                                            >
                                            PZ
                                        </button>  
                                    </>
                                        : <>
                                            <button className={'btn text-dark btn-invalid-state'}
                                                onClick={() => handleNavigate(object.workblockData.id,object.batteryTest?.pz?.id !== null ? '/batterietest/details' : '/batterietest/neu', object.batteryTest?.pz?.id, 'batterietest',object.workblockData.id, 'PZ' )}
                                                >
                                                PZ
                                            </button>      
                                        </>
                                    : <></>
                                } */}
                            </>
                        : <></>
                    }
                    </td>
                    <td style={{minWidth: '220px'}}>
                    {  object.characteristics 
                        ?   <>
                                {object.characteristics.microscopy
                                    ?   object.characteristics.microscopy.state !== 'gelöscht' && object.characteristics.microscopy.state !== 'gesperrt'
                                        ? <>
                                            <button className={`btn text-dark ${
                                                !object.stateOfRelease 
                                                    ? 'btn-planned'
                                                    : object.characteristics.microscopy.state === 'aktiv'
                                                        ? 'btn-processing'
                                                        : object.characteristics.microscopy.state === 'abgeschlossen'
                                                            ? 'btn-done'
                                                            : 'btn-unstarted'
                                                }`}
                                                onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/mikroskopie', object.characteristics?.microscopy?.id, 'charakteristik',object.workblockData.id, 'mikroskopie')}
                                                >
                                                LiMi
                                            </button>  
                                        </>
                                        : <>
                                            <button className={'btn text-dark btn-invalid-state'}
                                                onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/mikroskopie', object.characteristics?.microscopy?.id, 'charakteristik',object.workblockData.id, 'mikroskopie')}
                                                >
                                                LiMi
                                            </button>      
                                        </>
                                    : <></>
                                }
                                {object.characteristics.rem
                                    ?   object.characteristics.rem.state !== 'gelöscht' && object.characteristics.rem.state !== 'gesperrt'
                                        ? <>
                                            <button className={`btn text-dark ${
                                            !object.stateOfRelease  
                                                ? 'btn-planned'
                                                : object.characteristics.rem.state === 'aktiv'
                                                    ? 'btn-processing'
                                                    : object.characteristics.rem.state === 'abgeschlossen'
                                                        ? 'btn-done'
                                                        : 'btn-unstarted'
                                            }`}
                                            onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/rem', object.characteristics?.rem?.id, 'charakteristik',object.workblockData.id, 'rem')}
                                            >
                                            REM
                                        </button>  
                                    </>
                                        : <>
                                            <button className={'btn text-dark btn-invalid-state'}
                                                onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/rem', object.characteristics?.rem?.id, 'charakteristik',object.workblockData.id, 'rem')}
                                                >
                                                REM
                                            </button>     
                                        </>
                                    : <></>
                                }
                                {object.characteristics.xrd
                                    ?   object.characteristics.xrd.state !== 'gelöscht' && object.characteristics.xrd.state !== 'gesperrt'
                                        ? <>
                                            <button className={`btn text-dark ${
                                                !object.stateOfRelease 
                                                    ? 'btn-planned'
                                                    : object.characteristics.xrd.state === 'aktiv'
                                                        ? 'btn-processing'
                                                        : object.characteristics.xrd.state === 'abgeschlossen'
                                                            ? 'btn-done'
                                                            : 'btn-unstarted'
                                                }`}
                                                onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/xrd', object.characteristics?.xrd?.id, 'charakteristik',object.workblockData.id, 'xrd')}
                                                >
                                                XRD
                                            </button>  
                                        </>
                                        : <>
                                            <button className={'btn text-dark btn-invalid-state'}
                                                onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/xrd', object.characteristics?.xrd?.id, 'charakteristik',object.workblockData.id, 'xrd')}
                                                >
                                                XRD
                                            </button>     
                                        </>
                                    : <></>
                                }
                                {object.characteristics.raman
                                    ?   object.characteristics.raman.state !== 'gelöscht' && object.characteristics.raman.state !== 'gesperrt'
                                        ? <>
                                            <button className={`btn text-dark ${
                                                !object.stateOfRelease 
                                                    ? 'btn-planned'
                                                    : object.characteristics.raman.state === 'aktiv'
                                                        ? 'btn-processing'
                                                        : object.characteristics.raman.state === 'abgeschlossen'
                                                            ? 'btn-done'
                                                            : 'btn-unstarted'
                                                }`}
                                                onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/raman', object.characteristics?.raman?.id, 'charakteristik',object.workblockData.id, 'raman')}
                                                >
                                                PM REM
                                            </button>  
                                        </>
                                        : <>
                                            <button className={'btn text-dark btn-invalid-state'}
                                                onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/raman', object.characteristics?.raman?.id, 'charakteristik',object.workblockData.id, 'raman')}
                                                >
                                                PM REM
                                            </button>      
                                        </>
                                    : <></>
                                }
                                {object.characteristics.postMortem
                                    ?   object.characteristics.postMortem.state !== 'gelöscht' && object.characteristics.postMortem.state !== 'gesperrt'
                                        ? <>
                                            <button className={`btn text-dark ${
                                            !object.stateOfRelease 
                                                ? 'btn-planned'
                                                : object.characteristics.postMortem.state === 'aktiv'
                                                    ? 'btn-processing'
                                                    : object.characteristics.postMortem.state === 'abgeschlossen'
                                                        ? 'btn-done'
                                                        : 'btn-unstarted'
                                            }`}
                                            onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/postMortemVisuell', object.characteristics?.postMortem?.id, 'charakteristik',object.workblockData.id, 'pm')}
                                                >
                                                PM Foto
                                            </button>  
                                        </>
                                        : <>
                                            <button className={'btn text-dark btn-invalid-state'}
                                                onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/postMortemVisuell', object.characteristics?.postMortem?.id, 'charakteristik',object.workblockData.id, 'pm')}
                                                >
                                                PM Foto
                                            </button>      
                                        </>
                                    : <></>
                                }
                                {object.characteristics.hafttest
                                    ?   object.characteristics.hafttest.state !== 'gelöscht' && object.characteristics.hafttest.state !== 'gesperrt'
                                        ? <>
                                            <button className={`btn text-dark ${
                                                !object.stateOfRelease 
                                                    ? 'btn-planned'
                                                    : object.characteristics.hafttest.state === 'aktiv'
                                                        ? 'btn-processing'
                                                        : object.characteristics.hafttest.state === 'abgeschlossen'
                                                            ? 'btn-done'
                                                            : 'btn-unstarted'
                                                }`}
                                                onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/hafttest', object.characteristics?.hafttest?.id, 'charakteristik',object.workblockData.id, 'hafttest')}
                                                >
                                                Sonstiges
                                            </button>  
                                        </>
                                        : <>
                                            <button className={'btn text-dark btn-invalid-state'}
                                                onClick={() => handleNavigate(object.workblockData.id, '/charakterisierung/hafttest', object.characteristics?.hafttest?.id, 'charakteristik',object.workblockData.id, 'hafttest')}
                                                >
                                                Sonstiges
                                            </button>      
                                        </>
                                    : <></>
                                }
                            </>
                        : <></>
                    }
                    </td>
                    <td style={{minWidth: '100px'}}>
                        {/* <button className={`btn text-dark ${
                                !object.stateOfRelease
                                    ? 'btn-planned'
                                    : 'btn-done'
                                }`}
                                onClick={() => handleConfirmPackage(object.workpackageData[0],object.stateOfRelease)}
                                >
                                {!object.stateOfRelease  ? 'Bestätigen' : 'Bestätigt'}
                        </button> */}
                        <button
                            className={`btn text-dark ${
                            object.summary 
                            ?   'btn-done'
                            :   'btn-unstarted'
                            }`}
                            onClick={(e => {
                                Cookies.set('tableFull_scrollPosition',object.workblockData.id)
                                navigate(`/arbeitspakete/zusammenfassung`,
                                    {
                                        state:{
                                            packageID: object.workpackageData[0],
                                            user: [object.responsibleUser]
                                        }
                                    });
                                })
                            }
                            data-bs-toggle='tooltip' data-bs-placement="bottom" title='Zusammenfassung bearbeiten'
                        >
                            <FontAwesomeIcon 
                                icon={faFilePen} 
                                size="xl"
                            />
                        </button>
                    </td>
                </tr>
            );
        });
        setInputsTable(tableData);
    };

    /**
     * Filter and Sort Functions for table
     */
    const sortByList = ['Priorität','Paket', 'Block', 'Verantwortlicher' , 'Kurzbeschreibung', 'Anodentyp', 'Anodenname']
    const filterTableData = (table:FullTableProps[]) => {
        /**
         * Filter Options Types
         */
        let newInputs = [...table];
        if(selectedFilter.anodenbautyp.length > 0){
            newInputs = newInputs.filter(x => (x.anode ? selectedFilter.anodenbautyp.includes(x.anode.type) : false));
        }
        if(selectedFilter.batteriebautype.length > 0){
            newInputs = newInputs.filter(x => {
                if(x.batteryConstruction){
                    //AND Condition
                    // let typeList = [];
                    // if(x.batteryConstruction.hz) typeList.push('hz');
                    // // if(x.batteryConstruction.pz) typeList.push('pz');
                    // if(x.batteryConstruction.vz) typeList.push('vz');

                    // for(let selected of selectedFilter.batteriebautype){
                    //     if(typeList.includes(selected)){
                    //         condition= true;
                    //     }
                    //     else{
                    //         return false;
                    //     }
                    // }

                    //OR Condition
                    if(x.batteryConstruction.hz && selectedFilter.batteriebautype.includes('hz')){
                        return true;
                    }
                    else if(x.batteryConstruction.vz && selectedFilter.batteriebautype.includes('vz')){
                        return true;
                    }
                }
                return false;
            });
        }
        if(selectedFilter.batterietest.length > 0){
            newInputs = newInputs.filter(x => {
                //AND Condition
                // let condition = false;
                // if(x.batteryTest){
                //     let typeList = [];
                //     if(x.batteryTest.hz) typeList.push('hz');
                //     // if(x.batteryTest.pz) typeList.push('pz');
                //     if(x.batteryTest.vz) typeList.push('vz');

                //     for(let selected of selectedFilter.batterietest){
                //         if(typeList.includes(selected)){
                //             condition= true;
                //         }
                //         else{
                //             return false;
                //         }
                //     }
                // }
                // return condition;

                //OR Condition
                if(x.batteryTest){
                    if(x.batteryTest.hz && selectedFilter.batterietest.includes('hz')){
                        return true;
                    }
                    else if(x.batteryTest.vz && selectedFilter.batterietest.includes('vz')){
                        return true;
                    }
                }
                return false;
            });
        }
        if(selectedFilter.characterisierung.length > 0){
            newInputs = newInputs.filter(x => {
                // //AND Condition
                // let condition = false;
                // if(x.characteristics){
                //     let typeList = [];
                //     if(x.characteristics.hafttest) typeList.push('hafttest');
                //     if(x.characteristics.microscopy) typeList.push('mikroskopie');
                //     if(x.characteristics.raman) typeList.push('raman');
                //     if(x.characteristics.rem) typeList.push('rem');
                //     if(x.characteristics.xrd) typeList.push('xrd');
                //     if(x.characteristics.postMortem) typeList.push('pm');


                //     for(let selected of selectedFilter.characterisierung){
                //         if(typeList.includes(selected)){
                //             condition= true;
                //         }
                //         else{
                //             return false;
                //         }
                //     }
                // }
                // return condition;

                //OR Condition
                if(x.characteristics){
                    //'hafttest'|'xrd' | 'rem' |'raman' | 'pm' | 'mikroskopie' 
                    if(x.characteristics.hafttest && selectedFilter.characterisierung.includes('hafttest')){
                        return true;
                    }
                    else if(x.characteristics.xrd && selectedFilter.characterisierung.includes('xrd')){
                        return true;
                    }
                    else if(x.characteristics.rem && selectedFilter.characterisierung.includes('rem')){
                        return true;
                    }
                    else if(x.characteristics.raman && selectedFilter.characterisierung.includes('raman')){
                        return true;
                    }
                    else if(x.characteristics.postMortem && selectedFilter.characterisierung.includes('pm')){
                        return true;
                    }
                    else if(x.characteristics.microscopy && selectedFilter.characterisierung.includes('mikroskopie')){
                        return true;
                    }
                }
                return false;
            });
        }
        /**
         * Filter State
         */
        if(selectedFilter.confirmationStateOfPackage.length > 0){
            if(selectedFilter.confirmationStateOfPackage.length === 1){
                switch(selectedFilter.confirmationStateOfPackage[0]){
                    case 'bestätigt':{
                        newInputs = newInputs.filter( x => x.stateOfRelease);
                        break;
                    }
                    case 'unbestätigt':{
                        newInputs = newInputs.filter( x => !x.stateOfRelease);
                        break;
                    }
                    default: break;
                }
            }
        }
        if(selectedFilter.state.length > 0){
            newInputs = newInputs.filter(x => {
                // check if special types where selected to filter state
                if(selectedFilter.anodenbautyp.length > 0 || selectedFilter.batteriebautype.length > 0 || selectedFilter.batterietest.length > 0 || selectedFilter.characterisierung.length > 0){
                    if( 
                        (x.anode && selectedFilter.anodenbautyp.includes(x.anode.type) && selectedFilter.state.includes(x.anode.state)) || 
                        (selectedFilter.batteriebautype.includes('vz') && x.batteryConstruction && x.batteryConstruction.vz && selectedFilter.state.includes(x.batteryConstruction.vz.state)) ||
                        (selectedFilter.batteriebautype.includes('hz') && x.batteryConstruction && x.batteryConstruction.hz && selectedFilter.state.includes(x.batteryConstruction.hz.state)) ||
                        // (selectedFilter.batteriebautype.includes('pz') && x.batteryConstruction && x.batteryConstruction.pz && selectedFilter.state.includes(x.batteryConstruction.pz.state))  ||
                        (selectedFilter.batterietest.includes('vz') && x.batteryTest && x.batteryTest.vz && selectedFilter.state.includes(x.batteryTest.vz.state)) ||
                        (selectedFilter.batterietest.includes('hz') && x.batteryTest && x.batteryTest.hz && selectedFilter.state.includes(x.batteryTest.hz.state)) ||
                        // (selectedFilter.batterietest.includes('pz') && x.batteryTest && x.batteryTest.pz && selectedFilter.state.includes(x.batteryTest.pz.state)) ||
                        (selectedFilter.characterisierung.includes('mikroskopie') && x.characteristics && x.characteristics.microscopy && selectedFilter.state.includes(x.characteristics.microscopy.state)) ||
                        (selectedFilter.characterisierung.includes('xrd') && x.characteristics && x.characteristics.xrd && selectedFilter.state.includes(x.characteristics.xrd.state)) ||
                        (selectedFilter.characterisierung.includes('rem') && x.characteristics && x.characteristics.rem && selectedFilter.state.includes(x.characteristics.rem.state)) ||
                        (selectedFilter.characterisierung.includes('raman') && x.characteristics && x.characteristics.raman && selectedFilter.state.includes(x.characteristics.raman.state)) ||
                        (selectedFilter.characterisierung.includes('pm') && x.characteristics && x.characteristics.postMortem && selectedFilter.state.includes(x.characteristics.postMortem.state)) ||
                        (selectedFilter.characterisierung.includes('hafttest') && x.characteristics && x.characteristics.hafttest && selectedFilter.state.includes(x.characteristics.hafttest.state))
                    ){
                        return true;
                    }
                }
                // if no special type was selected, then filter state of black an packages
                else {
                    // filter state in blocks
                    if(x.stateOfRelease && (selectedFilter.state.includes(x.stateOfBlock))){
                        return true;
                    }
                }
                return false;
            });
        }

        /**
         * Search
        */
        if(searchFilter!== ''){
            newInputs = newInputs.filter(x => {
                let keyword = searchFilter.toLowerCase();
                let found = x.nameOfPackage.toLowerCase().includes(keyword) || 
                            x.nameOfBlock.toLowerCase().includes(keyword) || 
                            x.responsibleUser.toLowerCase().includes(keyword) ||
                            x.shortDescriptionOfBlock.toLowerCase().includes(keyword) ||
                            (x.anode? x.anode.name.toLowerCase().includes(keyword) : false)
                return found;
            });
        }
        handleChangeSort(newInputs,sortBy)
    };
    const handleChangeSort = (table:FullTableProps[],target: string) => {
        setInitialization(false);
        let newInput = [...table];
        switch(target){
            case 'prio': {//Need other handling coz of cookie
                let allOpenPackages = newInput.filter(x => x.stateOfPackage === 'aktiv' || x.stateOfPackage === 'inPlanung');
                let allClosedPackages = newInput.filter(x => x.stateOfPackage !== 'aktiv' && x.stateOfPackage !== 'inPlanung');

                //all closed or deleted packages have prio 0 -> has to come at the end
                allOpenPackages = allOpenPackages.sort((a,b) => a.priorityOfPackage - b.priorityOfPackage);
                //sort closed packages by date
                allClosedPackages = allClosedPackages.sort((a,b) => {
                        //sort by name reverse
                        if(a.nameOfPackage.toLowerCase() > b.nameOfPackage.toLowerCase()){
                            return -1;
                        }
                        if(a.nameOfPackage.toLowerCase() < b.nameOfPackage.toLowerCase()){
                            return 1;
                        }
                        else{
                            return 0;
                    }});

                //concat active packages with closed ones
                newInput = [...allOpenPackages,...allClosedPackages];
                break;
            }
            case 'Paket': {
                newInput = newInput.sort((a,b) => {
                    if (a.nameOfPackage > b.nameOfPackage) {
                        return -1;
                    }
                    if (a.nameOfPackage < b.nameOfPackage) {
                        return 1;
                    }
                    return 0;
                    });
                break;
            };
            case 'Block': {
                newInput = newInput.sort((a,b) => {
                    if (a.nameOfBlock < b.nameOfBlock) {
                        return -1;
                    }
                    if (a.nameOfBlock > b.nameOfBlock) {
                        return 1;
                    }
                    return 0;
                    });
                break;
            }
            case 'Verantwortlicher':   {
                newInput = newInput.sort((a,b) => {
                    if (a.responsibleUser < b.responsibleUser) {
                        return -1;
                    }
                    if (a.responsibleUser > b.responsibleUser) {
                        return 1;
                    }
                    return 0;
                    });
                break;
            }  
            case 'Kurzbeschreibung': {
                newInput = newInput.sort((a,b) => {
                    if (a.shortDescriptionOfBlock < b.shortDescriptionOfBlock) {
                        return -1;
                    }
                    if (a.shortDescriptionOfBlock > b.shortDescriptionOfBlock) {
                        return 1;
                    }
                    return 0;
                    });
                break;
            }                           
            case 'Anodentyp': {
                newInput = newInput.sort((a,b) => {
                    if ((a.anode?.type ?? '' )< (b.anode?.type ?? '')) {
                        return -1;
                    }
                    if ((a.anode?.type ?? '') > (b.anode?.type ?? '')) {
                        return 1;
                    }
                    return 0;
                    });
                break;
            }   
            case 'Anodenname': {
                newInput = newInput.sort((a,b) => {
                    if ((a.anode?.name ?? '' )< (b.anode?.name ?? '')) {
                        return -1;
                    }
                    if ((a.anode?.name ?? '') > (b.anode?.name ?? '')) {
                        return 1;
                    }
                    return 0;
                    });
                break;
            } 
            default: {
                break;
            }
        };
        setSorted(true);
        setInputs(newInput);
    };
    const handleOpenModal = (blockID:string) => {
        Cookies.set('tableFull_scrollPosition',blockID);
        setModalLoaded(false);
        setModalBlockID(blockID)
    };
    const handleModalClose = (shortDescription:string, blockID:string) => {
        let idx = inputs.map( x => x.workblockData.id).indexOf(blockID);
        console.log(idx,blockID)
        //set modalBlockID to empty -> allows editing of same row multiple times directly after dismiss modal
        setModalBlockID('');
        if(!idx && idx!==0) return;
        let newInputs = [...inputs];
        newInputs[idx].shortDescriptionOfBlock = shortDescription;
        setInputs(newInputs);
    };
    const handleChangePriority = async(packageID: string) => {
        //Api Edit Prio Call -> adjust in backend
        console.log(selectedPriority);
        await ApiWorkPackage.EditPriority(packageID,Number(selectedPriority))
        .then(()=>{
            setSelectedPrioRow(null);
            setSelectedPriority('');
            setInitialization(false);
            loadData();
        })
        .catch(e=>{
            console.log(e);
            setSelectedPrioRow(null);
            setSelectedPriority('');
        });
    };
    const handleClick = async(index: number) => {
        await setSelectedPrioRow(index) 
        await setSelectedPriority(String(inputs[index].priorityOfPackage))
    };
    /**
     * Navigation to Pages
     */
    const handleNavigate = (blockID:string,path: string, data: any, page: 'arbeitspaket' | 'anodeR2R' | 'anodeS2S' | 'batteriebau' | 'batterietest' | 'charakteristik', workblockID?:string, type?:string) => {

        //blockID to set table scroll position after interaction
        Cookies.set('tableFull_scrollPosition',blockID);

        switch(page){
            case 'arbeitspaket': {
                navigate(path, {
                    state: {
                      workpackage: data
                    },
                  });
                break;
            }
            case 'anodeS2S' : {
                if(!data && workblockID){
                    navigate(path, {
                        state: {
                            data: null,
                            workblockID: workblockID
                        },
                    });
                    break;
                }
                ApiAnodeConstruction.Get(data)
                    .then((anode) => {
                        navigate(path, {
                        state: {
                            data: anode,
                            materials: materials,
                            workblockID: anode.workblock.id
                        },
                        });
                    })
                .catch((err) => {
                    console.log(err);
                });
                break;
            }
            case 'anodeR2R': {
                if(!data && workblockID){
                    navigate(path, {
                        state: {
                            data: null,
                            workblockID: workblockID
                        },
                    });
                    break;
                }
                ApiAnodeConstructionR2R.Get(data)
                    .then((anode) => {
                        navigate(path, {
                        state: {
                            data: anode,
                            workblockID: anode.workblock.id
                        },
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                break;
            }
            case 'batteriebau': {
                if(!data && workblockID && type){
                    navigate(path,{
                        state:{
                            data: null,
                            materials: materials,
    
                            workblockID: workblockID, 
                            halbzellenList: halbzellenList,
                            vollzellenList: vollzellenList,
                            type: type === 'HZ' ? 'HZ' : 'VZ'
                        }
                        });
                    break;
                }
                if(data !== null){
                    ApiBatteryConstruction.Get(data)
                    .then((battery) =>{ 
                        navigate(path,{
                        state:{
                            data: battery,
                            materials: materials,
                            workblockID: battery.workblock.id, 
                            halbzellenList: halbzellenList,
                            vollzellenList: vollzellenList,
                            type: battery.fixedBatteryData.typeID === 'Halbzelle' ? 'HZ' : 'VZ'
                        }
                        });
                    })
                    .catch((err)=>{
                        console.log(err);
                    });
                }
                
                break;
            }
            case 'batterietest': {
                if(workblockID){
                    ApiWorkBlock.Get(workblockID)
                        .then((dataWB)=> {
                            if(!data &&  type){
                                navigate(path,{
                                    state:{ workblockID: workblockID, 
                                    workblockName: dataWB.name,
                                    workpackageID: dataWB.workpackage.id,
                                    workpackageName: dataWB.workpackage.name,
                                    data: null,
                                    halbzellenList: halbzellenList,
                                    vollzellenList: vollzellenList,
                                    type: type === 'HZ' ? 'HZ' : 'VZ'
                                }});
                            }
                            if(data){
                                ApiBatteryTest.Get(data)
                                    .then((batterytest) =>{
                                        navigate(path,{
                                           state:{ workblockID: workblockID, 
                                            workblockName: dataWB.name,
                                            workpackageID: dataWB.workpackage.id,
                                            workpackageName: dataWB.workpackage.name,
                                            data: batterytest, 
                                            halbzellenList: halbzellenList,
                                            vollzellenList: vollzellenList,
                                            type: type === 'HZ' ? 'HZ' : 'VZ'
                                        }});
                                    })
                                    .catch((err)=>{
                                        console.log(err);
                                    })
                            }
                        })
                        .catch((err)=> {
                            console.log(err);
                        })
                }
                
                break;
            }
            case 'charakteristik': {
                if(workblockID){
                    let characteristicData: any = null;
                    if(data){
                        switch(type){
                            case 'hafttest': {
                                break;
                            }
                            case 'mikroskopie': {
                                break;
                            }
                            case 'xrd': {
                                break;
                            }
                            case 'rem': {
                                break;
                            }
                            case 'raman': {
                                break;
                            };
                            case 'pm': {
                                break
                            };
                            default: break;
                        }
                    }
                    ApiWorkBlock.Get(workblockID)
                        .then((workblock)=>{
                            navigate(path,{
                                state: {
                                    workblockID: workblockID,
                                    workblockName: workblock.name,
                                    workpackageID: workblock.workpackage.id,
                                    workpackageName: workblock.workpackage.name,
                                    characteristicsID: workblock.characteristics?.id ?? '',
                                    data : characteristicData
                                }
                            });

                        })
                        .catch((err)=> {
                            console.log(err);
                        })
                }
                break;
            }
        }
        
    };
    const handleNew = () => {
        navigate(`/arbeitspakete/neu`);
    };
    // const handleConfirmPackage = (packageID:string, stateOfRelease: boolean) => {
    //     setInitialization(false);
    //     if(!stateOfRelease){
    //         ApiWorkPackage.Activate({id: packageID})
    //         .then(()=>{
    //             loadData();
    //         })
    //         .catch((err) =>{
    //             console.log(err);
    //         });
    //     }
    //     else{
    //         ApiWorkPackage.Plan({id: packageID})
    //         .then(()=>{
    //             loadData();
    //         })
    //         .catch((err) =>{
    //             console.log(err);
    //         });
    //     }
        
    // };
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let checked = event.target.checked;
        setShowInvalidStates(checked);
        Cookies.set('tablefull_settings_ ShowLockedSteps', String(checked));
    };
    const handleRadioChangeSecond = (event: React.ChangeEvent<HTMLInputElement>) => {
        let checked = event.target.checked;
        setShowInvalidStatesPackages(checked);
        Cookies.set('tablefull_settings_ ShowLockedBlocksAndPackages', String(checked));
    };

    /**
     * Use Effects: loadData() => set data for table => create table => initialized
     */
    useEffect(() => {
        document.title = 'Arbeitspakete - Gesamtübersicht';
        loadInitialData();
    },[]);
    useEffect(()=> {
        if(loadMaterialFinished){
            loadData();
            handleChangeSort(inputs,sortBy)
        }
    },[loadMaterialFinished]);
    useEffect(() => {
        if(sorted){
            createTable();
        }
    },[inputs,selectedPriority]);
    useEffect(() => {
        setInitialization(true);
    },[inputsTable]);
    useEffect(() => {
        setInitialization(false);
        if(loadMaterialFinished){
            loadData();
        }
    },[showInvalidStates,showInvalidStatesOfPackages,selectedFilter,searchFilter])
    useEffect(()=>{
        handleChangeSort(inputs,sortBy);
    },[sortBy]);
    useEffect(()=>{
        if(initialized){
            //scroll into view
            if(scrollRef && scrollRef.current){
                scrollRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start"
                  });
                Cookies.remove('tableFull_scrollPosition');
            }
        }
    },[initialized]);
    useEffect(()=>{
        if(modalBlockID!== ''){
            setModalLoaded(true);
        }
    },[modalBlockID]);
    useEffect(()=> {
        if(modalLoaded){
            let modalID = 'editDesription__' + modalBlockID;
            let component = document.getElementById(modalID);
            if(!component) return;
            $('#'+modalID).modal('show')
        }
    },[modalLoaded]);
    
    return(
        <div className='content-main-container'>
            <h1>Arbeitspakete - Gesamtübersicht</h1>
            <TableSettingsFullTable 
                inputsFilter={selectedFilter}
                searchFilter={searchFilter}
                handleSearchFilterChange={setSearchFilter}
                handleFilterChange={setSelectedFilter}
                handleChangeSort={setSortBy}
                handleRadioChange={handleRadioChange}
                handleRadioChangeSecond={handleRadioChangeSecond}
                sortBy={sortBy}
                sortByList={sortByList}
            />
            {initialized ? 
            <><div className="row">
                    <div className="col p-0 ms-3">
                        <button
                            className="btn-main mt-4"
                            data-bs-toggle='tooltip' data-bs-placement="bottom" title='Neues Arbeitspaket'
                            onClick={handleNew}
                        >
                            <FontAwesomeIcon 
                                icon={faCirclePlus} 
                                size="xl"
                            /> Neues Arbeitspaket
                        </button>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className='table main-table margin-top-25 table-hover'>
                        <thead>
                            {tableHeaders}
                        </thead>
                        <tbody>
                            {inputsTable}
                        </tbody>
                    </table>
                </div>
                { modalLoaded
                    ?   <DescriptionModal
                            blockID = {modalBlockID} 
                            modalID = {'editDesription__' + modalBlockID}
                            handleChangeShortDescription={handleModalClose}
                            handleOnDismiss={() => {setModalBlockID('')}}
                        />
                    :   <></>

                }
            </>
            : <></>
            }
        </div>
    );
};

import { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
export interface MultiselectDropdownProps{
    id?: string;
    valueList: string[];
    selectedValues: Option[];
    dropdownMenuClass?:string;
    formClass?:string;
    toggleButtonClass?:string;
    inputTextClass?:string;
    dropdownItemClass?:string;
    selectedItemClass?:string;
    selectedItems?: string[];
    handleChange: (items:Option[]) => void;
}

interface Option {
  id: string;
  label: string;
}

const MultiSelectDropdown = ({
    id,
    valueList,
    selectedValues,
    dropdownMenuClass,
    formClass,
    toggleButtonClass,
    inputTextClass,
    selectedItems,
    selectedItemClass,
    dropdownItemClass,
    handleChange
}:MultiselectDropdownProps) => {
    const [selectedOptions, setSelectedOptions] = useState<Option[]>(selectedValues);
    const [optionList, setOptionList] = useState<Option[]>([]);
    const [initialized, setInitialization] = useState(false);

    useEffect(() => {
        setMultiselectData();
    },[]);
    useEffect(()=>{
        setInitialization(true)
        handleChange(selectedOptions);
    },[selectedOptions]);

    const setMultiselectData = () =>{
        let i = -1;
        let selectedList: {id:string, label: string}[] = [];
        let opList = valueList.map((x)=> {
            i +=1;
            if(selectedItems && selectedItems.includes(x)){
                selectedList.push({id:String(i), label: x})
            }
            return {id:String(i), label: x}
        });
        setSelectedOptions(selectedList);
        setOptionList(opList);
    };
  const handleSelect = (selectedOption: Option) => {
    setInitialization(false)
    const isSelected = selectedOptions.some(option => option.id === selectedOption.id);
    let newSelectedOptions = [...selectedOptions];
    if (isSelected) {
      // If already selected, remove it
      newSelectedOptions = newSelectedOptions.filter(option => option.id !== selectedOption.id)
      setSelectedOptions(newSelectedOptions);
    } else {
      // If not selected, add it
      newSelectedOptions.push(selectedOption);
      setSelectedOptions(newSelectedOptions);
    }
  };

  return (
     <form className={formClass ?? ""} id={id ?? ""}>
        <div className="input-group">
            <input type="text" className={`form-control ${inputTextClass ?? ""}`} placeholder={selectedOptions.map(option => option.label).join(', ')}/>
            <button className={`btn ${toggleButtonClass?? "btn-outline-secondary"} dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
            <ul className={`dropdown-menu ${dropdownMenuClass ?? "dropdown-menu-end"}`}>
                {initialized? 
                    <>
                    {optionList.map(item => (
                    <li className={`dropdown-item ${dropdownItemClass ?? ""}`}
                        key={item.id}
                        id={item.id}
                        onClick={() => handleSelect(item)}
                        >
                        {item.label}
                    </li>
                    ))}
                    </>
                : <></>}
            </ul>
        </div>
    </form>

  );
};

export default MultiSelectDropdown;

export const MultiSelectDropdownWithCheckboxes = ({
    id,
    valueList,
    selectedValues,
    dropdownMenuClass,
    toggleButtonClass,
    inputTextClass,
    selectedItemClass,
    formClass,
    dropdownItemClass,
    handleChange
}:MultiselectDropdownProps) => {
    const [selectedOptions, setSelectedOptions] = useState<Option[]>(selectedValues);
    const [optionList, setOptionList] = useState<Option[]>([]);
    const [initialized, setInitialization] = useState(false);
    useEffect(() => {
        setMultiselectData();
    },[])
    useEffect(()=>{
        setInitialization(true)
    },[optionList]);

    useEffect(()=>{
        setInitialization(true)
        handleChange(selectedOptions);
    },[selectedOptions]);

    const setMultiselectData = () =>{
        let i = -1;
        let opList = valueList.map((x)=> {
            i +=1;
            return {id:String(i), label: x}
        });
        setOptionList(opList);
    };
    const handleCheckboxChange = (selectedOption: Option) => {
        const isSelected = selectedOptions.some(option => option.id === selectedOption.id);

        if (isSelected) {
        // If already selected, remove it
        setSelectedOptions(prevOptions => prevOptions.filter(option => option.id !== selectedOption.id));
        } else {
        // If not selected, add it
        setSelectedOptions(prevOptions => [...prevOptions, selectedOption]);
        }
    };

  return (
    <form className={formClass ?? ""} id={id ?? ""} >
        <div className="input-group">
            <input type="text" className={`form-control dropdown-toggle ${inputTextClass ?? ""}`} data-bs-toggle="dropdown" aria-expanded="false" placeholder={selectedOptions.map(option => option.label).join(', ')}/>
            <ul className={`dropdown-menu ${dropdownMenuClass ?? "dropdown-menu-end"}`}>
                {initialized? 
                    <>
                    {optionList.map(item => (
                    <li className={`dropdown-item ${dropdownItemClass ?? ""}`}
                        key={item.id} > 
                        <Form.Check
                            type="checkbox"
                            id={item.id} >
                            <Form.Check.Input type="checkbox"
                                checked={selectedOptions.some(selected => selected.id === item.id)}
                                onClick={() => handleCheckboxChange(item)}
                                className={selectedItemClass ?? ""}
                            />
                            <Form.Check.Label>{item.label}</Form.Check.Label>
                        </Form.Check>
                    </li>
                    ))}
                    </>
                    : <></>}
            </ul>
        </div>
    </form>
  );
};
import Config from '../util/Config';
import { ApiPrepareRequest } from './ApiPrepareRequest';
import { ApiClient } from './ApiClient';
import BasicResponse from './BasicResponse';
import * as requestTypes from './types/__RequestStructures';
import * as responseTypes from './types/__ResponseStructures';
import { CreateBasicResponse } from './types/__GeneralStructures';


export class ApiSummary {
  static basePath: string = '/summary';
  static baseUrl: string = Config.BackendUrl + ApiSummary.basePath;


  //Get
  static Get(id: string = ''): Promise<responseTypes.IBackendSummary> {
    let path = '/get';

    // prepare query
    let query = ApiPrepareRequest.PrepareGetQuery(id);
    let url = ApiSummary.baseUrl + path + query;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendSummary>;

      resolve(responseObject.data);
    });
  };


  static Create(requestData: requestTypes.__SummaryCreateConverted): Promise<string> {
    let path = '/create';
    let url = ApiSummary.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject =
        (await response.json()) as CreateBasicResponse<string>;
      resolve(responseObject.id);
    });
  };

  static Edit(requestData: requestTypes.__SummaryEditConverted): Promise<boolean> {
    let path = '/edit';
    let url = ApiSummary.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.put(url, requestData);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  };
  // add summary and evaluations
  static AddSummary(requestData: requestTypes.__SummaryPropsAdd): Promise<boolean>{
    let path = '/addevaluation';
    let url = ApiSummary.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.put(url, requestData);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  //upload
  static Upload(requestData: requestTypes.__UploadFiles): Promise<boolean> {
    let path = '/upload';
    let url = ApiSummary.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject =
        (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  };
  //generatepdf
  static GeneratePdf(id: string): Promise<string> {
    let path = '/generatepdf';
    let query = '?id=' + id;
    let url = ApiSummary.baseUrl + path + query;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status === 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.data);
    });
  };
}

export type InputsHeader = {
  packageID: string;
  workBlockID: string;
  vorgehensbeschreibung: string;
  afterSputteringGlassSclices: boolean;
  afterSputteringRolling: boolean;
  date: string;
  status: string;
  name: string;
  anodeID: string;
  verantwortlicher: string;
  syntheseort: string;
  substrat: string;
  plasmaCheckbox: boolean;
  blitzCheckbox: boolean;
  ofenCheckbox: boolean;
  andereCheckbox: boolean;
  andereInput: string;
  laengeInput: string;
};
export type InputElementTableOne = {
  id: string;
  geschwindigkeit: string;
  leistung: string;
  gasfluss: string;
  druck: string;
  start: string;
  stop: string;
  blitz: string;
  kommentar: string;
  [key: string]: string; // add an index signature
};
export type InputElementTableTwo = {
  schritt: string;
  material: string;
  dicke: string;
  gasfluss: string;
  druck: string;
  p: string;
  u: string;
  i: string;
  geschwindigkeit: string;
  start: string;
  stop: string;
  blitz: string[];
  blitzList: {id:string,label:string}[];
  kommentar: string;
  [key: string]: string | any; // add an index signature
};
export type InputElementTableThree = {
  blitz: string;
  spannung: string;
  energie: string;
  impulslaenge: string;
  start: string;
  ende: string;
  geschwindigkeit: string;
  anzahl: string;
  intervall: string;
  vorpuls: string;
  vorpulsPulslaenge: string;
  lampe: string;
  kommentar: string;
  [key: string]: string; // add an index signature
};
export type InputElementTableFour = {
  segment: InputElementTableFourData[];
  band: InputElementTableFourData[];
  pos: InputElementTableFourData[];
  start: InputElementTableFourData[];
  ende: InputElementTableFourData[];
  [key: string]:InputElementTableFourData[];
};

export type InputElementTableFourData = {
  value: string;
  [key: string]:string;
};

export const emptyinputsHeader: InputsHeader = {
  packageID: '',
  workBlockID: '',
  vorgehensbeschreibung: '',
  afterSputteringGlassSclices: false,
  afterSputteringRolling: false,
  date: '',
  status: '',
  name: '',
  anodeID: '',
  verantwortlicher: '',
  syntheseort: '',
  substrat: '',
  blitzCheckbox: false,
  ofenCheckbox: false,
  plasmaCheckbox: false,
  andereCheckbox: false,
  andereInput: '',
  laengeInput: '',
};

export const emptyTableOne: InputElementTableOne = {
  id: '',
  geschwindigkeit: '',
  leistung: '',
  gasfluss: '',
  druck: '',
  start: '',
  stop: '',
  blitz: '',
  kommentar: '',
};

export const emptyTableTwo: InputElementTableTwo = {
  schritt: '',
  material: '',
  dicke: '',
  gasfluss: '',
  druck: '',
  p: '',
  u: '',
  i: '',
  geschwindigkeit: '',
  start: '',
  stop: '',
  blitz: [],
  blitzList:[],
  kommentar: '',
};

export const emptyTableThree: InputElementTableThree = {
  blitz: '',
  spannung: '',
  energie: '',
  impulslaenge: '',
  start: '',
  ende: '',
  geschwindigkeit: '',
  anzahl: '',
  intervall: '',
  vorpuls: '',
  vorpulsPulslaenge: '',
  lampe: '',
  kommentar: '',
};

export const emptyTableFour: InputElementTableFour = {
  segment: [{value: ''}],
  band: [{value: ''}],
  pos: [{value: ''}],
  start: [{value: ''}],
  ende: [{value: ''}],
};
export const tableHeadersFour = [
    'Segment',
    'Band',
    'Pos',
    'Start',
    'Ende'
];
export const tableHeadersOne = (
  <thead>
    <tr className=" align-middle">
      <th rowSpan={2}></th>
      <th rowSpan={2}>Geschwindigkeit (m/min)</th>
      <th rowSpan={2}>Leistung (W)</th>
      <th rowSpan={2}>Gasfluss (sccm)</th>
      <th rowSpan={2}>Druck (10^-3 mbar)</th>
      <th colSpan={2}>Position</th>
      <th rowSpan={2}>Blitz (#)</th>
    </tr>
    <tr className=" align-middle">
      <th>Start (m)</th>
      <th>Stop (m)</th>
    </tr>
  </thead>
);
export const tableHeadersTwo = (
  <thead>
    <tr className=" align-middle">
      <th rowSpan={2}></th>
      <th rowSpan={2}>Schritt (#)</th>
      <th rowSpan={2}>Material</th>
      <th rowSpan={2}>Dicke (nm)</th>
      <th rowSpan={2}>Leistung (P)</th>
      <th rowSpan={2}>Geschw. (m/min)</th>
      <th colSpan={2}>Segment</th>
      <th rowSpan={2}>Blitz (#)</th>
{/* 
      <th rowSpan={2}>Gasfluss (sccm)</th>
      <th rowSpan={2}>Druck (10^-3 mbar)</th>
      <th rowSpan={2}>P (W)</th>
      <th rowSpan={2}>U (V)</th>
      <th rowSpan={2}>I (mA)</th>
      <th colSpan={2}>Pos. abs.</th> */}
    </tr>
    <tr className=" align-middle">
      <th>Start (#)</th>
      <th>Stop (#)</th>
    </tr>
  </thead>
);

export const tableHeadersThree = (
  <thead>
    <tr className=" align-middle">
      <th rowSpan={2}></th>
      <th rowSpan={2}>Blitz (#)</th>
      <th rowSpan={2}>Lampe (#)</th>
      <th rowSpan={2}>Spannung (kV)</th>
      {/* <th rowSpan={2}>Energie (J/cm^2)</th> */}
      <th rowSpan={2}>Anzahl (#)</th>
      <th colSpan={2}>Pos. Segm.</th>
      <th rowSpan={2}>Geschw. (m/min)</th>
      <th rowSpan={2}>Pulslänge (ms)</th>
      <th rowSpan={2}>Intervall (ms)</th>
      <th rowSpan={2}>Vorblitz (kV)</th>
      <th rowSpan={2}>Vorblitz Pulslänge (ms)</th>
    </tr>
    <tr>
      <th>Start (#)</th>
      <th>Ende (#)</th>
    </tr>
  </thead>
);

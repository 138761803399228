import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModularSearchHeader from '../../components/ModularSearchHeader/ModularSearchHeader';
import { Table } from '../../components/Table';
import {
  ApiWorkPackage,
  WorkpackageConverter,
} from '../../backend/ApiWorkPackage';

export default function ArbeitspaketPlanung() {
  const navigate = useNavigate();
  const tableHeaders = [
    'Name',
    'Verantwortlicher',
    'Datum',
    'Status',
    'Ort',
    'Bearbeiten',
  ];
  const [displayedTable, setDisplayedTable] = useState<string[][]>([]);

  const [initializedTable, setInitializedTable] = useState(false);
  
  useEffect(() => {
    document.title = 'Arbeitspakete in Planung';
    if (!initializedTable) {
      ApiWorkPackage.ListAll({
        state: { name: 'inPlanung' },
      }).then((data) => {
        setDisplayedTable(WorkpackageConverter.ConvertFromApiToTableList(data));
        setInitializedTable(true);
      });
    }
  });

  const [modalContent, setModalContent] = useState<ReactNode>(<></>);
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({});
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getInitialTable = () => {
    return displayedTable;
  };
  const handleModalOpen = (displayModal: React.ReactNode) => {
    setModalContent(displayModal);
    setOpenModal(!openModal);
  };
  const handleModalClose = () => {
    setOpenModal(false);
    let overlay = document.getElementById('overlay-root');
    if(overlay){
      overlay.style.display = 'none';
    }
  };
  const handleOpen = (index: number) => {
    
    navigate(`/arbeitspakete/details`, {
      state: {
        workpackage: displayedTable[index]
      },
    });
  };
  const handleDuplicate = (index: number) => {
    navigate(`/arbeitspakete/neu`);
  };
  const handleNew = () => {
    navigate(`/arbeitspakete/neu`);
  };
  const handleAccept = (index: number) => {
    let response = ApiWorkPackage.Activate({ id: displayedTable[index][0] })
    .then((data) => {
      navigate("/arbeitspakete");
    });
  };
  const someFunctions = {
    func: [handleOpen, handleAccept],
    val: ['Öffnen', 'Bestätigen'],
    defaultState: ["aktiv", "aktiv"],
    modal: [false, true] //infomodal
  };

  return (
    <div className='content-main-container'>
      {initializedTable ? (
        <ModularSearchHeader
          title='Arbeitspakete in Planung'
          dropdownOptions={tableHeaders.slice(0, -1)}
          handleChange={handleChange}
          setDisplayedTable={setDisplayedTable}
          displayTable={displayedTable}
          getInitialTable={getInitialTable}
        />
      ) : (
        <></>
      )}

      <input
        className='btn-main margin-top-15'
        type='button'
        value='Neues Arbeitspaket'
        onClick={handleNew}
      />
      <Table
        modalIDList={{...someFunctions.val.map(x => 'AbreitspaketePlanung_'+x)}}
        modalTextList={['','Arbeitspaket bestätigen ?']}
        headers={tableHeaders}
        valuesList={displayedTable}
        stateList={null}
        functionsList={someFunctions}
      />
    </div>
  );
}

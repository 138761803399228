import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiBatteryTest } from '../../backend/ApiBatteryTest';
import {
  __BatteryTestValuesConverted,
  __BatteryTestValuesUnconverted,
  __FileResponse
} from '../../backend/types/__GeneralStructures';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { IBackendBatteryTestGet } from '../../backend/types/__ResponseStructures';
import InputField from '../../UI/InputField';
import { ConfirmationModal, InfoModal, NavigationModal } from '../../components/Modal';
import Loading from '../../components/Ladebalken';
import FileUploadAccordeon from '../../components/FileUpload';
import { ApiFile } from '../../backend/ApiFiles';
import { ApiBatteryConstruction } from '../../backend/ApiBatteryConstruction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useUserList } from '../../hooks/UserHooks';

const BatterietestDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const [userListDict, userDataLoaded] = useUserList();

  const [batteryTestMain, setBatteryTestMain] = useState<IBackendBatteryTestGet>(location.state.data);  
  const [IdListToDisconnect, setIdListToDisconnect] = useState<string[]>([]);
  const [batteries, setBatteries] = useState<{ id: string; name: string }[]>([]);
  const [batteriesLoaded, setBatteriesLoaded] = useState(false);
  const defaultTable = [
    {
      batteriename:'',
      batterieID: '',
      misst: false,
      beendet: false,
      defekt: false,
      formingI: '',
      formingU: '',
      formingQ: '',
      cyclingI: '',
      cyclingU: '',
      cyclingQ: '',
      cyclingCV: '',
      ratentestI: '',
      ratentestCycPerI: '',
      ratentestRepeat: '',
      bemerkungen: '',
    },
    {
      batteriename: '',
      batterieID: '',
      misst: false,
      beendet: false,
      defekt: false,
      formingI: '',
      formingU: '',
      formingQ: '',
      cyclingI: '',
      cyclingU: '',
      cyclingQ: '',
      cyclingCV: '',
      ratentestI: '',
      ratentestCycPerI: '',
      ratentestRepeat: '',
      bemerkungen: '',
    },
  ];
  const [files, setFiles] = useState<__FileResponse[]>([]);
  const [loadFiles, setLoadFiles] = useState(false);
  const [inputs, setInputs] = useState(
    batteryTestMain
      ? [
          {
            batteriename: '',
            batterieID: '',
            misst: false,
            beendet: false,
            defekt: false,
            formingI: '',
            formingU: '',
            formingQ: '',
            cyclingI: '',
            cyclingU: '',
            cyclingQ: '',
            cyclingCV: '',
            ratentestI: '',
            ratentestCycPerI: '',
            ratentestRepeat: '',
            bemerkungen: '',
          },
          {
            batteriename: '',
            batterieID: '',
            misst: false,
            beendet: false,
            defekt: false,
            formingI: '',
            formingU: '',
            formingQ: '',
            cyclingI: '',
            cyclingU: '',
            cyclingQ: '',
            cyclingCV: '',
            ratentestI: '',
            ratentestCycPerI: '',
            ratentestRepeat: '',
            bemerkungen: '',
          },
        ]
      : defaultTable
  );
  // get current Date
  let currentFullDate = new Date();
  let month: number|string = currentFullDate.getMonth()+1;
  if (month < 10){
    month = '0' + month.toString();
  }
  else{
    month = month.toString();
  }
  let date =  [currentFullDate.getFullYear().toString(), month,currentFullDate.getDate().toString()].join('-');
    
  const [initializedTable, setInitializedTable] = useState(false);
  const [initialized, setInitialization] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [inputsHeader,setInputsHeader] = useState(
      {workBlockName: location.state.workblockName 
        ? location.state.workblockName
        : '',
      packageName: location.state.workpackageName
        ? location.state.workpackageName
        : '',
      date: location.state.data 
        ? location.state.data.date.split('T')[0]
        : date,
      responsibleUser: location.state.data
        ? location.state.data.responsibleUser[0].displayName
        : '',
      userList: [''],
      comment: location.state.data
        ? location.state.data.comment
        : '',
      status: location.state.data 
        ? location.state.data.state.name
        : enums.WorkBlockState[0]
      }
    );

  const fillInitialData = () => {
    let batteryTestArray: any[] = [];
    let batteryTestMainCopy = {...batteryTestMain};
    if(batteryTestMainCopy.batteryTestProcessing.length > 0 && batteryTestMainCopy.batteryTestWishes[0].dch){
      batteryTestMainCopy.batteryTestProcessing.map((battery: any) => {
        let batteryTest = [
          {
            batteriename: battery.battery.name ?? '',
            batterieID: battery.battery.id ?? '',
            misst: battery.isMeasuring,
            beendet: battery.isFinished,
            defekt: battery.hasDefects,
            formingI: battery.ch.forming_I_mA,
            formingU: battery.ch.forming_U_max_min,
            formingQ: battery.ch.forming_QLimit_mAh,
            cyclingI: battery.ch.cycling_I_mA,
            cyclingU: battery.ch.cycling_U_max_min_V,
            cyclingQ: battery.ch.cycling_QLimit_mAh,
            cyclingCV: battery.ch.cycling_CV_I_cut_mA,
            ratentestI: battery.ch.rateTest_I_mA,
            ratentestCycPerI: battery.ch.rateTest_Cyc_per_i,
            ratentestRepeat: battery.ch.rateTest_Repeat,
            bemerkungen: battery.annotation,
          },
          {
            batteriename: battery.battery.name ?? '',
            batterieID: battery.battery.id ?? '',
            misst: battery.isMeasuring,
            beendet: battery.isFinished,
            defekt: battery.hasDefects,
            formingI: battery.dch.forming_I_mA,
            formingU: battery.dch.forming_U_max_min,
            formingQ: battery.dch.forming_QLimit_mAh,
            cyclingI: battery.dch.cycling_I_mA,
            cyclingU: battery.dch.cycling_U_max_min_V,
            cyclingQ: battery.dch.cycling_QLimit_mAh,
            cyclingCV: battery.dch.cycling_CV_I_cut_mA,
            ratentestI: battery.dch.rateTest_I_mA,
            ratentestCycPerI: battery.dch.rateTest_Cyc_per_i,
            ratentestRepeat: battery.dch.rateTest_Repeat,
            bemerkungen: battery.annotation,
          },
        ];
        batteryTestArray = batteryTestArray.concat(batteryTest);
      });
    }
    else{
      batteryTestMainCopy.batteryTestWishes.map((battery: any) => {
      let batteryTest = [
        {
          batteriename: '',
          batterieID: '',
          misst: false,
          beendet: false,
          defekt: false,
          formingI: battery.ch.forming_I_mA,
          formingU: battery.ch.forming_U_max_min,
          formingQ: battery.ch.forming_QLimit_mAh,
          cyclingI: battery.ch.cycling_I_mA,
          cyclingU: battery.ch.cycling_U_max_min_V,
          cyclingQ: battery.ch.cycling_QLimit_mAh,
          cyclingCV: battery.ch.cycling_CV_I_cut_mA,
          ratentestI: battery.ch.rateTest_I_mA,
          ratentestCycPerI: battery.ch.rateTest_Cyc_per_i,
          ratentestRepeat: battery.ch.rateTest_Repeat,
          bemerkungen: battery.annotation ?? '',
        },
        {
          batteriename: '',
          batterieID: '',
          misst: false,
          beendet: false,
          defekt: false,
          formingI: battery.dch.forming_I_mA,
          formingU: battery.dch.forming_U_max_min,
          formingQ: battery.dch.forming_QLimit_mAh,
          cyclingI: battery.dch.cycling_I_mA,
          cyclingU: battery.dch.cycling_U_max_min_V,
          cyclingQ: battery.dch.cycling_QLimit_mAh,
          cyclingCV: battery.dch.cycling_CV_I_cut_mA,
          ratentestI: battery.dch.rateTest_I_mA,
          ratentestCycPerI: battery.dch.rateTest_Cyc_per_i,
          ratentestRepeat: battery.dch.rateTest_Repeat,
          bemerkungen: battery.annotation ?? '',
        },
      ];
      batteryTestArray = batteryTestArray.concat(batteryTest);
    });
    }
    setInputs(batteryTestArray);
  };
  
  //Loadoing
  const loadData = async() =>{
    await fillInitialData();
    let inputsHeaderWithUserList = {...inputsHeader};
    inputsHeaderWithUserList.userList = batteryTestMain.responsibleUser.map(x =>x.displayName);
    inputsHeaderWithUserList.status = batteryTestMain.state.name;
    inputsHeaderWithUserList.responsibleUser = batteryTestMain.responsibleUser[0].displayName;
    inputsHeaderWithUserList.comment = batteryTestMain.comment;
    await setInputsHeader(inputsHeaderWithUserList); 
    await setInitializedTable(true);
    setOpenLoadingModal(false);
    return
  };
  const loadFileData = async() =>{
    if(location.state.data){
      await ApiBatteryTest.Get(location.state.data.id).then((data)=>{
        const fileList = data.files ?? [];
        setFiles(fileList);
      });
    }
    setLoadFiles(true);
  };
  const loadBatteries = async() =>{
    let data = await ApiBatteryConstruction.ListBatteries();
    let batteriesList: { id: string; name: string }[] = [];
    data = data.sort((a,b)=>{
      if(a.name.trim().toLowerCase() < b.name.trim().toLowerCase()){
        return -1;
      }
      if(a.name.trim().toLowerCase() > b.name.trim().toLowerCase()){
        return 1;
      }
      return 0;
    });

    data.map((element: { id: string; name: string }) => {
      batteriesList.push({ id: element.id, name: element.name });
    });
    await setBatteries(batteriesList);
    await setBatteriesLoaded(true);
    if(batteriesList.length < 1){
      //no battery exists, navigate to batterie construction or back
      let errorModalBattery = document.getElementById("ErrorMissingBatterie");
      if(!errorModalBattery) return;
      $('#ErrorMissingBatterie').modal('show');
    }
  };
  const reloadBatteryMainData = async() => {
    await setInitializedTable(false);
    await setInitialization(false);   
    await setIdListToDisconnect([]);
    await ApiBatteryTest.Get(location.state.data.id)
      .then((batterytest) =>{
        setBatteryTestMain(batterytest);
      })
      .catch((err)=>{
          console.log(err);
      })
  };

  //Validation
  const [validatedInputs, setValidatedInputs] = useState(false);
  const validateInputFields = () => {
    if( inputs.length == 0)
      return;

    for(let entry of inputs) {
      if(entry.batterieID == ""){
        setValidatedInputs(false);
        return;
      }
      if(entry.batteriename == ""){
        setValidatedInputs(false);
        return;
      }
    }
    setValidatedInputs(true);
  };

  //Handle Functions
  const handleChangeTable = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setInputs((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputsHeader((values) => ({ ...values, [name]: value }));
  };
  const handleInputChangeTable = (
    event: ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setInputs((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputsHeader((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleOptionChangeTable = (value:string, index: number, id:string) => {
    const name = 'batteriename';
    if(id === ''){
      if(batteries.map(x => x.name).includes(value)){
        let battery = batteries[batteries.map(x => x.name).indexOf(value)];
        id=battery.id;
      }
    }

    setInputs((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index || i === index + 1
          ? {
              ...input,
              [name]: value,
              batterieID:id,
            }
          : input
      )
    );
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    let inputs = inputsHeader;
    if(name === 'responsibleUser'){
      inputs.userList.push(value);
      setInputsHeader(inputs);
    }
    if(name === 'status' && value === 'abgeschlossen'){
      let newInputs = {...inputsHeader};
      let currentFullDate = new Date();
      let month: number|string = currentFullDate.getMonth()+1;
      if (month < 10){
        month = '0' + month.toString();
      }
      else{
        month = month.toString();
      }
      let date =  [currentFullDate.getFullYear().toString(), month,currentFullDate.getDate().toString()].join('-');
      newInputs.date = date
      newInputs.status = value;
      setInputsHeader(newInputs);
    }
    else{
      setInputsHeader((prevState) => ({ ...prevState, [name]: value}));
    }
  };
  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, checked } = event.target;

    setInputs((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index
          ? { ...input, [name]: checked }
          : i === index + 1
          ? { ...input, [name]: checked }
          : input
      )
    );
  };
  const handleAddTableRow = () => {
    let newInputs = [...inputs];
    setInputs(newInputs.concat(defaultTable));
  };
  const handleEdit = async() => {
    setOpenLoadingModal(true);
    let ch: __BatteryTestValuesConverted = {
      forming_I_mA: '',
      forming_U_max_min: '',
      forming_QLimit_mAh: '',
      cycling_I_mA: '',
      cycling_U_max_min_V: '',
      cycling_QLimit_mAh: '',
      cycling_CV_I_cut_mAh: '',
      rateTest_I_mA: '',
      rateTest_Cyc_per_I: '',
      rateTest_Repeat: 0,
    };
    let dch: __BatteryTestValuesConverted = {
      forming_I_mA: '',
      forming_U_max_min: '',
      forming_QLimit_mAh: '',
      cycling_I_mA: '',
      cycling_U_max_min_V: '',
      cycling_QLimit_mAh: '',
      cycling_CV_I_cut_mAh: '',
      rateTest_I_mA: '',
      rateTest_Cyc_per_I: '',
      rateTest_Repeat: 0,
    };
    const temp: any[] = [];
    let inputsCopy = [...inputs];
    inputsCopy.map((input, index) => {
      if (index % 2 === 0) {
        ch = {
          forming_I_mA: input.formingI,
          forming_U_max_min: input.formingU,
          forming_QLimit_mAh: input.formingQ,
          cycling_I_mA: input.cyclingI,
          cycling_U_max_min_V: input.cyclingU,
          cycling_QLimit_mAh: input.cyclingQ,
          cycling_CV_I_cut_mAh: input.cyclingCV,
          rateTest_I_mA: input.ratentestI,
          rateTest_Cyc_per_I: input.ratentestCycPerI,
          rateTest_Repeat: Number(input.ratentestRepeat) ?? 0,
        };
      } else {
        dch = {
          forming_I_mA: input.formingI,
          forming_U_max_min: input.formingU,
          forming_QLimit_mAh: input.formingQ,
          cycling_I_mA: input.cyclingI,
          cycling_U_max_min_V: input.cyclingU,
          cycling_QLimit_mAh: input.cyclingQ,
          cycling_CV_I_cut_mAh: input.cyclingCV,
          rateTest_I_mA: input.ratentestI,
          rateTest_Cyc_per_I: input.ratentestCycPerI,
          rateTest_Repeat: Number(input.ratentestRepeat) ?? 0, // only int allowed
        };
        if (
          Math.floor(index / 2) <
          batteryTestMain.batteryTestProcessing.length
        ) {
          let anotherTemp = {
            id: batteryTestMain.batteryTestProcessing[Math.floor(index / 2)]
              .id,
            ch: ch,
            dch: dch,
            isMeasuring: input.misst,
            isFinished: input.beendet,
            hasDefects: input.defekt,
            annotation: input.bemerkungen,
            measuringPoint: 'GerätB',
            batteries: input.batterieID,
          };
          temp.push(anotherTemp);
        } else {
          let anotherTemp = {
            ch: ch,
            dch: dch,
            isMeasuring: input.misst,
            isFinished: input.beendet,
            hasDefects: input.defekt,
            annotation: input.bemerkungen,
            measuringPoint: 'GerätB',
            batteries: input.batterieID,
          };
          temp.push(anotherTemp);
        }
      }
    });
    let user:string[] = inputsHeader.userList.map(name => userListDict[userListDict.map(x=>x.displayName).indexOf(name)].loginName);
    await ApiBatteryTest.Edit({
      id: location.state.data.id,
      responsibleUser: user,
      state: inputsHeader.status,
      comment: inputsHeader.comment,
      evaluation: null,
      date: new Date(inputsHeader.date),
      batteryTestProcess: temp,
      processIDListToDisconnect: IdListToDisconnect
    }).then(()=>{
      reloadBatteryMainData();
    })
      .catch(()=>{
        setOpenLoadingModal(false);
      });

  };
  const handleEditWunschNavigation = () => {
    navigate('/batterietest/detailswunsch', {
      state:{
        workblockID: batteryTestMain.workblock.id, 
        workblockName: batteryTestMain.workblock.name,
        workpackageID: batteryTestMain.workblock.workpackageID,
        workpackageName: inputsHeader.packageName,
        user: [inputsHeader.responsibleUser], 
        data: batteryTestMain, 
        halbzellenList: location.state.halbzellenList,
        vollzellenList: location.state.vollzellenList,
        type: location.state.type
      },
      replace:true
    });
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleGeneratePdf = async () => {
    let id = location.state.data.id;
    setOpenLoadingModal(true);
    ApiBatteryTest.GeneratePdf(id).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };
  const handleGeneratePdfWunsch = async () => {
    let id = location.state.data.id;
    setOpenLoadingModal(true);
    ApiBatteryTest.GeneratePdfWish(id).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };
  const handleReloadPage = async () => {
    setLoadFiles(false);
  };
  const submitModalCloseAccordion = (response:FormData)  =>{
    sendFileData(response);
    // send formdata to backend
    return response;
  };
  const sendFileData = async(data:FormData) =>{
    if(location.state.data){
      const id = location.state.data.id;
      await ApiFile.Upload(data,'/batterytest',id).then(async ()=>{
        await ApiBatteryTest.Get(id).then((data) => {
          const fileList = data.files?? []
          setFiles(fileList);
        });
      });
    }
    setLoadFiles(false);
  };

  //Use effects
  useEffect(() => {
    if(batteriesLoaded){
      validateInputFields();
    }
  }, [inputs]); // Aktualisiert immer dann, wenn sich batteryData ändert
  useEffect(()=>{
    loadFileData();
  },[loadFiles]);
  useEffect(()=>{
    if(initializedTable && userDataLoaded){
      setInitialization(true);
    }
  },[initializedTable]);
  useEffect(() => {
    document.title = 'Batterietest Bearbeiten';
    loadBatteries();
    if (location.state && ( !initializedTable || !initialized )) {
      setOpenLoadingModal(true);
    }
    if(!loadFiles){
      loadFileData();
    }
  },[]);
  useEffect(()=>{
    if(batteriesLoaded){
      loadData();
    }
  },[batteriesLoaded, batteryTestMain]);

  return (
    <div style={{ overflowX: 'scroll' }} className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}

      {initialized ? 
        <>
          <h1 className='mb-0'>Batterietest {location.state.type}</h1>
          <form action=''>
            <div className='row'>
              <div className='col'>
                <button
                  className='btn-main-light margin-left-0 p-1'
                  style={{fontSize:'15px'}}
                  onClick={event => handleEditWunschNavigation()}
                >
                  <FontAwesomeIcon 
                    icon={faArrowRight} 
                    className='pe-2'
                  />
                  Wunsch bearbeiten
                </button>
              </div>
            </div>
            <div className='row margin-top-5 ps-1'>
              <p>Arbeitspaket: {inputsHeader.packageName}</p>
            </div>
            <div className='row ps-1'>
              <p>Arbeitsblock: {inputsHeader.workBlockName}</p>
            </div>
            <div className='row mt-2'>
              <div className='col-4'>
                <label htmlFor='responsibleUser'>Verantwortlicher:</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                  <InputField
                    inputType='text'
                    name='responsibleUser'
                    placeholder= {``}
                    value={inputsHeader.responsibleUser}
                    changeHandler={handleChange}
                    disabled={false}
                    inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
                  />
                </div>
                <select
                  name='responsibleUser'
                  value={inputsHeader.responsibleUser}
                  onChange={(event) => handleOptionChange(event)}
                  className='input-lg text-md border-bottom-primary margin-top-5'
                >
                  {userListDict.map(option => (
                    <option key={option.displayName} value={option.displayName} hidden={!option.stateIsActve}>
                      {option.displayName}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-4'>
                <label htmlFor='status'>Status:</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                  <InputField
                    inputType='text'
                    name='status'
                    placeholder='aktiv'
                    value={inputsHeader.status}
                    changeHandler={handleChange}
                    disabled={false}
                    inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
                  />
                </div>
                <select
                  name='status'
                  value={inputsHeader.status}
                  onChange={(event) => handleOptionChange(event)}
                  className='input-lg text-md border-bottom-primary margin-top-5'
                >
                  {enums.WorkBlockState.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-8'>
                <label htmlFor='comment'>Übergreifender Kommentar</label>
                <textarea
                  className='input-lg-textarea text-md border-bottom-primary cut-text-overflow margin-top-5'
                  name='comment'
                  value={inputsHeader.comment}
                  onChange={handleInputChange}
                />
              </div>
              
            </div>
            <div className='mt-5'></div>
          </form>
          <div className="table-responsive margin-top-5">
            <table className='table main-table table-hover align-middle' style={{width:'100%'}}>
              <thead>
                <tr className=" align-middle">
                  <th rowSpan={2}></th>
                  <th rowSpan={2}>Batteriename</th>
                  <th colSpan={3}>Status</th>
                  <th rowSpan={2}></th>
                  <th colSpan={3}>Forming</th>
                  <th colSpan={4}>Cycling</th>
                  <th colSpan={3}>Ratentest</th>
                </tr>
                <tr className=" align-middle">
                  <th>misst</th>
                  <th>beendet</th>
                  <th>defekt</th>
                  <th>I (mA)</th>
                  <th>U_max/min(V)</th>
                  <th>Q-Limit</th>
                  <th>I (mA)</th>
                  <th>U_max/min (V)</th>
                  <th>Q-Limit (mAh)</th>
                  <th>CV (I_cut)</th>
                  <th>I (mA)</th>
                  <th>Cyc per I</th>
                  <th>Wdh</th>
                </tr>
              </thead>
              <tbody>
                {inputs.map((input, index) => (
                  <>
                    <tr key={index} className='table-small-inputs' style={{maxWidth:'none !important'}}>
                      {index % 2 === 0 ? (
                        <>
                        <td rowSpan={3} style={{width:'40px'}}>
                        <button
                          className='btn-delete'
                          type='button'
                          data-bs-toggle='modal'
                          data-bs-target={'#BatteryTestEdit_DeleteEntryModal_'+String(index)}
                        ><FontAwesomeIcon
                          icon={faXmark}
                          size='sm'
                          />
                        </button>
                        <ConfirmationModal
                          modalID={'BatteryTestEdit_DeleteEntryModal_'+String(index)}
                          modalText={inputs.length > 2 
                            ? 'Soll der Eintrag wirklich gelöscht werden?'
                            : 'Achtung! Sie entfernen die letzte Batterie. Sollten Sie die Tabelle so abspeichern, werden beim nächsten Laden die gewünschten Variationen angezeigt.'}
                          confirmationText='löschen'
                          handleConfirm={() =>{ 
                            if(inputs[index].batterieID !== '' && batteryTestMain.batteryTestProcessing.length >= Math.floor(index / 2)+1 && !IdListToDisconnect.includes(batteryTestMain.batteryTestProcessing[Math.floor(index / 2)].id)){
                              let list = [...IdListToDisconnect];
                              list.push(batteryTestMain.batteryTestProcessing[Math.floor(index / 2)].id);
                              setIdListToDisconnect(list);
                              }
                              setInputs((prevTable) => {
                                const newTable = [...prevTable];
                                newTable.splice(index, 2);
                                return newTable;
                              })}
                            }
                          />
                        </td>
                          <td rowSpan={2} className='px-0'>
                            <input 
                              className={`input-lg text-md margin-right-5 ${input.batterieID==='' ? 'border border-danger border-bottom-error' : 'border-bottom-primary'}`}
                              list="selectBattery" 
                              placeholder={'Batterie wählen...'}
                              value={input.batteriename}
                              autoCorrect="off"
                              autoComplete='off'
                              onChange={(event) => handleOptionChangeTable(event.target.value,index, '')}
                            />
                            <datalist id="selectBattery">
                              {batteries.map((option: { id: string; name: string }) => (
                                <option id={option.id} value={option.name}
                                  onClick={(event) => handleOptionChangeTable(option.name,index, option.id)}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </datalist>
                          </td>
                          <td rowSpan={2} style={{width:'60px'}}>
                            <input
                              type='checkbox'
                              name='misst'
                              id='misst'
                              checked={input.misst}
                              onChange={(e) => handleCheckboxChange(e, index)}
                            />
                          </td>
                          <td rowSpan={2} style={{width:'76px'}}>
                            <input
                              type='checkbox'
                              name='beendet'
                              id='beendet'
                              checked={input.beendet}
                              onChange={(e) => handleCheckboxChange(e, index)}
                            />
                          </td>
                          <td rowSpan={2} style={{width:'60px'}}>
                            <input
                              type='checkbox'
                              name='defekt'
                              id='defekt'
                              checked={input.defekt}
                              onChange={(e) => handleCheckboxChange(e, index)}
                            />
                          </td>
                        </>
                      ) : null}

                      <td style={{width:'55px'}}>
                        <p>{index % 2 === 0 ? 'CH' : 'DCH'}</p>
                      </td>
                      <td style={{width:'100px'}}>
                        <input
                          type='text'
                          name='formingI'
                          id='formingI'
                          value={input.formingI}
                          onChange={(e) => handleChangeTable(e, index)}
                        />
                      </td>
                      <td style={{width:'100px'}}>
                        <input
                          type='text'
                          name='formingU'
                          id='formingU'
                          value={input.formingU}
                          onChange={(e) => handleChangeTable(e, index)}
                        />
                      </td>
                      <td style={{width:'100px'}}>
                        <input
                          type='text'
                          name='formingQ'
                          id='formingQ'
                          value={input.formingQ}
                          onChange={(e) => handleChangeTable(e, index)}
                        />
                      </td>
                      <td style={{width:'100px'}}>
                        <input
                          type='text'
                          name='cyclingI'
                          id='cyclingI'
                          value={input.cyclingI}
                          onChange={(e) => handleChangeTable(e, index)}
                        />
                      </td>
                      <td style={{width:'100px'}}>
                        <input
                          type='text'
                          name='cyclingU'
                          id='cyclingU'
                          value={input.cyclingU}
                          onChange={(e) => handleChangeTable(e, index)}
                        />
                      </td>
                      <td style={{width:'100px'}}>
                        <input
                          type='text'
                          name='cyclingQ'
                          id='cyclingQ'
                          value={input.cyclingQ}
                          onChange={(e) => handleChangeTable(e, index)}
                        />
                      </td>
                      <td style={{width:'100px'}}>
                        <input
                          type='text'
                          name='cyclingCV'
                          id='cyclingCV'
                          value={input.cyclingCV}
                          onChange={(e) => handleChangeTable(e, index)}
                        />
                      </td>
                      <td style={{width:'100px'}}>
                        <input
                          type='text'
                          name='ratentestI'
                          id='ratentestI'
                          value={input.ratentestI}
                          onChange={(e) => handleChangeTable(e, index)}
                        />
                      </td>
                      <td style={{width:'100px'}}>
                        <input
                          type='text'
                          name='ratentestCycPerI'
                          id='ratentestCycPerI'
                          value={input.ratentestCycPerI}
                          onChange={(e) => handleChangeTable(e, index)}
                        />
                      </td>
                      <td style={{width:'100px'}}>
                        <input
                          type='number'
                          name='ratentestRepeat'
                          id='ratentestRepeat'
                          min = '0'
                          value={Number(input.ratentestRepeat)}
                          onChange={(e) => handleChangeTable(e, index)}
                        />
                      </td>
                    </tr>

                    {index % 2 !== 0 ? (
                      <tr>
                        <td colSpan={17}>
                          <textarea
                            style={{ width: '100%', margin: '0', lineHeight: 'auto' }}
                            name='bemerkungen'
                            id='bemerkungen'
                            value={input.bemerkungen}
                            onChange={(e) => handleInputChangeTable(e, index)}
                          />
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
          <div className='row mt-2'>
            <div className='col'>
              <input
                className='btn-main'
                type='button'
                value='+ Test hinzufügen'
                onClick={handleAddTableRow}
              />
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col'>
              <input
                className='btn-main margin-right-15'
                type='button'
                value='Zurück'
                onClick={handleBack}
              />
              <input
                className='btn-main margin-right-15'
                type='button'
                value='Batterietest speichern'
                onClick={handleEdit}
                disabled={!validatedInputs}
              />
              <input
                className='btn-main margin-right-15'
                type='button'
                value='PDF generieren'
                onClick={handleGeneratePdf}
              />
              <input
                className='btn-main'
                type='button'
                value='PDF Wunsch anzeigen'
                onClick={handleGeneratePdfWunsch}
              />
            </div>
          </div>
          <div className='row mt-2'>
            { inputs.map(x => x.batterieID).includes('')
              ? <p className='text-err-color-font'>Batterien müssen angeklickt werden!</p>
              : <></>
            }
          </div>
        </>
       : <></>
      }
      {loadFiles ?
        <div className='margin-top-20 paket-information-container row'>
          <div className='column'>
            <FileUploadAccordeon
              handleConfirm={submitModalCloseAccordion}
              handleReloadPage={handleReloadPage}
              files={files}
            />
          </div>
        </div>
        : <></>
      }
      <InfoModal
        modalID='ErrorDownloadFailed'
        infoText='Download fehlgeschlagen'
        confirmationText='Ok'
      />
      <NavigationModal 
        modalID='ErrorMissingBatterie'
        infoText='Bitte erstellen Sie vor dem Testen mindestens eine Batterie!'
        confirmationText='Ok'
        path='/batteriebau'
      />
    </div>
  );
};

export default BatterietestDetails;

import { ApiBackendData } from "../../backend/ApiBackendData";

  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();

export type InputElementTable = {
    id: string;
    name: string;
    variation: string;
    location: string;
    constructor: string;
    trys: string;
    u1:string,
    u2:string,
    annotation: string;
    [key: string]: string; // add an index signature
};
export const emptyTable: InputElementTable = {
id: '',
name: '',
variation: '',
location: enums.Location[0],
constructor: '',
trys: '0',
u1:'',
u2:'',
annotation: ''
};
export const tableHeaders = [
    '',
    'Batterie',
    'Messort',
    'Zellbauer',
    'Versuche',
    'U1',
    'U2 (precharge)',
    'Variation',
    // 'Anmerkung',
  ];

/**
 * Module for interfaces fpr batterydatas
 */
export interface AnodeForBatteryData{
    id:string,
    anodeName: string,
    anodeId: string,
    anodengroesse: number
}
export interface CathodeForBatteryData{
    id:string,
    kathodeName: string
    kathodeId: string
    kathodengroesse: number
}
export interface ElectrolytForBatteryData{
    id:string,
    elektrolytName: string
    elektrolytId: string
    elektrolytvolumen: string
    elektrolytgesamtvolumen: number
}
export interface SeparatorForBatteryData{
    id:string,
    separatorName: string
    separatorId: string
    separatorgroesse: number
    separatoranzahl: number
}
export interface FeatherForBatteryData{
    id:string,
    federName: string
    federId: string
}
export interface SpacerForBatteryData{
    id:string,
    spacerName: string
    spacerId: string
    spacergroesse: number
    spacerdicke: number
}
export interface CaseForBatteryData{
    id:string,
    gehaeuseName: string
    gehaeuseId: string
}
export interface BatteryDataForModal {
    responsibleUser:string[],
    date: Date,
    celltype:string,
    state: string,
    anode: AnodeForBatteryData|null,
    kathode: CathodeForBatteryData|null,
    elektolyt: ElectrolytForBatteryData|null,
    gehaeuse: CaseForBatteryData|null,
    feder: FeatherForBatteryData|null,
    spacer: SpacerForBatteryData|null,
    separator: SeparatorForBatteryData|null
}
export interface BatteryDataForModalVariation {
    anode: AnodeForBatteryData|null,
    kathode: CathodeForBatteryData|null,
    elektolyt: ElectrolytForBatteryData|null,
    gehaeuse: CaseForBatteryData|null,
    feder: FeatherForBatteryData|null,
    spacer: SpacerForBatteryData|null,
    separator: SeparatorForBatteryData|null
}

export const  testData = {
    celltype: 'Halbzelle',
    verantwortlicher: 'Max',
    anodeData: 'Anode1, Größe: 5mm',
    kathodeData: 'Kathode1, Größe: 5mm',
    elektrolytData: 'Elektrolyt1, Volumen: 2x2,5ul, Gesamtvolumen 5ul',
    separatorData: 'Separator 1, Größe: 5mm, Anzahl: 5',
    spacerData: 'Spacer, Größe: 5mm, Anzahl: 5',
    federData: '',
    gehaeuseData: 'Gehäuse1',
  };